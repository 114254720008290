import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NonAuthentication = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem('uuid') !== null){
            navigate('/dashboard');
        }
    }, []);

    return children;
}

export default NonAuthentication;