import React, {useCallback, useEffect, useState,} from 'react'
import Sidebar from '../../components/Sidebar'
import { FiEye } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import Breadcrumbs from '../../components/Breadcrumbs'
import Clipboard from '../../components/Clipboard'

function Detailtransaction() {
    const [geser, setGeser] = useState(false);
    const { id } = useParams();
    const [detail, setDetail] = useState({});

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const detailTransaction = useCallback( async () => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature
        }

        let body = {
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/transactiondetail?transactionid='+id+'&id='+currentTimestamp, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            setDetail(response.data.data[0]);
        }

        console.log(response.data);
    }, [id])

    useEffect(() => {
        detailTransaction()
    }, [detailTransaction]);

    const gesertoggle = localStorage.getItem("gesertoggle");

    return (
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[330px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300">
                        <div className="flex flex-row m-3 ml-0">
                            <a href='/transaction'><FiArrowLeft size={25} className="mt-1 mr-1" /></a>
                            <div className="flex  flex-col">
                                <h1 className='text-2xl'>Detail Transaction</h1>
                                <h2 className="text-sm">
                                    <Breadcrumbs paths={window.location.pathname.split('/')}/>
                                </h2>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex flex-row">
                        <div className="w-full flex flex-row text-sm p-3 mt-1 mx-2 bg-[#1E1F23] rounded-lg border">
                            <div className=' w-full'>
                                <div className='flex flex-col'>
                                    <span className='text-[#989B9D]'>Transaction Code</span>
                                    <span className='text-white'>{detail.tabletransactionindex}</span>
                                </div>
                                <div className='flex flex-col mt-3'>
                                    <span className='text-[#989B9D]'>Transaction Date</span>
                                    <span className='text-white'>{detail.tabletransactiontimestamp}</span>
                                </div>
                                <div className='flex flex-col mt-3'>
                                    <span className='text-[#989B9D]'>Invoice ID</span>
                                    <span className='text-white'>{detail.tabletransactionbctransaction}</span>
                                </div>
                                <div className='flex flex-col mt-3'>
                                    <span className='text-[#989B9D]'>Thread ID</span>
                                    <span className='text-white'>{detail.tabletransactionpurposeindex}</span>
                                </div>
                            </div>
                            <div className=' w-full'>
                                <div className='flex flex-col'>
                                    <span className='text-[#989B9D]'>Transaction Type</span>
                                    <span className='text-white'>{detail.tabletransactionpurpose}</span>
                                </div>
                                <div className='flex flex-col mt-3'>
                                    <span className='text-[#989B9D]'>Transaction Status</span>
                                    <span className='text-white'>{detail.tabletransactionstatus}</span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='w-full h-full mx-8'>
                        <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Transaction Detail</h2>
                        <div className="flex flex-col gap-3 mb-[32px]">
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Transaction Code</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'https://testnet.explorer.dwa.network/transaction-detail/'+detail.tabletransactionbctransaction} target="_blank" className='underline'><p className="text-white underline">{detail.tabletransactionbctransaction}</p></a>
                                        <button onClick={() => Clipboard(detail.tabletransactionbctransaction)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Transaction Type</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactionpurpose}</p>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Blockchain Status</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">
                                        {detail.tabletransactionstatus === 'CONFIRMED' ?
                                        <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{detail.tabletransactionstatus}</span>
                                        : '' }
                                        {detail.tabletransactionstatus === 'COMPLETED' ?
                                        <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{detail.tabletransactionstatus}</span>
                                        : '' }
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Created Date</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full px-4">
                                    <div className='flex flex-row items-center gap-2'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99166 1.66602C5.39166 1.66602 1.66666 5.39935 1.66666 9.99935C1.66666 14.5993 5.39166 18.3327 9.99166 18.3327C14.6 18.3327 18.3333 14.5993 18.3333 9.99935C18.3333 5.39935 14.6 1.66602 9.99166 1.66602ZM10 16.666C6.31666 16.666 3.33333 13.6827 3.33333 9.99935C3.33333 6.31602 6.31666 3.33268 10 3.33268C13.6833 3.33268 16.6667 6.31602 16.6667 9.99935C16.6667 13.6827 13.6833 16.666 10 16.666ZM9.81666 5.83268H9.76666C9.43333 5.83268 9.16666 6.09935 9.16666 6.43268V10.366C9.16666 10.6577 9.31666 10.9327 9.575 11.0827L13.0333 13.1577C13.3167 13.3243 13.6833 13.241 13.85 12.9577C13.8918 12.8902 13.9197 12.815 13.9318 12.7365C13.944 12.6581 13.9403 12.578 13.9208 12.501C13.9014 12.424 13.8667 12.3517 13.8187 12.2885C13.7707 12.2252 13.7105 12.1722 13.6417 12.1327L10.4167 10.216V6.43268C10.4167 6.09935 10.15 5.83268 9.81666 5.83268Z" fill="white"/>
                                        </svg>
                                        <p className="text-white">{detail.tabletransactiontimestamp}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Sender Address</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        {detail.sender_userindex !== null ?
                                        <a href={'/user/'+detail.sender_userindex} target='_blank'><p className="text-white underline">{detail.tabletransactionaccountsender}</p></a>
                                        :
                                        <p className="text-white">{detail.tabletransactionaccountsender}</p>
                                        }
                                        <button onClick={() => Clipboard(detail.tabletransactionaccountsender)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Developer Username</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'/user/'+detail.developeruserindex} target='_blank'><p className="text-white underline">{detail.developerusername}</p></a>
                                        <button onClick={() => Clipboard(detail.developerusername)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Recipient Address</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        {detail.recepient_userindex !== null ?
                                        <a href={'/user/'+detail.recepient_userindex} target='_blank'><p className="text-white">{detail.tabletransactionaccountrecepient}</p></a>
                                        :
                                        <p className="text-white">{detail.tabletransactionaccountrecepient}</p>
                                        }
                                        <button onClick={() => Clipboard(detail.tabletransactionaccountrecepient)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {detail.gamerusername ?
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Gamer Username</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'/user/'+detail.gameruserindex} target='_blank'><p className="text-white underline">{detail.gamerusername}</p></a>
                                        <button onClick={() => Clipboard(detail.gamerusername)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div> : '' }

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Thread ID</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'/threads/'+detail.threadindex} target='_blank'><p className="text-white underline">{detail.threadindex}</p></a>
                                        <button onClick={() => Clipboard(detail.threadindex)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Thread Title</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'/threads/'+detail.threadindex} target='_blank'><p className="text-white underline">{detail.tablethreadtitle}</p></a>
                                        <button onClick={() => Clipboard(detail.tablethreadtitle)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            {detail.tabletransactionpurpose === 'Allocation' ? 
                            <>
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Allocation Amount</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactionamount} ANOA</p>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Gass Fee</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactionfee} ANOA</p>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Platform Fee</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.platformfee} ANOA</p>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Total Payment</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactiontotalamount} ANOA</p>
                                </div>
                            </div>
                            </>: '' }

                            {detail.tabletransactionpurpose === 'Disbursement' ? 
                            <>
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Disbursement Amount</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactionamount} ANOA</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Disbursement Fee</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactionfee} ANOA</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Total Payment</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactiontotalamount} ANOA</p>
                                </div>
                            </div>
                            </>: '' }

                            {detail.tabletransactionpurpose === 'Refund' ? 
                            <>
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Refund Amount</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactionamount} ANOA</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Gass Fee</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactionfee} ANOA</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Total Refund</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{detail.tabletransactiontotalamount} ANOA</p>
                                </div>
                            </div>
                            </> : '' }
                        </div>

                        {detail.tabletransactionpurpose === 'Allocation' ?
                            <div className='flex flex-row w-full h-full gap-5'>
                                <div className='w-full h-full'>
                                    <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Token Allocation Scheme</h2>
                                    <div className='bg-[#1E1F23] p-[12px] flex flex-col justify-between gap-2'>
                                        <div>
                                            <p className='text-[#BABEC1]'>Disbursement Fee</p>
                                            <p className='text-[#4391E7] text-[20px]'>0.1 ANOA</p>
                                        </div>
                                        <div>
                                            <p className='text-[#BABEC1]'>Disbursement Fee by</p>
                                            <p className='text-white'>{detail.tablethreaddisbursementfee}</p>
                                        </div>
                                        <div>
                                            <p className='text-[#BABEC1]'>Estimated Developer Expense</p>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>(Condition 1) Write Review & Media Upload</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.EstimatedDeveloperExpense_WriteReviewAndUploadMedia} ANOA</p>
                                            </div>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>(Condition 2) Write Review</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.EstimatedDeveloperExpense_WriteReview} ANOA</p>
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <p className='text-[#BABEC1]'>Estimated Gamers Earnings</p>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>(Condition 1) Write Review & Media Upload</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.EstimatedGamersEarning_WriteReviewAndUploadMedia} ANOA</p>
                                            </div>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>(Condition 2) Write Review</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.EstimatedGamersEarning_WriteReview} ANOA</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full h-full'>
                                    <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Calculation</h2>
                                    <div className='bg-[#1E1F23] p-[12px] w-full h-full flex flex-col justify-between'>
                                        <div className='w-full h-full flex flex-col gap-1 mb-6'>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>Targeted Reviews</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactiontargetedreviews}</p>
                                            </div>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>Subtotal (Condition 1 x Targeted Reviews)</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactionamount} ANOA</p>
                                            </div>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>Platform Fee</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.platformfee} ANOA</p>
                                            </div>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>Gass Fee</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactionfee} ANOA</p>
                                            </div>
                                            <hr/>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>Total Payment</p>
                                                <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactiontotalamount} ANOA</p>
                                            </div>
                                        </div>
                                        <div className='w-full h-full flex flex-col mb-9'>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>Developer Wallet Balance</p>
                                                <p className='text-[#CA4E4E] text-[20px]'>{detail.developer_wallet_balance} ANOA</p>
                                            </div>
                                            <div className='flex flex-row items-center justify-between'>
                                                <p className='text-white'>Allocation Balance</p>
                                                <p className='text-[#00813B] text-[20px]'>{detail.allocation_balance} ANOA</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : '' }

                        {detail.tabletransactionpurpose === 'Disbursement' ?
                        <div className='flex flex-row w-full h-full gap-5'>
                            <div className='w-full h-full'>
                                <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Token Allocation Scheme</h2>
                                <div className='bg-[#1E1F23] p-[12px] flex flex-col justify-between gap-2'>
                                    <div>
                                        <p className='text-[#BABEC1]'>Disbursement Fee</p>
                                        <p className='text-[#4391E7] text-[20px]'>0.1 ANOA</p>
                                    </div>
                                    <div>
                                        <p className='text-[#BABEC1]'>Disbursement Fee by</p>
                                        <p className='text-white'>{detail.tablethreaddisbursementfee}</p>
                                    </div>
                                    <div>
                                        <p className='text-[#BABEC1]'>Estimated Developer Expense</p>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>(Condition 1) Write Review & Media Upload</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.EstimatedDeveloperExpense_WriteReviewAndUploadMedia} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>(Condition 2) Write Review</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.EstimatedDeveloperExpense_WriteReview} ANOA</p>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        <p className='text-[#BABEC1]'>Estimated Gamers Earnings</p>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>(Condition 1) Write Review & Media Upload</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.EstimatedGamersEarning_WriteReviewAndUploadMedia} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>(Condition 2) Write Review</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.EstimatedGamersEarning_WriteReview} ANOA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full h-full'>
                                <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Calculation</h2>
                                <div className='bg-[#1E1F23] p-[12px] w-full h-full flex flex-col justify-between'>
                                    <div className='w-full h-full flex flex-col gap-1 mb-6'>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Subtotal (Condition 1)</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactionamount} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Disbursement Fee</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactionfee} ANOA</p>
                                        </div>
                                        <hr/>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Total Payment</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactiontotalamount} ANOA</p>
                                        </div>
                                    </div>
                                    <div className='w-full h-full flex flex-col mb-9'>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Allocation Balance</p>
                                            <p className='text-[#CA4E4E] text-[20px]'>{detail.allocation_balance} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Gamer Wallet Balance</p>
                                            <p className='text-[#00813B] text-[20px]'>{detail.gamer_wallet_balance} ANOA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : '' }

                        {detail.tabletransactionpurpose === 'Refund' ? 
                        <div className='flex flex-row w-full h-full gap-5'>
                            <div className='w-full h-full'>
                                <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Calculation</h2>
                                <div className='bg-[#1E1F23] p-[12px] w-full h-full flex flex-col justify-between'>
                                    <div className='w-full h-full flex flex-col gap-1 mb-6'>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Refund Amount</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactionamount} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Gas Fee</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactionfee} ANOA</p>
                                        </div>
                                        <hr/>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Total Refund</p>
                                            <p className='text-[#4391E7] text-[20px]'>{detail.tabletransactiontotalamount} ANOA</p>
                                        </div>
                                    </div>
                                    <div className='w-full h-full flex flex-col mb-9'>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Allocation Balance</p>
                                            <p className='text-[#CA4E4E] text-[20px]'>{detail.allocation_balance} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Developer Wallet Balance</p>
                                            <p className='text-[#00813B] text-[20px]'>{detail.developer_wallet_balance} ANOA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : '' }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Detailtransaction