import { useCallback, useEffect, useState } from "react";
import CryptoJS from 'crypto-js';
import axios from "axios";
import Swal from 'sweetalert2'

import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { useParams } from "react-router-dom";
import YouTube from 'react-youtube';
import { FiArrowLeft } from "react-icons/fi";
import Clipboard from '../../components/Clipboard'

const ThreadsDetail = () => {
    const [geser, setGeser] = useState(false);
    const { id } = useParams();
    const [result, setResult] = useState({});
    const [genre, setGenre] = useState([]);
    const [video, setVideo] = useState([]);
    const [image, setImage] = useState([]);
    const [thumbnail, setThumbnail] = useState(false);
    const [imageBig, setImageBig] = useState('');
    const [imageToggle, setImageToggle] = useState(false);

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    const loadThreadsDetail = useCallback( async () => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature
        }

        let body = {
            'tablethreadindex' : id
        };

        const currentTimestamp = new Date().getTime();

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/thread/v01/admin/getthread?id='+currentTimestamp, body, {
            'headers' : header
        });

        console.log(response.data.data);

        if(response.data.status.status === 1){
            setResult(response.data.data);
            setGenre(response.data.data.tablethreadgenre);
            setVideo(response.data.data.tablethreadvideo);
            setImage(response.data.data.tablethreadimage);
        }
    }, [id]);

    useEffect(() => {
        loadThreadsDetail();
    }, [loadThreadsDetail]);

    const cancelHandle = async () => {
        Swal.fire({
            title: 'Enter your reason?',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Kirim',
            showLoaderOnConfirm: true,
            preConfirm: async (params) => {
                let uuid =  localStorage.getItem("uuid");
                let parseuuid = JSON.parse(uuid);

                let userindex = parseuuid.userindex;
                let usertoken = parseuuid.usertokenlogin;

                let StringSign;
                StringSign = '';

                let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

                let header = {
                    'platform' : 'WEBSITE',
                    'gtoken' : 'PASSS',
                    'userindex' : userindex,
                    'tokenlogin' : usertoken,
                    'signature' : signature
                }

                let body = {
                    'threadindex' : id,
                    'cancelreason' : params
                };

                let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/thread/v01/admin/cancel', body, {
                    'headers' : header
                });

                if(response.data.status.status === 1){
                    loadThreadsDetail();
                }else{
                    Swal.showValidationMessage(
                        `Reason cannot be empty!`
                    )
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                Swal.fire(
                    'Canceled!',
                    'Your threads successfuly cancel',
                    'success'
                )
            }
        })
    }

    const opts = {
        height: '200',
        width: '450px',
    };

    const getVideoIdFromUrl = (url) => {
        const regex = /[?&]v=([^&]+)/;
        const match = url ? url.match(regex) : null;
        return match ? match[1] : '';
    };

    return(
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                        <div className="flex flex-row m-3 ml-0">
                            <a href='/threads'>
                                <FiArrowLeft size={25} className="mt-1 mr-1 hover:cursor-pointer" />
                            </a>
                            <div className="flex flex-col">
                                <h1 className='text-2xl max-sm:text-xl'>Detail Thread</h1>
                                <h2 className="text-sm max-sm:text-xs">
                                    <Breadcrumbs paths={window.location.pathname.split('/')}/>
                                </h2>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex flex-row max-sm:flex-col">
                        <div className="flex flex-col w-full h-full bg-black border rounded-lg p-3 mt-1">
                            <div className='w-full'>
                                <h1 className="text-white">Detail Threads</h1>
                                <div className="flex flex-col h-full w-full py-5">
                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Threads ID</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]">{result.tablethreadindex}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Developer Name</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]">{result.developer_name}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Title Threads</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]">{result.tablethreadtitle}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[20%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Threads Description</span>
                                        </div>
                                        <div className="flex content-start w-[80%] text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23] break-words">{result.tablethreaddescription}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Start Date</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">{result.tablethreaddatestart}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Start End</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">{result.tablethreaddateend}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Demo Link</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">
                                                <a href={result.tablethreaddemolink} target="_blank" className="text-blue-600 underline">{result.tablethreaddemolink}</a>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Coin Allocation</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">{result.tablethreadcoinallocation}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Review Reward</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">{result.tablethreadreviewreward}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Word Count</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">{result.tablethreadreviewwordcount}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">AVG Rating</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">{result.avg_rating}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Total Review</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">{result.total_review}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Status</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">
                                                {result.tablethreadstatus === 'ACTIVE' ? 
                                                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.tablethreadstatus}</span>
                                                : ''}
                                                {result.tablethreadstatus === 'ENDED' ? 
                                                    <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{result.tablethreadstatus}</span>
                                                : ''}
                                                {result.tablethreadstatus === 'CANCELED' ? 
                                                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{result.tablethreadstatus}</span>
                                                : ''}
                                                {result.tablethreadstatus === 'PENDING' ? 
                                                    <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.tablethreadstatus}</span>
                                                : ''}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Genre</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">
                                                <ul className="flex flex-row gap-2">
                                                    {genre.length > 0 ?
                                                    genre.map((result, key) => (
                                                        <>
                                                            <li key={key}>{result.genrename}</li>
                                                            {genre.length > 1 ? '/' : ''}
                                                        </>
                                                    )) : '-'}
                                                </ul>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs">Link Youtube</span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">
                                                {result.tablethreadyoutubelink ? 
                                                    <a href={result.tablethreadyoutubelink} target="_blank">{result.tablethreadyoutubelink}</a>
                                                : 'No youtube link'}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row pb-3 w-full">
                                        <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                                            <span className="text-white text-sm max-sm:text-xs"></span>
                                        </div>
                                        <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                            <span className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]">
                                                <button onClick={cancelHandle} class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">Cancel</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 className="text-white">Threads Video</h1>
                            <div className="bg-[#1E1F23] mt-5 p-3">
                                {video.length > 0 ?
                                <div className="grid grid-cols-4 gap-2">
                                    {video.map((result, key) => (
                                        <div className="w-full" key={key}>
                                            <video controls className="w-full h-[200px] object-cover">
                                                <source src={'https://back.thespace.world/public/'+result.tablemediafilename} type="video/mp4"/>
                                                Your browser does not support HTML video.
                                            </video>
                                        </div>
                                    ))}
                                </div>
                                : <span className="text-white">Video is empty</span> }
                            </div>

                            <h1 className="text-white mt-5">Threads Picture</h1>
                            <div className="bg-[#1E1F23] mt-5 p-3">
                                {image.length > 0 ? 
                                <div className="grid grid-cols-4 gap-2">
                                    {image.map((result, key) => (
                                        <div key={key}>
                                            <img src={'https://back.thespace.world/public/'+result.tablemediafilename} alt="picture" className="w-full h-[200px]"/>
                                        </div>
                                    ))}
                                </div>
                                : <span className="text-white">Image is empty</span> }
                            </div>
                        </div>
                    </div> */}
                    <div className="w-full h-full mt-5">
                        <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Thread Data</h2>
                        <div className="grid grid-cols-3 gap-3 mb-[32px]">
                            <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                                <p className="text-[#E4E8EC] pb-[10px]">Allocation Balance</p>
                                <h1 className="text-[#4391E7] text-[36px] font-semibold">{result.availabletoken} ANOA</h1>
                            </div>

                            <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                                <p className="text-[#E4E8EC] pb-[10px]">Total Reviews</p>
                                <h1 className="text-[#4391E7] text-[36px] font-semibold">{result.total_review}</h1>
                                <a href={'/listreview?q='+result.tablethreadindex} target="_blank" className="bg-[#2970FF] text-white font-semibold py-[5px] w-full block text-center rounded-lg mt-[10px]">Show Data</a>
                            </div>

                            <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                                <p className="text-[#E4E8EC] pb-[10px]">AVG Rating</p>
                                <h1 className="text-[#4391E7] text-[36px] font-semibold">{result.avg_rating}</h1>
                                <a href={'https://thespace.world/share/threads/'+id} target="_blank" className="bg-[#2970FF] text-white font-semibold py-[5px] w-full block text-center rounded-lg mt-[10px]">Show Data</a>
                            </div>

                            <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                                <p className="text-[#E4E8EC] pb-[10px]">Total Allocation</p>
                                <h1 className="text-[#4391E7] text-[36px] font-semibold">{result.total_allocations} ANOA</h1>
                                <a href={'/transaction?q='+result.tablethreadindex+'&f=Allocation'} target="_blank" className="bg-[#2970FF] text-white font-semibold py-[5px] w-full block text-center rounded-lg mt-[10px]">Show Data</a>
                            </div>

                            <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                                <p className="text-[#E4E8EC] pb-[10px]">Total Disbursement</p>
                                <h1 className="text-[#4391E7] text-[36px] font-semibold">{result.total_disbursements ? result.total_disbursements : 0} ANOA</h1>
                                <a href={'/transaction?q='+result.tablethreadindex+'&f=Disbursement'} target="_blank" className="bg-[#2970FF] text-white font-semibold py-[5px] w-full block text-center rounded-lg mt-[10px]">Show Data</a>
                            </div>
                        </div>

                        <div className="w-full h-full mb-[32px]">
                            <div className="flex flex-row gap-5">
                                <div className="w-[130%]">
                                    <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Data User</h2>
                                    <div className="flex flex-col gap-3 mb-[32px]">
                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Thread ID</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <p className="text-white px-4">{result.tablethreadindex}</p>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Thread title</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <p className="text-white px-4">{result.tablethreadtitle}</p>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Developer Username</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <div className='flex flex-row items-center px-4 gap-2'>
                                                    <a href={'/user/'+result.developer_userindex} target='_blank'><p className="text-white underline">{result.developer_username}</p></a>
                                                    <button onClick={() => Clipboard(result.developer_username)}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Status</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <p className="px-4">
                                                {result.tablethreadstatus === 'ACTIVE' ? 
                                                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.tablethreadstatus}</span>
                                                : ''}
                                                {result.tablethreadstatus === 'ENDED' ? 
                                                    <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{result.tablethreadstatus}</span>
                                                : ''}
                                                {result.tablethreadstatus === 'CANCELED' ? 
                                                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{result.tablethreadstatus}</span>
                                                : ''}
                                                {result.tablethreadstatus === 'PENDING' ? 
                                                    <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.tablethreadstatus}</span>
                                                : ''}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4 whitespace-nowrap">Reason for Cancelation</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <p className="text-white px-4">{result.tablethreadcancelreason !== null ? result.tablethreadcancelreason : '-'}</p>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Created Date</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <div className="flex flex-row gap-1 h-full items-center px-4">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.99163 1.66602C5.39163 1.66602 1.66663 5.39935 1.66663 9.99935C1.66663 14.5993 5.39163 18.3327 9.99163 18.3327C14.6 18.3327 18.3333 14.5993 18.3333 9.99935C18.3333 5.39935 14.6 1.66602 9.99163 1.66602ZM9.99996 16.666C6.31663 16.666 3.33329 13.6827 3.33329 9.99935C3.33329 6.31602 6.31663 3.33268 9.99996 3.33268C13.6833 3.33268 16.6666 6.31602 16.6666 9.99935C16.6666 13.6827 13.6833 16.666 9.99996 16.666ZM9.81663 5.83268H9.76663C9.43329 5.83268 9.16663 6.09935 9.16663 6.43268V10.366C9.16663 10.6577 9.31663 10.9327 9.57496 11.0827L13.0333 13.1577C13.3166 13.3243 13.6833 13.241 13.85 12.9577C13.8918 12.8902 13.9196 12.815 13.9318 12.7365C13.944 12.6581 13.9402 12.578 13.9208 12.501C13.9014 12.424 13.8666 12.3517 13.8187 12.2885C13.7707 12.2252 13.7105 12.1722 13.6416 12.1327L10.4166 10.216V6.43268C10.4166 6.09935 10.15 5.83268 9.81663 5.83268Z" fill="white"/>
                                                    </svg>
                                                    <span className="text-white">{result.tablethreadtimestamp}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Start Date</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <div className="flex flex-row gap-1 h-full items-center px-4">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.99163 1.66602C5.39163 1.66602 1.66663 5.39935 1.66663 9.99935C1.66663 14.5993 5.39163 18.3327 9.99163 18.3327C14.6 18.3327 18.3333 14.5993 18.3333 9.99935C18.3333 5.39935 14.6 1.66602 9.99163 1.66602ZM9.99996 16.666C6.31663 16.666 3.33329 13.6827 3.33329 9.99935C3.33329 6.31602 6.31663 3.33268 9.99996 3.33268C13.6833 3.33268 16.6666 6.31602 16.6666 9.99935C16.6666 13.6827 13.6833 16.666 9.99996 16.666ZM9.81663 5.83268H9.76663C9.43329 5.83268 9.16663 6.09935 9.16663 6.43268V10.366C9.16663 10.6577 9.31663 10.9327 9.57496 11.0827L13.0333 13.1577C13.3166 13.3243 13.6833 13.241 13.85 12.9577C13.8918 12.8902 13.9196 12.815 13.9318 12.7365C13.944 12.6581 13.9402 12.578 13.9208 12.501C13.9014 12.424 13.8666 12.3517 13.8187 12.2885C13.7707 12.2252 13.7105 12.1722 13.6416 12.1327L10.4166 10.216V6.43268C10.4166 6.09935 10.15 5.83268 9.81663 5.83268Z" fill="white"/>
                                                    </svg>
                                                    <span className="text-white">{result.tablethreaddatestart}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">End Date</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <div className="flex flex-row gap-1 h-full items-center px-4">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.99163 1.66602C5.39163 1.66602 1.66663 5.39935 1.66663 9.99935C1.66663 14.5993 5.39163 18.3327 9.99163 18.3327C14.6 18.3327 18.3333 14.5993 18.3333 9.99935C18.3333 5.39935 14.6 1.66602 9.99163 1.66602ZM9.99996 16.666C6.31663 16.666 3.33329 13.6827 3.33329 9.99935C3.33329 6.31602 6.31663 3.33268 9.99996 3.33268C13.6833 3.33268 16.6666 6.31602 16.6666 9.99935C16.6666 13.6827 13.6833 16.666 9.99996 16.666ZM9.81663 5.83268H9.76663C9.43329 5.83268 9.16663 6.09935 9.16663 6.43268V10.366C9.16663 10.6577 9.31663 10.9327 9.57496 11.0827L13.0333 13.1577C13.3166 13.3243 13.6833 13.241 13.85 12.9577C13.8918 12.8902 13.9196 12.815 13.9318 12.7365C13.944 12.6581 13.9402 12.578 13.9208 12.501C13.9014 12.424 13.8666 12.3517 13.8187 12.2885C13.7707 12.2252 13.7105 12.1722 13.6416 12.1327L10.4166 10.216V6.43268C10.4166 6.09935 10.15 5.83268 9.81663 5.83268Z" fill="white"/>
                                                    </svg>
                                                    <span className="text-white">{result.tablethreaddateend}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Min Word Count</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <p className="text-white px-4">{result.tablethreadreviewwordcount}</p>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Demo Link</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <a href={result.tablethreaddemolink} target="_blank" className="text-white px-4">{result.tablethreaddemolink === null ? '-' : result.tablethreaddemolink}</a>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Genre</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <div className="px-4 flex flex-row h-full items-center gap-2">
                                                    {genre.map((results, key) => (
                                                        <span key={key} class="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-pink-400 border border-pink-400">{results.genrename}</span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                                <p className="text-[#BABEC1] px-4">Description</p>
                                            </div>
                                            <div className="bg-[#1E1F23] py-[12px] w-full">
                                                <p className="text-white px-4">{result.tablethreaddescription}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Token Allocation Scheme</h2>
                                    <div className="bg-[#1E1F23] p-[12px] mb-[32px]">
                                        <div className="flex flex-col gap-3 mb-[10px]">
                                            <div className="w-full">
                                                <p className="text-[#BABEC1]">Disbursement Fee</p>
                                                <p className="text-[#4391E7]">{result.disbursementfee} ANOA</p>
                                            </div>
                                            <div className="w-full">
                                                <p className="text-[#BABEC1]">Disbursement Fee by</p>
                                                <p className="text-white">{result.tablethreaddisbursementfee}</p>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-3">
                                            <div className="w-full">
                                                <p className="text-[#BABEC1]">Estimated Developer Expense</p>
                                                <div className="flex flex-row items-center justify-between">
                                                    <p className="text-white">(Condition 1) Write Review & Media Upload</p>
                                                    <p className="text-[#4391E7]">{result.EstimatedDeveloperExpense_WriteReviewAndUploadMedia} ANOA</p>
                                                </div>
                                                <div className="flex flex-row items-center justify-between">
                                                    <p className="text-white">(Condition 2) Write Review</p>
                                                    <p className="text-[#4391E7]">{result.EstimatedDeveloperExpense_WriteReview} ANOA</p>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <p className="text-[#BABEC1]">Estimated Gamers Earnings</p>
                                                <div className="flex flex-row items-center justify-between">
                                                    <p className="text-white">(Condition 1) Write Review & Media Upload</p>
                                                    <p className="text-[#4391E7]">{result.EstimatedGamersEarning_WriteReviewAndUploadMedia} ANOA</p>
                                                </div>
                                                <div className="flex flex-row items-center justify-between">
                                                    <p className="text-white">(Condition 2) Write Review</p>
                                                    <p className="text-[#4391E7]">{result.EstimatedGamersEarning_WriteReview} ANOA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Token Allocation Scheme</h2>
                                    <div className="bg-[#1E1F23] p-[12px]">
                                        <div className="w-full mb-3">
                                            <p className="text-[#BABEC1] mb-2">Artwork</p>
                                            <div className="grid grid-cols-3 gap-3">
                                                {image.map((results, key) => (
                                                    <div className="flex flex-col items-center gap-3" key={key}>
                                                        <img src={results.tablemediafilenamefullpath} alt="atwork" className="h-[200px] w-full object-contain"/>
                                                        <div className="cursor-pointer" onClick={() => {setImageToggle(true); setImageBig(results.tablemediafilenamefullpath)}}>
                                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21.6666 21L15.6666 15M10.6666 7V10M10.6666 10V13M10.6666 10H13.6666M10.6666 10H7.66663M17.6666 10C17.6666 13.866 14.5326 17 10.6666 17C6.80063 17 3.66663 13.866 3.66663 10C3.66663 6.13401 6.80063 3 10.6666 3C14.5326 3 17.6666 6.13401 17.6666 10Z" stroke="#E4E8EC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between w-full gap-5">
                                            {video.map((results, key) => (
                                            <div className="w-full">
                                                <p className="text-[#BABEC1] mb-2">Video</p>
                                                <div className="w-[full]">
                                                    <div key={key}>
                                                        {thumbnail ?
                                                        <video controls className="w-full h-[200px] object-contain">
                                                            <source src={results.tablemediafilenamefullpath} type="video/mp4"/>
                                                            Your browser does not support the video tag.
                                                        </video> : 
                                                        <img src={results.tablemediathumbnailfullpath} alt="thumbnail" className="w-full h-[200px] cursor-pointer" onClick={() => setThumbnail(true)}/> 
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                            
                                            <div className="w-full">
                                                {result.tablethreadyoutubelink ?
                                                    <>
                                                        <p className="text-[#BABEC1] mb-2">Youtube</p>
                                                        <div>
                                                            <YouTube videoId={getVideoIdFromUrl(result.tablethreadyoutubelink)} opts={opts} />
                                                        </div>
                                                    </>
                                                 : '' }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {result.tablethreadstatus === 'CANCELED' || result.tablethreadstatus === 'ENDED' ?
                                <div className="w-[35%]">
                                    <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Action</h2>
                                    <button className="bg-[#fab1a0] text-white font-semibold py-[5px] w-full rounded-lg">
                                        Cancel Thread
                                    </button>
                                </div> 
                                : 
                                <div className="w-[35%]">
                                    <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Action</h2>
                                    <button className="bg-[#CA4E4E] text-white font-semibold py-[5px] w-full rounded-lg" onClick={cancelHandle}>
                                        Cancel Thread
                                    </button>
                                </div> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {imageToggle ?
            <div className="fixed inset-0 z-20" onClick={() => {setImageToggle(false); setImageBig('')}}>
                <div className="w-[800px] m-auto flex flex-col justify-center h-full">
                    <img src={imageBig} alt="atwork" className="w-full"/>
                </div>
            </div>: '' }
        </>
    );
}

export default ThreadsDetail;