const { useEffect } = require("react");
const { useNavigate } = require("react-router-dom");

const Authentication = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem('uuid') === null){
            navigate('/login');
        }
    }, []);

    return children;
};

export default Authentication;