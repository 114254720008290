import React, { useEffect, useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate, useParams } from 'react-router-dom';

import Sidebar from "../../components/Sidebar";
import { FiArrowLeft } from "react-icons/fi";
import { FiX } from 'react-icons/fi';
import {FiFilter} from "react-icons/fi";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from '../../components/Breadcrumbs';

function Log() {
    const navigate = useNavigate();
    const {id} = useParams();

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    const [isPopUpOpen, setPopUpOpen] = useState(false);
    const handleFilterMobile = () => {
        setPopUpOpen(!isPopUpOpen);
    }

    const handleClose = () => {
        setPopUpOpen(false);
    }

    const [logList, setLogList] = useState([]);

    const userHandle = async () => {

        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';
        // StringSign = 'PASSS';
        // StringSign = userindex;
        // StringSign = usertoken;

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature

        }

        let body = {
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/useractivitylog?userindexlog='+id+'&timestamp='+currentTimestamp, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            setLogList(response.data.data.list_data);
        }
    }

    useEffect(() => {
        userHandle();
    }, [])


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [geser, setGeser] = useState(false);

    return (
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]" } ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10 items-center">
                        <div className="flex flex-row m-3 ml-0">
                            <a href={'/user/'+id}>
                                <FiArrowLeft size={25} className="mt-1 mr-1" />
                            </a>
                            <div className="flex  flex-col">
                                <h1 className='text-2xl max-sm:text-xl'>Log</h1>
                                <h2 className="text-sm max-sm:text-xs">
                                    <Breadcrumbs paths={window.location.pathname.split('/')}/>
                                </h2>
                            </div>
                        </div>
                        <div className='bg-white'>
                            <div className='flex flex-row gap-2'>
                                <div className='px-4 text-black block py-[6px] cursor-pointer' onClick={() => navigate('/user/'+id)}>
                                    User Detail
                                </div>
                                <div className='px-4 block text-white bg-[#4C4DDC] py-[6px] cursor-pointer' onClick={() => navigate('/user/log/'+id)}>
                                    Activity Log
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-col w-[830px] h-full bg-black rounded-lg p-3 mt-1">
                            <div className="w-full">
                                <div className="flex flex-col">
                                    <div className="overflow-y-hidden">
                                        <div className="p-1.5 w-full inline-block align-middle">
                                            <div className="overflow-hidden max-sm:overflow-auto rounded-xl">
                                                <table className="min-w-full divide-y divide-gray-400">
                                                    <thead className="bg-white text-black">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >
                                                                Action Date
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >
                                                                Activity Log
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >
                                                                Category
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-400 text-white">
                                                        {logList.map((result, key) => (
                                                            <tr>
                                                                <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                    {result.useractivitylogtimestamp}
                                                                </td>
                                                                <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                    <span>{result.activitylog}</span>
                                                                </td>
                                                                <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                    {result.category}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Log