import React, { useState } from 'react'
import Sidebar from '../../components/Sidebar'
import { FiArrowLeft } from "react-icons/fi";
import Imageicon from "../../assets/media/imageicon.png";
import { AiOutlineClose } from "react-icons/ai";


function Media() {

    const [showModal2, setShowModal2] = React.useState(false);

    const [geser, setGeser] = useState(false);
    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    return (
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                        <div className="flex flex-row m-3 ml-0">
                            <FiArrowLeft size={25} className="mt-1 mr-1" />
                            <div className="flex  flex-col">
                                <h1 className='max-sm:text-xl text-2xl'>Media</h1>
                                <h2 className="text-sm max-sm:text-xs">../Media/</h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 max-sm:mt-0 w-full p-1 text-white text-sm">
                            <div className='flex flex-row w-full my-1'>
                                <div className='flex flex-row bg-[#1E1F23] p-1 w-full mr-3 rounded-lg text-white'>
                                    <img className='w-[20px]' src={Imageicon}/>
                                    <span className='ml-2'>Main Banner</span>
                                </div>
                                <div onClick={() => setShowModal2(true)} className='w-[25%] bg-[#4C4DDC] text-sm text-white text-center rounded-lg p-1 mr-3'>
                                    <span>Edit</span>
                                </div>
                            </div>
                            <div className='flex flex-row w-full my-1'>
                                <div className='flex flex-row bg-[#1E1F23] p-1 w-full mr-3 rounded-lg'>
                                    <img className='w-[20px]' src={Imageicon}/>
                                    <span className='ml-2'>Banner 2</span>
                                </div>
                                <div onClick={() => setShowModal2(true)} className='w-[25%] bg-[#4C4DDC] text-sm text-white text-center rounded-lg p-1 mr-3'>
                                    <span>Edit</span>
                                </div>
                            </div>
                            <div className='flex flex-row w-full my-1'>
                                <div className='flex flex-row bg-[#1E1F23] p-1 w-full mr-3 rounded-lg'>
                                    <img className='w-[20px]' src={Imageicon}/>
                                    <span className='ml-2'>Banner 3</span>
                                </div>
                                <div onClick={() => setShowModal2(true)} className='w-[25%] bg-[#4C4DDC] text-sm text-white text-center rounded-lg p-1 mr-3'>
                                    <span>Edit</span>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            {showModal2 ? (
            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative my-3 max-sm:my-0 mx-auto w-[35%] max-sm:w-[95%] p-3">
                    {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-2xl max-sm:text-xl font-semibold">
                                    Change Media
                                </h3>
                                <button
                                    className="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowModal2(false)}
                                >
                                    <AiOutlineClose
                                        className='fill-black w-[25px]'
                                    />
                                </button>
                            </div>
                            <div className='flex flex-col'>
                                <div className="relative p-1 m-2 flex-auto">
                                    <div className="flex justify-center">
                                        <div className="rounded-lg shadow-xl bg-gray-50 w-full">
                                            <div className="m-4">
                                                <div className="flex items-center justify-center w-full">
                                                    <label className="flex flex-col w-full h-40 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                                        <div className="flex flex-col items-center justify-center py-5">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path fill-rule="evenodd"
                                                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                                    clip-rule="evenodd" />
                                                            </svg>
                                                            <p className="text-center pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                                Drag File Here
                                                                <br/>or, click to browse
                                                                <br/>(JPG or PNG File)
                                                            </p>
                                                        </div>
                                                        <input type="file" className="opacity-0" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col p-3">
                                    <span>Current File</span>
                                    <div className='text-white text-sm flex flex-row bg-[#1E1F23] p-3 w-full mt-1 mr-3 rounded-lg border-spacing-5 border-white'>
                                        <img className='w-[20px]' src={Imageicon}/>
                                        <span className='ml-2'>Main Banner.jpg</span>
                                    </div>

                                </div>
                                <div className="flex flex-row items-center justify-end p-3 mt-1">
                                    <button
                                        className="w-[20%] max-sm:w-[25%] text-white bg-[black] px-3 py-2 max-sm:py-1 text-sm rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal2(false)}
                                    >
                                        Discard
                                    </button>
                                    <button
                                        className="w-[20%] max-sm:w-[25%] bg-[#E0E0F3] text-[#4C4DDC] text-sm px-3 py-2 max-sm:py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal2(false)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
            ) : null}
        </>
    )
}

export default Media