import React, { useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Link, useNavigate } from 'react-router-dom';


import thespace_logo from "../../src/assets/dashboard/logo.png";
import Icon_username from "../../src/assets/login/Icon_username.png";
import Icon_password from "../../src/assets/login/Icon_password.png";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";


function Login() {

    const inputUsername = useRef();
    const inputPassword = useRef();

    const [errorUsername, setErrorUsername] = useState('');
    const [errorPassword, setErrorPassword] = useState('');

    const navigate = useNavigate();

    const loginHandle = async (e) => {
        e.preventDefault();
        
        let username = inputUsername.current.value;
        let password = inputPassword.current.value;

        let StringSign;
        StringSign = 'WEBSITE';
        StringSign = 'PASSS';
        StringSign = username;
        StringSign = password;
        StringSign = 'admin';

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'signature' : signature
        }

        let body = {
            'emailusername' :username,
            'password' : password,
            'accounttype' : 'admin'
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/auth/v01/login?id='+currentTimestamp, body, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            let userdata = JSON.stringify(response.data.data.user);
            localStorage.setItem("uuid",userdata);
            console.log(response.data.data.user);
            navigate('/dashboard')
        }

        if(response.data.status.status === 0){
            response.data.status.message.forEach(element => {
                switch(element.code.substring(0, 9)){
                    case '008005015':
                        setErrorUsername(element.errormessage);
                    break;
                    case '008005115':
                        setErrorPassword(element.errormessage);
                    break;
                    default:
                        //
                    break;
                }
            })
        }

        console.log(response.data);
    }

    const [open, setOpen] = useState(false);

    return (
        <div className='flex flex-row bg-black py-10 px-16 max-sm:py-15 max-sm:px-5 h-full'>
            <div className='flow-initial w-[65%] max-sm:hidden'>
                <div className='relative h-full'>
                    <div className='m-0 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                        <img src={thespace_logo} 
                            className={``}
                        />
                    </div>
                </div>
            </div>
            <div className='flow-initial w-[35%] max-sm:w-full max-sm:h-[95%]'>
                <form className='bg-[#1E1F23] shadow-xl shadow-gray-300 rounded-3xl h-full pt-32 px-20 max-sm:pt-5 max-sm:px-5' onSubmit={(e) => loginHandle(e)}>
                    <div className='w-full'>
                        <h1 className='text-3xl font-semibold text-center text-white'>Login</h1>
                        <div className='mt-14 relative'>
                            <label for="first_name" className="block mb-2 text-sm font-medium text-white">Username</label>
                            <div className="absolute p-3">
                                <img src={Icon_username} className=""/>
                            </div>
                            <input type="text" ref={inputUsername} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 pl-10" placeholder="Yourname" />
                            {errorUsername ? <span className='text-red-500'>{errorUsername}</span> : ''}
                        </div>
                        <div className='mt-8 relative'>
                            <label for="first_name" className="block mb-2 text-sm font-medium text-white">Password</label>
                            <div className="absolute p-3">
                                <img src={Icon_password} className=""/>
                            </div>
                            <input type={open === false ? 'password' : 'text'} ref={inputPassword} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 pl-10" placeholder="Yourpassword" />
                            {open ?
                                <FiEye className={`absolute cursor-pointer right-5 top-10`}
                                    onClick={() => setOpen(!open)}
                                /> :
                                <FiEyeOff className={`absolute cursor-pointer right-5 top-10`} onClick={() => setOpen(!open)}/>
                            }
                            {errorPassword ? <span className='text-red-500'>{errorPassword}</span> : ''}
                        </div>
                        <div className='relative w-full'>
                            <div className='w-full px-0'>
                                <button type="submit" className="mt-10 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center">Login</button>
                            </div>
                            <div className='mt-3 flex justify-center'>
                                <span className='text-gray-300 text-center'>
                                    "Dont have an account"
                                    <Link className='text-blue-700 font-semibold' to={'/new-register'}> Create Account</Link>
                                </span>
                            </div>
                        </div>
                        
                        
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login