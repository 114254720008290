import { useCallback, useEffect } from "react";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Link, useNavigate } from 'react-router-dom';

function NewRegister() {
        const navigate = useNavigate();

        const loadNewRegister = useCallback(async () => {
            let StringSign;
            StringSign = 'WEBSITE';
            StringSign = 'PASSS';
            StringSign = 'admin';

            let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

            let header = {
                'platform' : 'WEBSITE',
                'gtoken' : 'PASSS',
                'signature' : signature
            }

            let body = {
            }

            let response = await axios.post('https://back.thespace.world/api/auth/v01/insertnew', body, {
                'headers' : header
            });

            if(response.data.status.status === 1){
                // console.log(response.data.data.user);
                localStorage.setItem('tokentrans', response.data.status.tokentrans)
                navigate('/register');
            }
        }, []);

        useEffect(() => {
            loadNewRegister()
        }, [loadNewRegister]);
   
  return (
    <>
        <h1>test</h1>
    </>
  )
}

export default NewRegister