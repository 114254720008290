import { useLocation } from "react-router-dom";

const QueryString = (value) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const myParamValue = queryParams.get(value);

    return myParamValue;
}

export default QueryString;