import React, { useEffect, useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

import Sidebar from "../../components/Sidebar";
import { FiArrowLeft } from "react-icons/fi";


import Icon_file from "../../assets/language/Icon_file.png";

function Edit_language() {

    const [geser, setGeser] = useState(false);
    const [edit,setEdit] = useState(false);

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

  return (
    <>
        <div className="bg-black min-h-full">
            <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
            </div>
            <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]" } ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                <div className="flex flex-row max-sm:flex-col justify-between text-gray-300 max-sm:mt-10">
                    <div className="flex flex-row m-3 max-sm:mb-1 ml-0">
                        <FiArrowLeft size={25} className="mt-1 mr-1" />
                        <div className="flex flex-col">
                            <h1 className='text-2xl max-sm:text-xl'>Term And Condition</h1>
                            <h2 className="text-sm max-sm:text-xs">../Language/TnC</h2>
                        </div>
                    </div>
                    <div className="flex text-center text-sm w-[15%] max-sm:w-[50%] m-3 max-sm:mx-0 ">
                        <div className="flex w-full max-sm:w-[75%] p-2 max-sm:px-0 h-full text-center">
                            <div className="bg-blue-400 text-gray-300 py-2 max-sm:py-1 w-full rounded-lg">
                                <a>Add Language</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-3 max-sm:mt-0 w-full p-1 max-sm:p-0 pb-0 text-white text-sm">
                    <div className='flex flex-col my-2'>
                        <div className='flex flex-row w-full'>
                            <div className={`${edit ? "rounded-lg" : "rounded-t-lg"} flex flex-row justify-between bg-[#1E1F23] p-3 w-full text-white`}>
                                <div className='flex flex-row justify-center'>
                                    <div>
                                        <img className='w-[20px]' src={Icon_file}/>
                                    </div>
                                    <div>
                                        <span className='ml-2'>Main Banner</span>
                                    </div>
                                </div>
                                {edit === false ? 
                                <div className='w-[10%] max-sm:w-[15%] bg-[#4C4DDC] text-sm text-white text-center rounded-lg p-1 mr-3' onClick={() => setEdit(!edit)}>
                                    <span>Edit</span>
                                </div>:
                                <div className='w-[10%] max-sm:w-[15%] bg-[#4C4DDC] text-sm text-white text-center rounded-lg p-1 mr-3' onClick={() => setEdit(!edit)}>
                                    <span>Save</span>
                                </div>}
                            </div>
                        </div>
                        <div className={`${edit ? "block" : "hidden"} rounded-b-lg px-3 pb-3 bg-[#1E1F23] w-full`}>
                            <textarea className='text-gray-600 w-full h-full'>
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Edit_language