import React, { useCallback, useEffect, useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

import Sidebar from "../../components/Sidebar";
import { FiArrowLeft } from "react-icons/fi";
import { FiX } from 'react-icons/fi';
import {FiFilter} from "react-icons/fi";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from '../../components/Breadcrumbs';

import { format } from 'date-fns';
import { FiEye } from "react-icons/fi";

function Log() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [geser, setGeser] = useState(false);

    const [category, setCategory] = useState('');
    const [adminRole, setAdminRole] = useState('');

    const inputSearch = useRef();

    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('');

    const navigate = useNavigate();

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    const [isPopUpOpen, setPopUpOpen] = useState(false);
    const handleFilterMobile = () => {
        setPopUpOpen(!isPopUpOpen);
    }

    const handleClose = () => {
        setPopUpOpen(false);
    }

    const [logList, setLogList] = useState([]);

    const userHandle = useCallback( async (category, adminrole, date_start, date_end, search) => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature

        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/log?&category='+category+'&admin_role='+adminrole+'&date_from='+date_start+'&date_to='+date_end+'&search='+search+'&id='+currentTimestamp, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            console.log(response.data.data);
            setLogList(response.data.data);
        }
    }, []);

    useEffect(() => {
        userHandle('', '', '', '', '');
    }, [userHandle])

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        const myArray = dates;
        const allNullValues = myArray.every(value => value === null);

        if(allNullValues !== true){
            userHandle(category, adminRole, format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd'), inputSearch.current.value);
        }else{
            userHandle(category, adminRole, '', '', inputSearch.current.value);
        }
    }

    const categoryHandle = (e) => {
        setCategory(e.target.value);
        if(startDate && endDate){
            userHandle(e.target.value, adminRole, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), inputSearch.current.value);
        }else{
            userHandle(e.target.value, adminRole, '', '', inputSearch.current.value);
        }
    }

    const adminRoleHandle = (e) => {
        setAdminRole(e.target.value);
        if(startDate && endDate){
            userHandle(category, e.target.value, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), inputSearch.current.value);
        }else{
            userHandle(category, e.target.value, '', '', inputSearch.current.value);
        }
    }

    const searchHandle = (e) => {
        if(e.key === 'Enter'){
            inputSearch.current.value = e.target.value;
            if(startDate && endDate){
                userHandle(category, adminRole, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), inputSearch.current.value);
            }else{
                userHandle(category, adminRole, '', '', inputSearch.current.value);
            }
        }
    }

    const handleSort = (key) => {
        if (sortBy === key) {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
          setSortBy(key);
          setSortOrder('asc');
        }
      };
    
    const sortedData = [...logList].sort((a, b) => {
        const order = sortOrder === 'asc' ? 1 : -1;
        return a[sortBy] > b[sortBy] ? order : -order;
    });

    const actionHandle = (val, cat) => {
        if(cat === 'User'){
            navigate('/user/'+val);
        }

        if(cat === 'Thread'){
            navigate('/threads/'+val);
        }

        if(cat === 'Review'){
            navigate('/listreview/'+val);
        }
    }

    return (
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]" } ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                        <div className="flex flex-row m-3 ml-0">
                            {/* <FiArrowLeft size={25} className="mt-1 mr-1" /> */}
                            <div className="flex  flex-col">
                                <h1 className='text-2xl max-sm:text-xl'>Log</h1>
                                <h2 className="text-sm max-sm:text-xs">
                                    <Breadcrumbs paths={window.location.pathname.split('/')}/>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-col w-[75%] h-full bg-black rounded-lg p-3 mt-1">
                            <div className="w-full">
                                <div className="flex flex-col">
                                    <div className="overflow-y-auto">
                                        <div className="p-1.5 w-full inline-block align-middle">
                                            <div className="overflow-auto max-sm:overflow-auto rounded-xl">
                                                <table className="min-w-full divide-y divide-gray-400">
                                                    <thead className="bg-white text-black">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >
                                                                <div className="flex flex-row items-center gap-2">
                                                                    <span>Action Date</span>
                                                                    <button onClick={() => handleSort('actiondate')}>
                                                                        {sortBy === 'actiondate' && sortOrder === 'asc' ? 
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>
                                                                        : 
                                                                            
                                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>  
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >
                                                                <div className="flex flex-row items-center gap-2">
                                                                    <span>Admin</span>
                                                                    <button onClick={() => handleSort('tablelogadmin')}>
                                                                        {sortBy === 'tablelogadmin' && sortOrder === 'asc' ? 
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>
                                                                        : 
                                                                            
                                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>  
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >
                                                                <div className="flex flex-row items-center gap-2">
                                                                    <span>Admin Role</span>
                                                                    <button onClick={() => handleSort('admin_role')}>
                                                                        {sortBy === 'admin_role' && sortOrder === 'asc' ? 
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>
                                                                        : 
                                                                            
                                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>  
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >
                                                                <div className="flex flex-row items-center gap-2">
                                                                    <span>Activity Log</span>
                                                                    <button onClick={() => handleSort('tablelogactivity')}>
                                                                        {sortBy === 'tablelogactivity' && sortOrder === 'asc' ? 
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>
                                                                        : 
                                                                            
                                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>  
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >
                                                                <div className="flex flex-row items-center gap-2">
                                                                    <span>Category</span>
                                                                    <button onClick={() => handleSort('tablelogcategory')}>
                                                                        {sortBy === 'tablelogcategory' && sortOrder === 'asc' ? 
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>
                                                                        : 
                                                                            
                                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            </svg>  
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-bold text-left uppercase "
                                                            >ACTION</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-400 text-white">
                                                        {sortedData.map((result, key) => (
                                                            <tr key={key}>
                                                                <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                    {result.actiondate}
                                                                </td>
                                                                <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                    {result.tablelogadmin}
                                                                </td>
                                                                <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                    {result.admin_role}
                                                                </td>
                                                                <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                    {result.tablelogactivity}
                                                                </td>
                                                                <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                    {result.tablelogcategory ? result.tablelogcategory : '-'}
                                                                </td>
                                                                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                                    <button onClick={() => actionHandle(result.tablelogactivityindex, result.tablelogcategory)}>
                                                                        <div className='flex justify-center px-1 py-1 bg-[#0028FC] rounded-md'>
                                                                            <FiEye size={25} className="" />
                                                                        </div>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[25%] mx-3 mt-1">
                            <div className="flex flex-col gap-5">
                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Search</h1>
                                    <div className="relative">
                                        <input type="text" className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D]" placeholder="Search" ref={inputSearch} onKeyDown={searchHandle}/>
                                        <div className="absolute top-[50%] right-3 translate-y-[-50%]">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.5 17.5L7.5 12.5M5.83333 8.33333C5.83333 11.555 8.44501 14.1667 11.6667 14.1667C14.8883 14.1667 17.5 11.555 17.5 8.33333C17.5 5.11167 14.8883 2.5 11.6667 2.5C8.44501 2.5 5.83333 5.11167 5.83333 8.33333Z" stroke="#707375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Action Date</h1>
                                    <div className="customDatePickerWidth text-white">
                                        <DatePicker
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange={true}
                                            placeholderText="ALL"
                                            isClearable={true}
                                            dateFormat={'dd/MM/yyyy'}
                                            className="bg-[#1E1F23] py-[6px] pl-[16px] outline-none rounded text-[#989B9D] cursor-pointer w-full"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Admin Role</h1>
                                    <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={adminRoleHandle}>
                                        <option value=''>ALL</option>
                                        <option value='Admin'>Admin</option>
                                    </select>
                                </div>

                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Category</h1>
                                    <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={categoryHandle}>
                                        <option value=''>ALL</option>
                                        <option value='Login'>Login</option>
                                        <option value='User'>User</option>
                                        <option value='Thread'>Thread</option>
                                        <option value='Review'>Review</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Log