import React, { useCallback, useEffect, useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Await, useNavigate, useParams } from 'react-router-dom';

import Sidebar from "../../components/Sidebar";
import { FiArrowLeft } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

function Edit_admin() {

    const [geser, setGeser] = useState(false);

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    const [showModal, setShowModal] = React.useState(false);

    const navigate = useNavigate();
    const { id } = useParams();
    const [userDetail, setUserDetail] = useState({});

    const userHandle = useCallback( async () => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = id;
        // StringSign = 'PASSS';
        // StringSign = userindex;
        // StringSign = usertoken;

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature

        }

        let body = {
            'detailuserindex' : id
        }

        let response = await axios.post('https://back.thespace.world/api/user/v01/admin/detailadmin', body, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            setUserDetail(response.data.data[0])
        }
    }, [id])

    useEffect(() => {
        userHandle();
    }, [userHandle])

// ------------------------------------------------------------------------------------------------------------------------------------------
    
    const [permissionDetail, setPermissionDetail] = useState({});
    const [readReview, setReadReview] = useState('');
    const [approveReview, setApproveReview] = useState('');
    const [rejectReview, setRejectReview] = useState('');
    const [readWalletBallance, setReadWalletBallance] = useState('');
    const [readWalletHistory, setReadWalletHistory] = useState('');
    const [readUserDetail, setReadUserDetail] = useState('');
    const [blockAccount, setBlockAccount] =  useState('');
    const [closeAccount, setCloseAccount] = useState('');
    const [approveRegistration, setApproveRegistration] = useState('');
    const [rejectRegistration, setRejectRegistration] = useState('');
    const [readReviewAccessLog, setReadReviewAccessLog] = useState('');
    const [readRegistrationAccessLog, setReadRegistrationAccessLog] = useState('');
    const [manageMedia, setManageMedia] = useState('');
    const [readPermission, setReadPermission] = useState('');
    const [updatePermission, setUpdatePermission] = useState('');

    const permissionHandle = useCallback( async () => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = id;
        // StringSign = 'PASSS';
        // StringSign = userindex;
        // StringSign = usertoken;

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature
        }

        let body = {
            'detailuserindex' : id
        }

        let response = await axios.post('https://back.thespace.world/api/user/v01/admin/getpermission', body, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            console.log(response.data.data);
            setPermissionDetail(response.data.data[0])
            setReadReview(response.data.data[0].tableuseradminpermissionisreadreview);
            setApproveReview(response.data.data[0].tableuseradminpermissionisapprovereview);
            setRejectReview(response.data.data[0].tableuseradminpermissionisrejectreview);
            setReadWalletBallance(response.data.data[0].tableuseradminpermissionisreadwalletbalance);
            setReadWalletHistory(response.data.data[0].tableuseradminpermissionisreadwallethistory);
            setReadUserDetail(response.data.data[0].tableuseradminpermissionisreaduserdetail);
            setBlockAccount(response.data.data[0].tableuseradminpermissionisblockaccount);
            setCloseAccount(response.data.data[0].tableuseradminpermissioniscloseaccount);
            setApproveRegistration(response.data.data[0].tableuseradminpermissionisapproveregistration);
            setRejectRegistration(response.data.data[0].tableuseradminpermissionisrejectregistration);
            setReadReviewAccessLog(response.data.data[0].tableuseradminpermissionisreadreviewaccesslog);
            setReadRegistrationAccessLog(response.data.data[0].tableuseradminpermissionisreadregistrationaccesslog);
            setManageMedia(response.data.data[0].tableuseradminpermissionismanagemedia);
            setReadPermission(response.data.data[0].tableuseradminpermissionisreadpermission);
            setUpdatePermission(response.data.data[0].tableuseradminpermissionisupdatepermission);
        }
        console.log(response.data.data);
    }, [id])

    useEffect(() => {
        permissionHandle();
    }, [permissionHandle])

    const handleReadReview = () => {
        if(readReview === ''){
            setReadReview(1);
        }else{
            setReadReview('');
        }
    }

    const handleApproveReview = () => {
        if(approveReview === ''){
            setApproveReview(1);
        }else{
            setApproveReview('');
        }
    }

    const handleRejectReview = () => {
        if(rejectReview === ''){
            setRejectReview(1);
        }else{
            setRejectReview('');
        }
    }

    const handleReadWalletBalance = () => {
        if(readWalletBallance === ''){
            setReadWalletBallance(1);
        }else{
            setReadWalletBallance('');
        }
    }

    const handleReadWalletHistory = () => {
        if(readWalletHistory === ''){
            setReadWalletHistory(1);
        }else{
            setReadWalletHistory('');
        }
    }

    const handleReadUserDetail = () => {
        if(readUserDetail === ''){
            setReadUserDetail(1);
        }else{
            setReadUserDetail('');
        }
    }

    const handleBlockAccount = () => {
        if(blockAccount === ''){
            setBlockAccount(1);
        }else{
            setBlockAccount('');
        }
    }

    const handleCloseAccount = () => {
        if(closeAccount === ''){
            setCloseAccount(1);
        }else{
            setCloseAccount('');
        }
    }

    const handleApproveRegistration = () => {
        if(approveRegistration === ''){
            setApproveRegistration(1);
        }else{
            setApproveRegistration('');
        }
    }

    const handleRejectRegistration = () => {
        if(rejectRegistration === ''){
            setRejectRegistration(1);
        }else{
            setRejectRegistration('');
        }
    }

    const handleReadReviewAccessLog = () => {
        if(readReviewAccessLog === ''){
            setReadReviewAccessLog(1);
        }else{
            setReadReviewAccessLog('');
        }
    }

    const handleReadRegistrationAccessLog = () => {
        if(readRegistrationAccessLog === ''){
            setReadRegistrationAccessLog(1);
        }else{
            setReadRegistrationAccessLog('');
        }
    }

    const handleManageMedia = () => {
        if(manageMedia === ''){
            setManageMedia(1);
        }else{
            setManageMedia('');
        }
    }

    const handleReadPermission = () => {
        if(readPermission === ''){
            setReadPermission(1);
        }else{
            setReadPermission('');
        }
    }

    const handleUpdatePermission = () => {
        if(updatePermission === ''){
            setUpdatePermission(1);
        }else{
            setUpdatePermission('');
        }
    }

// ---------------------------------------------------------------------------------------------------------------------------------------------------

    const checklistReadReview = useRef();
    const checklistApproveReview = useRef();
    const checklistRejectReview = useRef();
    const checklistReadWalletBallance = useRef();
    const checklistReadWalletHistory = useRef();
    const checklistReadUserDetail = useRef();
    const checklistBlockAccount = useRef();
    const checklistCloseAccount = useRef();
    const checklistApproveRegistration = useRef();
    const checklistRejectRegistration = useRef();
    const checklistReadReviewAccessLog = useRef();
    const checklistReadRegistrationAccessLog = useRef();
    const checklistManageMedia = useRef();
    const checklistReadPermission = useRef();
    const checklistUpdatePermissin = useRef();

    

    const handleSave = async () =>{
        console.log(checklistReadReview.current.checked);
        console.log(checklistApproveReview.current.checked);
        console.log(checklistRejectReview.current.checked);
        console.log(checklistReadWalletBallance.current.checked);
        console.log(checklistReadWalletHistory.current.checked);
        console.log(checklistReadUserDetail.current.checked);
        console.log(checklistBlockAccount.current.checked);
        console.log(checklistCloseAccount.current.checked);
        console.log(checklistApproveRegistration.current.checked);
        console.log(checklistRejectRegistration.current.checked);
        console.log(checklistReadReviewAccessLog.current.checked);
        console.log(checklistReadRegistrationAccessLog.current.checked);
        console.log(checklistManageMedia.current.checked);
        console.log(checklistReadPermission.current.checked);
        console.log(checklistUpdatePermissin.current.checked);

        let ReadReview = checklistReadReview.current.checked;
        let ApproveReview = checklistApproveReview.current.checked;
        let RejectReview = checklistRejectReview.current.checked;
        let ReadWalletBallance = checklistReadWalletBallance.current.checked;
        let ReadWalletHistory = checklistReadWalletHistory.current.checked;
        let ReadUserDetail = checklistReadUserDetail.current.checked;
        let BlockAccount = checklistBlockAccount.current.checked;
        let CloseAccount = checklistCloseAccount.current.checked;
        let ApproveRegistration = checklistApproveRegistration.current.checked;
        let RejectRegistration = checklistRejectRegistration.current.checked;
        let ReadReviewAccessLog = checklistReadReviewAccessLog.current.checked;
        let ReadRegistrationAccessLog = checklistReadRegistrationAccessLog.current.checked;
        let ManageMedia = checklistManageMedia.current.checked;
        let ReadPermission = checklistReadPermission.current.checked;
        let UpdatePermissin = checklistUpdatePermissin.current.checked;

        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';
        StringSign += id;
        StringSign += ReadReview;
        StringSign += ApproveReview;
        StringSign += RejectReview;
        StringSign += ReadWalletBallance;
        StringSign += ReadWalletHistory;
        StringSign += ReadUserDetail;
        StringSign += BlockAccount;
        StringSign += CloseAccount;
        StringSign += ApproveRegistration;
        StringSign += RejectRegistration;
        StringSign += ReadReviewAccessLog;
        StringSign += ReadRegistrationAccessLog;
        StringSign += ManageMedia;
        StringSign += ReadPermission;
        StringSign += UpdatePermissin;
        // StringSign = 'PASSS';
        // StringSign = userindex;
        // StringSign = usertoken;
        console.log(StringSign);

        let body = {
            'detailuserindex' : id,
            'isreadreview' :  ReadReview,
            'isapprovereview' :  ApproveReview,
            'isrejectreview' :  RejectReview,
            'isreadwalletbalance' :  ReadWalletBallance,
            'isreadwallethistory' :  ReadWalletHistory,
            'isreaduserdetail' :  ReadUserDetail,
            'isblockaccount' :  BlockAccount,
            'iscloseaccount' :  CloseAccount,
            'isapproveregistration' :  ApproveRegistration,
            'isrejectregistration' :  RejectRegistration,
            'isreadreviewaccesslog' :  ReadReviewAccessLog,
            'isreadregistrationaccesslog' :  ReadRegistrationAccessLog,
            'ismanagemedia' :  ManageMedia,
            'isreadpermission' :  ReadPermission,
            'isupdatepermission' : UpdatePermissin,
            
        }


        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();
        console.log(signature);


        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature
        }

        let response = await axios.post('https://back.thespace.world/api/user/v01/admin/editpermission', body, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            setShowModal(false);
            let userdata = JSON.stringify(response.data.data.user);
            localStorage.setItem("uuid",userdata);
            console.log(response.data.data.user);
            userHandle();
        }

        console.log(response.data);
    }

    return (
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                        <div className="flex flex-row m-3 ml-0">
                            <a href='/admin'>
                                <FiArrowLeft size={25} className="mt-1 mr-1 hover:cursor-pointer" />
                            </a>
                            <div className="flex  flex-col">
                                <h1 className='text-2xl max-sm:text-xl'>EditAdmin</h1>
                                <h2 className="text-sm max-sm:text-xs">../Admin/DetailAdmin</h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row max-sm:flex-col">
                        <div className="flex flex-col w-full h-full bg-black border rounded-lg p-3 mt-1">
                            <div>
                            <h1 className="text-white">Data Admin</h1>
                            <div className="flex flex-col h-full py-5">
                                <div className="flex flex-row pb-3 w-full">
                                    <div className="flex content-start w-[25%] max-sm:w-[35%] bg-[#1E1F23] rounded-md p-2 mr-3 max-sm:mr-2">
                                        <span className="text-white text-sm">Full Name</span>
                                    </div>
                                    <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                        <input className="text-sm  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.fullname}></input>
                                    </div>
                                </div>
                                <div className="flex flex-row pb-3 w-full">
                                    <div className="flex content-start w-[25%] max-sm:w-[35%] bg-[#1E1F23] rounded-md p-2 mr-3 max-sm:mr-2">
                                        <span className="text-white text-sm">Email</span>
                                    </div>
                                    <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                        <input className="text-sm  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.email}></input>
                                    </div>
                                </div>
                                <div className="flex flex-row pb-3 w-full">
                                    <div className="flex content-start w-[25%] max-sm:w-[35%] bg-[#1E1F23] rounded-md p-2 mr-3 max-sm:mr-2">
                                        <span className="text-white text-sm">Role</span>
                                    </div>
                                    <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                        <input className="text-sm  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.tipeuser}></input>
                                    </div>
                                </div>
                                <div className="flex flex-row pb-3 w-full">
                                    <div className="flex content-start w-[25%] max-sm:w-[35%] bg-[#1E1F23] rounded-md p-2 mr-3 max-sm:mr-2">
                                        <span className="text-white text-sm">Status</span>
                                    </div>
                                    <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                                        <input className="text-sm  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.status}></input>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="w-[35%] max-sm:w-full border border-spacing-3 p-3 mt-1 max-sm:mt-3 mx-3 max-sm:mx-0 rounded-lg bg-[#1E1F23]">
                            <div>
                            <div className="px-3 mt-3 text-sm text-white">
                                <h1>Action</h1>
                                <div className="flex flex-col">
                                <div className="bg-blue-900 hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg" onClick={() => setShowModal(true)}>
                                    <a className="text-white">Edit Permission</a>
                                </div>
                                <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg">
                                    <a className="text-white">Edit Profile</a>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal ? (
            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-2xl font-semibold">
                                    Edit Permission
                                </h3>
                                <button
                                    className="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                >
                                    <AiOutlineClose
                                        className='fill-black w-[25px]'
                                    />
                                </button>
                            </div>
                            <div className='flex flex-row'>
                                <div className="relative px-5 py-2 flex-auto">
                                    <div className='pt-2'>
                                        <span className=''>Review</span>
                                        <div className="flex items-center mb-2 mt-1">
                                            <input id="default-checkbox" type="checkbox" ref={checklistReadReview} onChange={handleReadReview} checked={readReview === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Read Review</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistApproveReview} onChange={handleApproveReview} checked={approveReview === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Approve Review</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistRejectReview} onChange={handleRejectReview} checked={rejectReview === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Reject Review</label>
                                        </div>
                                    </div>
                                    <div className='pt-2'>
                                        <span className=''>Wallet</span>
                                        <div className="flex items-center mb-2 mt-1">
                                            <input id="default-checkbox" type="checkbox" ref={checklistReadWalletBallance} onChange={handleReadWalletBalance} checked={readWalletBallance === 1 ? true :  false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Read Wallet Ballance</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistReadWalletHistory} onChange={handleReadWalletHistory} checked={readWalletHistory === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Read Wallet History</label>
                                        </div>
                                    </div>
                                    <div className='pt-2'>
                                        <span className=''>User Detail</span>
                                        <div className="flex items-center mb-2 mt-1">
                                            <input id="default-checkbox" type="checkbox" ref={checklistReadUserDetail} onChange={handleReadUserDetail} checked={readUserDetail === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Read User Detail</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistBlockAccount} onChange={handleBlockAccount} checked={blockAccount === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Block Account</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistCloseAccount} onChange={handleCloseAccount} checked={closeAccount === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Close Account</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistApproveRegistration} onChange={handleApproveRegistration} checked={approveRegistration === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Approve Registration</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistRejectRegistration} onChange={handleRejectRegistration} checked={rejectRegistration ===  1 ? true : false}className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Reject Registration</label>
                                        </div>
                                    </div> 
                                    <div className='pt-2'>
                                        <span className=''>Log</span>
                                        <div className="flex items-center mb-2 mt-1">
                                            <input id="default-checkbox" type="checkbox" ref={checklistReadReviewAccessLog} onChange={handleReadReviewAccessLog} checked={readReviewAccessLog === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Read Review Access Log</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistReadRegistrationAccessLog} onChange={handleReadRegistrationAccessLog} checked={readRegistrationAccessLog === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Read Registration Access Log</label>
                                        </div>
                                    </div>
                                    <div className='pt-2'>
                                        <span className=''>Media</span>
                                        <div className="flex items-center mb-2 mt-1">
                                            <input id="default-checkbox" type="checkbox" ref={checklistManageMedia} onChange={handleManageMedia} checked={manageMedia === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Manage Media</label>
                                        </div>
                                    </div>
                                    <div className='pt-2'>
                                        <span className=''>Permission</span>
                                        <div className="flex items-center mb-2 mt-1">
                                            <input id="default-checkbox" type="checkbox" ref={checklistReadPermission} onChange={handleReadPermission} checked={readPermission === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Read Permission</label>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <input id="default-checkbox" type="checkbox" ref={checklistUpdatePermissin} onChange={handleUpdatePermission} checked={updatePermission === 1 ? true : false} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Update Permission</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-white bg-[#0028FC] font-bold uppercase px-6 py-2 w-full text-xs rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-3 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleSave}
                                    >
                                        Save Changes
                                    </button>
                                    <button
                                        className="bg-[#E0E0F3] text-[#0028FC] font-bold uppercase text-xs px-6 py-2 w-full rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Discard
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
            ) : null}
        </>
    )
}

export default Edit_admin
