import React, { useCallback, useEffect, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useParams, } from 'react-router-dom';
import Swal from 'sweetalert2'

import Sidebar from "../../components/Sidebar";
import { FiArrowLeft } from "react-icons/fi";
import YouTube from 'react-youtube';
import Breadcrumbs from '../../components/Breadcrumbs';
import Clipboard from '../../components/Clipboard'

function Detail_review() {
    const { id } = useParams();
    const [reviewDetail, setReviewDetail] = useState({});
    const [geser, setGeser] = useState(false);

    const [imageBig, setImageBig] = useState('');
    const [imageToggle, setImageToggle] = useState(false);

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");
    

    const userHandle = useCallback( async () => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = id;

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature
        }

        let body = {
            'reviewindex' : id
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/review/v01/admin/detailreview?id='+currentTimestamp, body, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            console.log(response.data);
            setReviewDetail(response.data.data[0])
        }
    }, [id])

    const handleApprove = async () =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to take this action?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Approve Review!'
        }).then(async(result) => {
            if (result.isConfirmed) {
                let uuid =  localStorage.getItem("uuid");
                let parseuuid = JSON.parse(uuid);

                let userindex = parseuuid.userindex;
                let usertoken = parseuuid.usertokenlogin;

                let StringSign;
                StringSign = '';
                StringSign += id; 

                let body = {
                    'reviewindex' : id,
                }

                let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

                let header = {
                    'platform' : 'WEBSITE',
                    'gtoken' : 'PASSS',
                    'userindex' : userindex,
                    'tokenlogin' : usertoken,
                    'signature' : signature
                }

                let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/review/v01/admin/approve', body, {
                    'headers' : header
                });

                if(response.data.status.status === 1){
                    Swal.fire(
                        'Approval Success!',
                        'Approval Review successfuly.',
                        'success'
                    )
                    userHandle();
                }else{
                    Swal.fire(
                        response.data.status.message[0].code,
                        response.data.status.message[0].errormessage,
                        'error'
                    )
                }
            }
        })
    }
    
    
    const handleReject = async () =>{
        Swal.fire({
            title: 'Enter your reason?',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Kirim',
            showLoaderOnConfirm: true,
            preConfirm: async (params) => {
                let uuid =  localStorage.getItem("uuid");
                let parseuuid = JSON.parse(uuid);

                let userindex = parseuuid.userindex;
                let usertoken = parseuuid.usertokenlogin;

                let StringSign;
                StringSign = '';
                StringSign += id;

                let body = {
                    'reviewindex' : id,
                    'rejectreason' : params
                }

                let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

                let header = {
                    'platform' : 'WEBSITE',
                    'gtoken' : 'PASSS',
                    'userindex' : userindex,
                    'tokenlogin' : usertoken,
                    'signature' : signature
                }

                let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/review/v01/admin/reject', body, {
                    'headers' : header
                });

                if(response.data.status.status === 1){
                    Swal.fire(
                        'Reject Success!',
                        'Reject Review successfuly.',
                        'success'
                    )
                    userHandle();
                }else{
                    Swal.fire(
                        response.data.status.message[0].code,
                        response.data.status.message[0].errormessage,
                        'error'
                    )
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                Swal.fire(
                    'Reject Success!',
                    'Reject Review successfuly.',
                    'success'
                )
            }
        })
    }

    useEffect(() => {
        userHandle();
    }, [userHandle])

    const opts = {
        height: '200',
        width: '450px'
    };

    const getVideoIdFromUrl = (url) => {
        const regex = /[?&]v=([^&]+)/;
        const match = url ? url.match(regex) : null;
        return match ? match[1] : '';
    };

    return (
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                        <div className="flex flex-row m-3 ml-0">
                            <a href='/listreview'>
                                <FiArrowLeft size={25} className="mt-1 mr-1 hover:cursor-pointer" />
                            </a>
                            <div className="flex  flex-col">
                                <h1 className='text-2xl max-sm:text-xl'>Detail Review</h1>
                                <h2 className="text-sm max-sm:text-xs">
                                    <Breadcrumbs paths={window.location.pathname.split('/')}/>
                                </h2>
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex flex-col mt-3 max-sm:mt-1">
                        <div className="">
                            <div className="w-full">
                                <div className='flex flex-row max-sm:flex-col bg-[#1E1F23] w-full p-5 rounded-lg border text-sm'>
                                    <div className="flex flex-row justify-between w-full">
                                        <div className="flex-auto">
                                            <h1 className='text-[#989B9D] font-bold'>USER</h1>
                                            {console.log(reviewDetail)}
                                            <div className='mt-2'>
                                                <p className='text-[#989B9D]'>Developer Username</p>
                                                <p className='text-white font-semibold py-1'>
                                                    <a href={`/user/${reviewDetail.developeruserindex}`} className='underline'>{reviewDetail.developerusername}</a>
                                                </p>
                                                <p className='text-[#989B9D]'>Gamers Username</p>
                                                <p className='text-white font-semibold py-1'>
                                                    <a href={`/user/${reviewDetail.tablereviewgameruserindex}`} className="underline">{reviewDetail.gamerusername}</a>
                                                </p>
                                            </div>

                                            <h1 className='text-[#989B9D] font-bold pt-5'>THREAD</h1>
                                            <div className='mt-2'>
                                                <p className='text-[#989B9D]'>Thread ID</p>
                                                <p className='text-white font-semibold py-1'>
                                                    <a href={`/threads/${reviewDetail.tablethreadindex}`} className='underline'>{reviewDetail.tablethreadindex}</a>
                                                </p>
                                                <p className='text-[#989B9D]'>Thread Title</p>
                                                <p className='text-white font-semibold py-1'>{reviewDetail.tablethreadtitle}</p>
                                                <p className='text-[#989B9D]'>Minimun Word Count</p>
                                                <p className='text-white font-semibold py-1'>{reviewDetail.minimumWordCount}</p>
                                            </div>

                                            <h1 className='text-[#989B9D] font-bold pt-5'>STATUS</h1>
                                            <div className='mt-2'>
                                                <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{reviewDetail.tablereviewstatus}</span>
                                            </div>
                                        </div>
                                        <div className="flex-auto">
                                            <h1 className='text-[#989B9D] font-bold'>WALLET</h1>
                                            <div className='mt-2'>
                                                <p className='text-[#989B9D]'>Allocation Balance</p>
                                                <p className='text-[#BA6719] font-semibold py-1 text-[30px]'>{reviewDetail.total_coin} ANOA</p>
                                            </div>

                                            <h1 className='text-[#989B9D] font-bold pt-5'>REWARD</h1>
                                            <div className='mt-2'>
                                                <p className='text-[#989B9D] mb-1'>Coin Schema</p>
                                                <div className='flex flex-row items-center justify-between mb-1'>
                                                    <p className='text-white'>Write Review</p>
                                                    <p className='text-[#BA6719]'>{reviewDetail.reviewreward} ANOA</p>
                                                </div>
                                                <div className='flex flex-row items-center justify-between mb-1'>
                                                    <p className='text-white'>Upload Experience</p>
                                                    <p className='text-[#BA6719]'>{reviewDetail.uploadmediareward} ANOA</p>
                                                </div>
                                                <div className='flex flex-row items-center justify-between mb-1'>
                                                    <p className='text-white'>Disbursement Fee</p>
                                                    <p className='text-[#BA6719]'>-{reviewDetail.disbursementfee} ANOA</p>
                                                </div>
                                                <div className='flex flex-row items-center justify-between mb-1'>
                                                    <p className='text-white'>Total Reward</p>
                                                    <p className='text-[#BA6719]'>{reviewDetail.totalReward} ANOA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col bg-[#1E1F23] w-full p-5 rounded-lg border text-sm mt-3'>
                                    <h1 className='text-[#989B9D] font-bold pt-5'>REVIEW</h1>
                                    <div className='mt-2'>
                                        <p className='text-[#989B9D]'>Rating</p>
                                        <p className='text-white font-semibold py-1'>{reviewDetail.tablereviewrating}</p>
                                        <p className='text-[#989B9D]'>Review</p>
                                        <p className='text-white font-semibold py-1'>"{reviewDetail.tablereviewdescription}"</p>
                                        <p className='text-[#989B9D]'>Word Count</p>
                                        <p className='text-white font-semibold py-1'>{reviewDetail.wordCount}</p>

                                        <p className='text-[#989B9D]'>Review With Youtube</p>
                                        <p className='text-white font-semibold py-1'>
                                            {reviewDetail.tablereviewyoutubelink ?
                                            <a href={reviewDetail.tablereviewyoutubelink} target='_blank'>{reviewDetail.tablereviewyoutubelink}</a>
                                            : 'No youtube link' }
                                        </p>
                                    </div>
                                    <div class="grid grid-cols-5 gap-2 mt-5">
                                        {console.log(reviewDetail)}
                                        {reviewDetail.tablereviewpictpath ?
                                        reviewDetail.tablereviewpictpath.map((result, key) => (
                                            <img src={`https://back.thespace.world/public/`+result} alt="review-image" className='w-full h-[200px]' key={key}/>
                                        )) : '' }

                                        {reviewDetail.tablereviewvideopath ?
                                        reviewDetail.tablereviewvideopath.map((result, key) => (
                                            <video controls width="320" height="240">
                                                <source src={'https://back.thespace.world/public/'+result}/>
                                                Your browser does not support the video tag.
                                            </video>
                                        )) : '' }
                                    </div>
                                </div>
                                {reviewDetail.tablereviewstatus !== 'REJECT' && reviewDetail.tablereviewstatus !== 'APPROVE' ?
                                <div className="flex flex-row bg-[#1E1F23] w-full p-5 rounded-lg border text-sm mt-3">
                                    <button onClick={handleReject} className="bg-red-700  hover:bg-red-400 cursor-pointer text-center p-1 rounded-lg w-full mr-5 text-white">
                                        Reject
                                    </button>
                                    <button onClick={handleApprove} className="bg-blue-700  hover:bg-blue-400 cursor-pointer text-center p-1 rounded-lg w-full text-white">
                                        Approve
                                    </button>
                                </div>: '' }
                            </div>
                        </div>
                    </div> */}

                    <div className='w-full h-full mx-8'>
                        <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Review Detail</h2>
                        <div className="flex flex-col gap-3 mb-[32px]">
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Review ID</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <p className="text-white">{reviewDetail.tablereviewindex}</p>
                                        <button onClick={() => Clipboard(reviewDetail.tablereviewindex)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Created Date</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full px-4">
                                    <div className='flex flex-row items-center gap-2'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99166 1.66602C5.39166 1.66602 1.66666 5.39935 1.66666 9.99935C1.66666 14.5993 5.39166 18.3327 9.99166 18.3327C14.6 18.3327 18.3333 14.5993 18.3333 9.99935C18.3333 5.39935 14.6 1.66602 9.99166 1.66602ZM10 16.666C6.31666 16.666 3.33333 13.6827 3.33333 9.99935C3.33333 6.31602 6.31666 3.33268 10 3.33268C13.6833 3.33268 16.6667 6.31602 16.6667 9.99935C16.6667 13.6827 13.6833 16.666 10 16.666ZM9.81666 5.83268H9.76666C9.43333 5.83268 9.16666 6.09935 9.16666 6.43268V10.366C9.16666 10.6577 9.31666 10.9327 9.575 11.0827L13.0333 13.1577C13.3167 13.3243 13.6833 13.241 13.85 12.9577C13.8918 12.8902 13.9197 12.815 13.9318 12.7365C13.944 12.6581 13.9403 12.578 13.9208 12.501C13.9014 12.424 13.8667 12.3517 13.8187 12.2885C13.7707 12.2252 13.7105 12.1722 13.6417 12.1327L10.4167 10.216V6.43268C10.4167 6.09935 10.15 5.83268 9.81666 5.83268Z" fill="white"/>
                                        </svg>
                                        <p className="text-white">{reviewDetail.tablereviewtimestamp}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Thread ID</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'/threads/'+reviewDetail.tablereviewthreadindex} target='_blank'><p className="text-white underline">{reviewDetail.tablereviewthreadindex}</p></a>
                                        <button onClick={() => Clipboard(reviewDetail.tablereviewthreadindex)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Thread Title</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'/threads/'+reviewDetail.tablereviewthreadindex} target='_blank'><p className="text-white underline">{reviewDetail.tablethreadtitle}</p></a>
                                        <button onClick={() => Clipboard(reviewDetail.tablethreadtitle)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Developer Username</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'/user/'+reviewDetail.developeruserindex} target='_blank'><p className="text-white underline">{reviewDetail.developerusername}</p></a>
                                        <button onClick={() => Clipboard(reviewDetail.tablethreadtitle)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Gamer Username</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <div className='flex flex-row items-center px-4 gap-2'>
                                        <a href={'/user/'+reviewDetail.tablereviewgameruserindex} target='_blank'><p className="text-white underline">{reviewDetail.gamerusername}</p></a>
                                        <button onClick={() => Clipboard(reviewDetail.tablethreadtitle)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Minimum Word Count</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{reviewDetail.minimumWordCount}</p>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4"> Status</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">
                                    {reviewDetail.tablereviewstatus === 'REJECTED' ? <span class="bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-orange-700 dark:text-orange-300 border border-orange-300">{reviewDetail.tablereviewstatus}</span> : ''}
                                    {reviewDetail.tablereviewstatus === 'APPROVED' ? <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-700 dark:text-green-400 border border-green-400">{reviewDetail.tablereviewstatus}</span> : ''}
                                    {reviewDetail.tablereviewstatus === 'CANCELED' ? <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">{reviewDetail.tablereviewstatus}</span> : ''}
                                    {reviewDetail.tablereviewstatus === 'PENDING' ? <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-700 dark:text-yellow-400 border border-yellow-400">{reviewDetail.tablereviewstatus}</span> : ''}
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Reason for Rejection</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">{reviewDetail.tablereviewrejectreason !== null ? reviewDetail.tablereviewrejectreason : '-'}</p>
                                </div>
                            </div>

                            {reviewDetail.tablereviewstatusblockchain !== '' ?
                            <div className="flex flex-row gap-3">
                                <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                                    <p className="text-[#BABEC1] px-4">Blockchain Status</p>
                                </div>
                                <div className="bg-[#1E1F23] py-[12px] w-full">
                                    <p className="text-white px-4">
                                        <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">{reviewDetail.tablereviewstatusblockchain}</span>
                                    </p>
                                </div>
                            </div> : '' }

                            
                        </div>

                        <div className='flex flex-row w-full h-full gap-5 mb-3'>
                            <div className='w-full h-full'>
                                <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Token Reward Scheme</h2>
                                <div className='bg-[#1E1F23] p-[12px] flex flex-col justify-between gap-2'>
                                    <div>
                                        <p className='text-[#BABEC1]'>Disbursement Fee</p>
                                        <p className='text-[#4391E7] text-[20px]'>{reviewDetail.disbursementfee} ANOA</p>
                                    </div>
                                    <div>
                                        <p className='text-[#BABEC1]'>Disbursement Fee by</p>
                                        <p className='text-white'>{reviewDetail.tablethreaddisbursementfee}</p>
                                    </div>
                                    <div>
                                        <p className='text-[#BABEC1]'>Estimated Gamers Earnings</p>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>(Condition 1) Write Review & Media Upload</p>
                                            <p className='text-[#4391E7] text-[20px]'>{reviewDetail.EstimatedGamersEarning_WriteReviewAndUploadMedia} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>(Condition 2) Write Review</p>
                                            <p className='text-[#4391E7] text-[20px]'>{reviewDetail.EstimatedGamersEarning_WriteReview} ANOA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full h-full'>
                                <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Gamer Reward</h2>
                                <div className='bg-[#1E1F23] p-[12px] w-full h-full flex flex-col justify-between'>
                                    <div className='w-full h-full flex flex-col gap-1 mb-6'>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Write Review</p>
                                            <p className='text-[#4391E7] text-[20px]'>{reviewDetail.reviewreward} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Media Upload</p>
                                            <p className='text-[#4391E7] text-[20px]'>{reviewDetail.uploadmediareward} ANOA</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Disbursement Fee</p>
                                            <p className='text-[#4391E7] text-[20px]'>{reviewDetail.disbursementfee} ANOA</p>
                                        </div>
                                        <hr/>
                                        {/* <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Subtotal (Condition 1)</p>
                                            <p className='text-[#4391E7] text-[20px]'>{reviewDetail.totalReward} ANOA</p>
                                        </div> */}
                                        <div className='flex flex-row items-center justify-between'>
                                            <p className='text-white'>Total Reward</p>
                                            <p className='text-[#4391E7] text-[20px]'>{reviewDetail.totalReward} ANOA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Review Submission</h2>
                        <div className='bg-[#1E1F23] w-full h-full p-[12px]'>
                            <div className='flex flex-row items-center mb-3'>
                                <div className='w-full'>
                                    <p className='text-[#BABEC1]'>Rating</p>
                                    <span className='text-[20px] text-[#C6E743]'>{reviewDetail.tablereviewrating}</span>
                                    <span className='text-white text-[14px]'>/5</span>
                                </div>
                                <div className='w-full'>
                                    <p className='text-[#BABEC1]'>Word Count</p>
                                    <span className='text-[20px] text-[#C6E743]'>{reviewDetail.wordCount}</span>
                                    <span className='text-white text-[14px]'>/{reviewDetail.minimumWordCount}</span>
                                </div>
                            </div>
                            <p className='text-[#BABEC1]'>Review</p>
                            <div className='bg-black p-[16px] mb-3'>
                                <p className='text-white'>{reviewDetail.tablereviewdescription}</p>
                            </div>
                            <div className='w-full max-w-[900px]'>
                                {console.log(reviewDetail)}
                                <div className="w-full mb-3">
                                    {reviewDetail.tablereviewpictfullpath ?
                                    reviewDetail.tablereviewpictfullpath.length > 0 ?
                                    <>
                                        <p className="text-[#BABEC1] mb-2">Media</p>
                                        <div className="grid grid-cols-3 gap-3">
                                            {reviewDetail.tablereviewpictfullpath.map((result, key) => (
                                                <div className="flex flex-col items-center gap-3" key={key}>
                                                    <img src={result} alt="atwork" className="h-[200px] w-full object-contain"/>
                                                    <div onClick={() => {setImageToggle(true); setImageBig(result)}} className='cursor-pointer'>
                                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M21.6666 21L15.6666 15M10.6666 7V10M10.6666 10V13M10.6666 10H13.6666M10.6666 10H7.66663M17.6666 10C17.6666 13.866 14.5326 17 10.6666 17C6.80063 17 3.66663 13.866 3.66663 10C3.66663 6.13401 6.80063 3 10.6666 3C14.5326 3 17.6666 6.13401 17.6666 10Z" stroke="#E4E8EC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    : '' : ''}
                                </div>
                                <div className="flex flex-row justify-between w-full gap-5">
                                    {reviewDetail.tablereviewvideofullpath ?
                                    reviewDetail.tablereviewvideofullpath.length > 0 ?
                                    <div className="w-full">
                                        <p className="text-[#BABEC1] mb-2">Video</p>
                                        
                                        <div className="w-[full]">
                                            <video controls className="w-full h-[200px] object-contain">
                                                <source src={reviewDetail.tablereviewvideofullpath[0]} type="video/mp4"/>
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                    : '' : ''}
                                    <div className="w-full">
                                        {reviewDetail.tablereviewyoutubelink !== null ?
                                        <>
                                            <p className="text-[#BABEC1] mb-2">Youtube</p>
                                            <div>
                                                <YouTube videoId={getVideoIdFromUrl(reviewDetail.tablereviewyoutubelink)} opts={opts}/>
                                            </div>
                                        </>
                                        : '' }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {reviewDetail.tablereviewstatus !== 'REJECTED' && reviewDetail.tablereviewstatus !== 'APPROVED' ?
                        <div className="flex flex-row w-full p-5 rounded-lg text-sm mt-3">
                            <button onClick={handleReject} className="bg-red-700  hover:bg-red-400 cursor-pointer text-center p-1 rounded-lg w-full mr-5 text-white">
                                Reject
                            </button>
                            <button onClick={handleApprove} className="bg-blue-700  hover:bg-blue-400 cursor-pointer text-center p-1 rounded-lg w-full text-white">
                                Approve
                            </button>
                        </div>: '' }
                    </div>
                </div>
            </div>

            {imageToggle ?
            <div className="fixed inset-0 z-20" onClick={() => {setImageToggle(false); setImageBig('')}}>
                <div className="w-[800px] m-auto flex flex-col justify-center h-full">
                    <img src={imageBig} alt="atwork" className="w-full object-contain"/>
                </div>
            </div>: '' }
            </>
    )
}

export default Detail_review