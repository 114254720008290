import React, { useRef, useState, useCallback, useEffect } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

import { AiOutlineClose } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";


function Registrasi() {

    function goToLoginPage() {
        navigate('/login');
    }

    const [open, setOpen] = useState(false);
    const [openconfirm, setOpenconfirm] = useState(false);

    const loadRegister = useCallback(async () => {

        let tokentrans =  localStorage.getItem("tokentrans");

        let StringSign;
        StringSign = 'WEBSITE';
        StringSign = 'PASSS';
        StringSign = 'admin';

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'signature' : signature
        }

        let body = {
            'tokentrans' : tokentrans

        }

        let response = await axios.post('https://back.thespace.world/api/auth/v01/detailid', body, {
            'headers' : header
        });

        console.log(response.data)

        if(response.data.status.status === 1){
            // console.log(response.data.data.user);
            // localStorage.setItem('tokentrans', response.data.status.tokentrans)
            // navigate('/register');
        }
    }, []);

    const inputFullname = useRef();
    const inputUsername = useRef();
    const inputEmail = useRef();
    const inputPhoneCountryCode = useRef();
    const inputPhoneNumberShort = useRef();
    const inputOrganization = useRef();
    const inputAccountType = useRef();
    const inputPassword = useRef();
    const inputConfirmPassword = useRef();


    const [errorFullname, setErrorFullName] = useState('');
    const [errorUsername, setErrorUsername] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorPhoneCountryCode, setErrorPhoneCountryCode] = useState('');
    const [errorPhoneNumberShort, setErrorPhoneNumberShort] = useState('');
    const [errorOrganization, setErrorOrganization] = useState('');
    const [errorAccountType, setErrorAccountType] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

    const navigate = useNavigate();

    const registerHandle = async (e) => {
        e.preventDefault();
        
        let fullname = inputFullname.current.value;
        let username = inputUsername.current.value;
        let email = inputEmail.current.value;
        let phonecountrycode = inputPhoneCountryCode.current.value;
        let phonenumbershort = inputPhoneNumberShort.current.value;
        let organization = inputOrganization.current.value;
        let accounttype = inputAccountType.current.value;
        let password = inputPassword.current.value;
        let confirmpassword = inputConfirmPassword.current.value;

        let tokentrans =  localStorage.getItem("tokentrans");

        let StringSign;
        StringSign = 'WEBSITE';
        StringSign = 'PASSS';
        StringSign = username;
        StringSign = password;
        StringSign = 'admin';

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'signature' : signature
        }

        let body = {
            'fullname' :fullname,
            'username' :username,
            'email' :email,
            'phonecountrycode' :phonecountrycode,
            'phonenumbershort' :phonenumbershort,
            'organization' :organization,
            'accounttype' :accounttype,
            'password' :password,
            'confirmpassword' :confirmpassword,
            'tokentrans' :tokentrans,
        }

        let response = await axios.post('https://back.thespace.world/api/auth/v01/store', body, {
            'headers' : header
        });

        console.log(response.data)

        if(response.data.status.status === 1){
            console.log(response.data.data.user);
            navigate('/login')
        }

        if(response.data.status.status === 0){
            response.data.status.message.forEach(element => {
                switch(element.code.substring(0, 9)){
                    case '008005020':
                        setErrorFullName(element.errormessage);
                    break;
                    case '008005015':
                        setErrorUsername(element.errormessage);
                    break;
                    case '008005035':
                        setErrorEmail(element.errormessage);
                    break;
                    case '008005060':
                        setErrorPhoneNumberShort(element.errormessage);
                    break;
                    case '':
                        setErrorOrganization(element.errormessage);
                    break;
                    case '008005115':
                        setErrorPassword(element.errormessage);
                    break;
                    case '008005115':
                        setErrorConfirmPassword(element.errormessage);
                    break;
                    default:
                        //
                    break;
                }
            })
        }

        console.log(response.data);
    }
    
    const [telephone, setTelephone] = useState("");
    const [countryCode, setCountryCode] = useState("+1"); // set default country code to +1
    
    const handleTelephoneChange = (e) => {
        setTelephone(e.target.value);
    };
    
    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);
    };

    const [selectedJobTitle, setSelectedJobTitle] = useState("");

    const jobTitles = [
        "admin",
        "Manager",
        "Supervisor",
        "Team Leader",
        "Developer",
        "Designer",
    ];

    const handleJobTitleChange = (e) => {
        setSelectedJobTitle(e.target.value);
    };

    useEffect(() => {
        loadRegister()
    }, [loadRegister]);

    return (
        <>
        <div className='bg-black'>
            <div className="flex flex-col items-center min-h-screen pt-6 sm:justify-cente sm:pt-0">
                <div className="w-full px-6 py-4 mt-8 overflow-hidden sm:max-w-md sm:rounded-lg bg-[#1E1F23] shadow-xl shadow-gray-300">
                    <div className=''>
                        <a href="/">
                            <h1 className='text-3xl font-semibold text-center text-gray-300'>Register</h1>
                        </a>
                        <button onClick={goToLoginPage}
                            className="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            
                        >
                            <AiOutlineClose
                                className='fill-gray-300 w-[25px]'
                            />
                        </button>
                    </div>
                    <form className='mt-5' onSubmit={(e) => registerHandle(e)}>
                        <div className='relative'>
                            <label
                                className="block text-sm font-medium text-gray-300 undefined"
                            >
                                Full Name
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    ref={inputFullname}
                                    type="text"
                                    name="name"
                                    className="block w-full mt-1 border px-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                                {errorFullname ? <span className='text-red-500'>{errorFullname}</span> : ''}
                            </div>
                        </div>
                        <div className='mt-2 relative'>
                            <label
                                className="block text-sm font-medium text-gray-300 undefined"
                            >
                                User Name
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    ref={inputUsername}
                                    type="text"
                                    name="name"
                                    className="block w-full mt-1 border px-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                                {errorUsername ? <span className='text-red-500'>{errorUsername}</span> : ''}
                            </div>
                        </div>
                        <div className="mt-2">
                            <label
                                className="block text-sm font-medium text-gray-300 undefined"
                            >
                                Email
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    ref={inputEmail}
                                    type="email"
                                    name="email"
                                    className="block w-full mt-1 border px-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                                {errorEmail ? <span className='text-red-500'>{errorEmail}</span> : ''}
                            </div>
                        </div>
                        <div className="mt-2 relative">
                            <label
                                className="block text-sm font-medium text-gray-300 undefined"
                            >
                                No Hp
                            </label>
                            <div className="flex items-center">
                                <select
                                    ref={inputPhoneCountryCode}
                                    className="mr-2 mt-1 rounded-md border px-2 py-1"
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                >
                                    <option value="62">+62</option>
                                    <option value="63">+63</option>
                                    <option value="65">+65</option>
                                    <option value="66">+66</option>
                                    <option value="69">+69</option>
                                </select>
                                <input
                                    ref={inputPhoneNumberShort}
                                    type="tel"
                                    className="flex-1 rounded-md border px-2 py-1 mt-1"
                                    placeholder="Telephone"
                                    value={telephone}
                                    onChange={handleTelephoneChange}
                                />
                            </div>
                            {errorPhoneNumberShort ? <span className='text-red-500'>{errorPhoneNumberShort}</span> : ''}
                        </div>
                        <div className='mt-2 relative'>
                            <label
                                className="block text-sm font-medium text-gray-300 undefined"
                            >
                                Account Type
                            </label>
                            <div className="relative">
                                <select
                                    ref={inputAccountType}
                                    className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 mt-1 px-2 py-2 pr-8 rounded-md shadow leading-tight focus:outline-none focus:shadow-outline"
                                    value={selectedJobTitle}
                                    onChange={handleJobTitleChange}
                                >
                                    <option value="">Selected Job Title</option>
                                    {jobTitles.map((title) => (
                                        <option key={title} value={title}>
                                            {title}
                                        </option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-300">
                                    <svg
                                        className="fill-black h-5 w-5 transform rotate-90"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M6.293 7.293a1 1 0 0 0 0 1.414L10.586 13l-4.293 4.293a1 1 0 1 0 1.414 1.414l4.292-4.293a1 1 0 0 0 0-1.414l-4.292-4.292a1 1 0 0 0-1.414 0z"
                                    />
                                    </svg>
                                </div>
                                {errorAccountType ? <span className='text-red-500'>{errorAccountType}</span> : ''}
                            </div>
                        </div>
                        <div className="mt-2 relative">
                            <label
                                className="block text-sm font-medium text-gray-300 undefined"
                            >
                                Organisasion
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    ref={inputOrganization}
                                    type="text"
                                    name="organisasion"
                                    className="block w-full mt-1 border px-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                                {errorOrganization ? <span className='text-red-500'>{errorOrganization}</span> : ''}
                            </div>
                        </div>
                        <div className="mt-2 relative">
                            <label
                                className="block text-sm font-medium text-gray-300 undefined"
                            >
                                Password
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    ref={inputPassword}
                                    type={open === false ? 'password' : 'text'}
                                    name="password"
                                    className="block w-full mt-1 border px-2 py-1 border-gray-300 rounded-md shadow-sm"
                                />
                                {open ?
                                <FiEye size={23} className={`absolute cursor-pointer right-[10px] top-[29px]`}
                                    onClick={() => setOpen(!open)}
                                /> :
                                <FiEyeOff size={23} className={`absolute cursor-pointer right-[10px] top-[29px]`} onClick={() => setOpen(!open)}/>
                                }
                                {errorPassword ? <span className='text-red-500'>{errorPassword}</span> : ''}
                            </div>
                        </div>
                        <div className="mt-2 relative">
                            <label
                                className="block text-sm font-medium text-gray-300 undefined"
                            >
                                Confirm Password
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    ref={inputConfirmPassword}
                                    type={openconfirm === false ? 'password' : 'text'}
                                    name="password_confirmation"
                                    className="block w-full mt-1 border px-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                                {openconfirm ?
                                <FiEye size={23} className={`absolute cursor-pointer right-[10px] top-[29px]`}
                                    onClick={() => setOpenconfirm(!openconfirm)}
                                /> :
                                <FiEyeOff size={23} className={`absolute cursor-pointer right-[10px] top-[29px]`} onClick={() => setOpenconfirm(!openconfirm)}/>
                                }
                                {errorConfirmPassword ? <span className='text-red-500'>{errorConfirmPassword}</span> : ''}
                            </div>
                        </div>
                        <div className="flex items-center justify-end mt-6 mb-4">
                            <button
                                type="submit"
                                className="inline-flex items-center px-4 py-2 ml-4 text-xs font-semibold tracking-widest text-white uppercase transition duration-150 ease-in-out bg-blue-700 hover:bg-blue-900 border border-transparent rounded-md"
                            >
                                Register
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    );
}

export default Registrasi;