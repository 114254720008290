import { useLocation, Link } from "react-router-dom";

const Breadcrumbslatest = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const capitalizeName = (name) => {
        return name.replace(/\b\w/g, (match) => match.toUpperCase());
    }

    return(
        <div className="inline-flex rounded-[12px] items-center gap-2">
            {pathnames.map((name, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathnames.length - 1;

                return (
                    <>
                    {isLast ? (
                        <span className="text-[#BABEC1] font-semibold">{capitalizeName(name)}</span>
                    ) : (
                        <Link to={routeTo} className="text-black font-bold">
                        {capitalizeName(name)}
                        </Link>
                    )}
                    {!isLast && 
                    <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 4.16659L13.3333 9.99992L7.5 15.8333" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span> }
                    </>
                );
            })}
        </div>
    );
}

export default Breadcrumbslatest;