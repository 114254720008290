import React, { useCallback, useEffect, useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../../components/Sidebar'
import { FiEye } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import {FiFilter} from "react-icons/fi";
import { FiX } from 'react-icons/fi';

import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import Clipboard from '../../components/Clipboard'
import { format } from 'date-fns';
import Breadcrumbs from "../../components/Breadcrumbs";

function User() {
    const [geser, setGeser] = useState(false);
    const [totalRow, setTotalRow] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [endCount, setEndCount] = useState(0);

    const [showRecord, setShowRecord] = useState(10);
    
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');

    const [startDateLastLogin, setStartDateLastLogin] = useState('');
    const [endDateLastLogin, setEndDateLastLogin] = useState('');

    const [startDateJoin, setStartDateJoin] = useState('');
    const [endDateJoin, setEndDateJoin] = useState('');

    const inputSearch = useRef();

    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('');

    const handleToggleSidebar = () => {
      if(localStorage.getItem('gesertoggle') !== null){
        localStorage.removeItem("gesertoggle");
        setGeser(true);
      }else{
        if(geser === false){
          setGeser(true);
          localStorage.setItem("gesertoggle", "true");
        }else{
          setGeser(false);
          localStorage.removeItem("gesertoggle");
        }
      }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    const [filterByUserTipe, setFilterByUserTipe] = useState([]);
    const handleUserType = (type) => {
      if(type === 'gamer'){
        let filterUserType = userList.filter(item => item.tipeuser === type);
        setFilterByUserTipe(filterUserType);
      }else{
        let filterUserType = userList.filter(item => item.tipeuser === type);
        setFilterByUserTipe(filterUserType);
      } 
    };


    const [filterByStatusUser, setFilterByStatusUser] = useState([]);
    const handleStatusUser = (type) => {
      if(type === 'active'){
        let filterUserStatus = userList.filter(item => item.status === type);
        setFilterByStatusUser(filterUserStatus);
      }else{
        let filterUserStatus = userList.filter(item =>  item.status === type);
        setFilterByStatusUser(filterUserStatus);
      }
    }

    const [isPopUpOpen, setPopUpOpen] = useState(false);
    const handleFilterMobile = () => {
      setPopUpOpen(!isPopUpOpen);
    }

    const handleClose = () => {
      setPopUpOpen(false);
    }

    const userHandle = useCallback( async (paginate, startDate, endDate, status, type, startDateLast, endDateLast, search, showrecord) => {

        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        // StringSign = id;
        StringSign = '';
        // StringSign = 'WEBSITE';
        // StringSign = 'PASSS';
        // StringSign = userindex;
        // StringSign = usertoken;

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/listuser?length='+showrecord+'&page='+paginate+'&join_date_from='+startDate+'&join_date_to='+endDate+'&status='+status+'&type='+type+'&last_login_date_from='+startDateLast+'&last_login_date_to='+endDateLast+'&search='+search+'&id='+currentTimestamp, {
            'headers' : header
        });

        if(response.data.status.status === 1){
          setUserList(response.data.data.list_data);
          setTotalRow(response.data.data.total_data);
          const filteredUserList = response.data.data.list_data.filter((user) => {
            return user.username.includes(searchTerm) || user.tipeuser.includes(searchTerm) || user.status.includes(searchTerm);
          });
          setUserList(filteredUserList);
          setFilterByUserTipe(filteredUserList);
        }

        console.log(response.data);
    },[])

    useEffect(() => {
      userHandle(1, '', '', '', '', '', '', '', showRecord);
    }, [userHandle])

    const paginatehandle = (e) => {
      let pageSelected = e.selected + 1;
      setPageNumber(pageSelected);
      userHandle(pageSelected, '', '', status, type, '', '', inputSearch.current.value, showRecord);
    }

    useEffect(() => {
      let endCount = ((pageNumber - 1) + parseInt(showRecord));
      setEndCount(endCount);
    }, [pageNumber, showRecord]);

    const typeHandle = (e) => {
      setType(e.target.value);
      if(startDateLastLogin && endDateLastLogin){
        if(startDateJoin && endDateJoin){
          userHandle(pageNumber, format(startDateJoin, 'yyyy-MM-dd'), format(endDateJoin, 'yyyy-MM-dd'), status, e.target.value, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, showRecord);
        }else{
          userHandle(pageNumber, '', '', status, e.target.value, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, showRecord);
        }
      }else{
        userHandle(pageNumber, '', '', status, e.target.value, '', '', inputSearch.current.value, showRecord);
      }
    }

    const statusHandle = (e) => {
      setStatus(e.target.value);
      if(startDateLastLogin && endDateLastLogin){
        if(startDateJoin && endDateJoin){
          userHandle(pageNumber, format(startDateJoin, 'yyyy-MM-dd'), format(endDateJoin, 'yyyy-MM-dd'), e.target.value, type, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, showRecord);
        }else{
          userHandle(pageNumber, '', '', e.target.value, type, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, showRecord);
        }
      }else{
        userHandle(pageNumber, '', '', e.target.value, type, '', '', inputSearch.current.value, showRecord);
      }
    }

    const lastLoginDateHandle = (dates) => {
      const [start, end] = dates;
      setStartDateLastLogin(start);
      setEndDateLastLogin(end);

      const myArray = dates;
      const allNullValues = myArray.every(value => value === null);

      if(allNullValues !== true){
        if(startDateJoin && endDateJoin){
          userHandle(pageNumber, format(startDateJoin, 'yyyy-MM-dd'), format(endDateJoin, 'yyyy-MM-dd'), status, type, format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd'), inputSearch.current.value, showRecord);  
        }else{
          userHandle(pageNumber, '', '', status, type, format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd'), inputSearch.current.value, showRecord); 
        }
      }else{
        if(startDateJoin && endDateJoin){
          userHandle(pageNumber, format(startDateJoin, 'yyyy-MM-dd'), format(endDateJoin, 'yyyy-MM-dd'), status, type, '', '', inputSearch.current.value, showRecord);
        }else{
          userHandle(pageNumber, '', '', status, type, '', '', inputSearch.current.value, showRecord);
        }
      }
    }

    const joinDateHandle = (dates) => {
      const [start, end] = dates;
      setStartDateJoin(start);
      setEndDateJoin(end);

      const myArray = dates;
      const allNullValues = myArray.every(value => value === null);

      if(allNullValues !== true){
        if(startDateLastLogin && endDateLastLogin){
          userHandle(pageNumber, format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd'), status, type, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, showRecord); 
        }else{
          userHandle(pageNumber, format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd'), status, type, '', '', inputSearch.current.value, showRecord); 
        } 
      }else{
        if(startDateLastLogin && endDateLastLogin){
          userHandle(pageNumber, '', '', status, type, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, showRecord);
        }else{
          userHandle(pageNumber, '', '', status, type, '', '', inputSearch.current.value, showRecord);
        }
      }
    }

    const searchHandle = (e) => {
      if(e.key === 'Enter'){
        inputSearch.current.value = e.target.value;
        if(startDateLastLogin && endDateLastLogin){
          if(startDateJoin && endDateJoin){
            userHandle(pageNumber, format(startDateJoin, 'yyyy-MM-dd'), format(endDateJoin, 'yyyy-MM-dd'), status, type, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, showRecord);
          }else{
            userHandle(pageNumber, '', '', status, type, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, showRecord);
          }
        }else{
          if(startDateJoin && endDateJoin){
            userHandle(pageNumber, format(startDateJoin, 'yyyy-MM-dd'), format(endDateJoin, 'yyyy-MM-dd'), status, type, '', '', inputSearch.current.value, showRecord);
          }else{
            userHandle(pageNumber, '', '', status, type, '', '', inputSearch.current.value, showRecord);
          }
        }
      }
    }

    const handleSort = (key) => {
      if (sortBy === key) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortBy(key);
        setSortOrder('asc');
      }
    };
  
    const sortedData = [...userList].sort((a, b) => {
        const order = sortOrder === 'asc' ? 1 : -1;
        return a[sortBy] > b[sortBy] ? order : -order;
    });

    const showRecordhandle = (e) => {
      setShowRecord(e.target.value);

      if(startDateLastLogin && endDateLastLogin){
        if(startDateJoin && endDateJoin){
          userHandle(pageNumber, format(startDateJoin, 'yyyy-MM-dd'), format(endDateJoin, 'yyyy-MM-dd'), status, type, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, e.target.value);
        }else{
          userHandle(pageNumber, '', '', status, type, format(startDateLastLogin, 'yyyy-MM-dd'), format(endDateLastLogin, 'yyyy-MM-dd'), inputSearch.current.value, e.target.value);
        }
      }else{
        if(startDateJoin && endDateJoin){
          userHandle(pageNumber, format(startDateJoin, 'yyyy-MM-dd'), format(endDateJoin, 'yyyy-MM-dd'), status, type, '', '', inputSearch.current.value, e.target.value);
        }else{
          userHandle(pageNumber, '', '', status, type, '', '', inputSearch.current.value, e.target.value);
        }
      }
    }
  return (
    <>
    <div className="bg-black min-h-full">
      <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
        <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
      </div>
      <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[15px] duration-300 w-full h-full pt-5 pr-10 max-sm:pr-[15px] pb-5`}>
        <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
            <div className="flex flex-row m-3 ml-0">
                {/* <FiArrowLeft size={25} className="mt-1 mr-1" /> */}
                <div className="flex  flex-col">
                    <h1 className='text-2xl max-sm:text-xl'>User</h1>
                    <h2 className="text-sm max-sm:text-xs">
                      <Breadcrumbs paths={window.location.pathname.split('/')}/>
                    </h2>
                </div>
            </div>
            <div className='hidden max-sm:flex flex-row m-3 mt-5 px-3 justify-center rounded-lg bg-gray-600' onClick={handleFilterMobile}>
              <div className='mt-3'>
                <FiFilter size={18} />
              </div>
              <div className='ml-2 mt-2'>
                <span>Filter</span>
              </div>
            </div>
            {/* filter version for mobile */}
            {isPopUpOpen && (
              <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                <div className="max-w-full max-h-full sm:max-w-md sm:max-h-md bg-white rounded-lg p-3">
                  <div className="flex justify-end">
                    <div onClick={handleClose}>
                      <FiX size={18} />
                    </div>
                  </div>
                  <div className='max-sm:w-full h-full border border-spacing-3 p-3 mt-1 ml-3 max-sm:ml-0 rounded-lg bg-[#1E1F23]'>
                    <div className=''>
                      <div className="px-3 mt-3 text-sm text-white max-w-full">
                        <span className="text-base text-white">Search</span>
                        <div className="relative">
                            <label htmlFor="hs-table-search" className="sr-only">
                                Search
                            </label>
                            <input
                                type="text"
                                name="hs-table-search"
                                id="hs-table-search"
                                className="block p-2 pl-10 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                                <svg
                                    className="h-3.5 w-3.5 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </div>
                      </div>
                      </div>
                      <div className="px-3 mt-3 text-sm text-white">
                        <h1>Filter By User Type</h1>
                        <div className="flex flex-col">
                          <div className="bg-blue-900 hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg"
                            onClick={() => handleUserType("developer")}
                          >
                            <a className="text-white">Developer</a>
                          </div>
                          <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg"
                            onClick={() => handleUserType("gamer")}
                          >
                            <a className="text-white">Gamers</a>
                          </div>
                        </div>
                      </div>
                      <div className="px-3 mt-3 text-sm text-white">
                        <h1>Filter By Status User</h1>
                        <div className="flex flex-col">
                          <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg"
                            onClick={() => handleStatusUser("active")}
                          >
                            <a className="text-white">Active</a>
                          </div>
                          <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg"
                            onClick={() => handleStatusUser("need review")}
                          >
                            <a className="text-white">Need Review</a>
                          </div>
                          <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg"
                            onClick={() => handleStatusUser("block")}
                          >
                            <a className="text-white">BLock</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* and filter version mobile */}
        </div>
        <div className="flex flex-row">
          <div className="w-full">
            <div className="flex flex-row">
              <div className="overflow-auto">
                  <div className="p-1.5 w-full inline-block align-middle">
                      <div className="overflow-auto max-sm:overflow-auto rounded-xl">
                          <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-300">
                                  <tr>
                                      <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                      >
                                          <div className="flex flex-row items-center gap-2">
                                              <span>Username</span>
                                              <button onClick={() => handleSort('username')}>
                                                  {sortBy === 'username' && sortOrder === 'asc' ? 
                                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>
                                                  : 
                                                      
                                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>  
                                                  }
                                              </button>
                                          </div>
                                      </th>
                                      <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                      >
                                          <div className="flex flex-row items-center gap-2">
                                              <span>User Type</span>
                                              <button onClick={() => handleSort('tipeuser')}>
                                                  {sortBy === 'tipeuser' && sortOrder === 'asc' ? 
                                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>
                                                  : 
                                                      
                                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>  
                                                  }
                                              </button>
                                          </div>
                                      </th>
                                      <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                      >
                                          <div className="flex flex-row items-center gap-2">
                                              <span>Email</span>
                                              <button onClick={() => handleSort('email')}>
                                                  {sortBy === 'email' && sortOrder === 'asc' ? 
                                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>
                                                  : 
                                                      
                                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>  
                                                  }
                                              </button>
                                          </div>
                                      </th>
                                      <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                      >
                                          <div className="flex flex-row items-center gap-2">
                                              <span>Wallet Address</span>
                                              <button onClick={() => handleSort('wallet_address')}>
                                                  {sortBy === 'wallet_address' && sortOrder === 'asc' ? 
                                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>
                                                  : 
                                                      
                                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>  
                                                  }
                                              </button>
                                          </div>
                                      </th>
                                      <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                      >
                                          <div className="flex flex-row items-center gap-2">
                                              <span>Status</span>
                                              <button onClick={() => handleSort('status')}>
                                                  {sortBy === 'status' && sortOrder === 'asc' ? 
                                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>
                                                  : 
                                                      
                                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>  
                                                  }
                                              </button>
                                          </div>
                                      </th>
                                      <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                      >
                                          <div className="flex flex-row items-center gap-2">
                                              <span>Join Date</span>
                                              <button onClick={() => handleSort('joindate')}>
                                                  {sortBy === 'joindate' && sortOrder === 'asc' ? 
                                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>
                                                  : 
                                                      
                                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>  
                                                  }
                                              </button>
                                          </div>
                                      </th>
                                      <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                      >
                                          <div className="flex flex-row items-center gap-2">
                                              <span>Last Login</span>
                                              <button onClick={() => handleSort('lastlogin')}>
                                                  {sortBy === 'lastlogin' && sortOrder === 'asc' ? 
                                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>
                                                  : 
                                                      
                                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>  
                                                  }
                                              </button>
                                          </div>
                                      </th>
                                      <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-center text-gray-600 uppercase whitespace-nowrap"
                                      >
                                          Action
                                      </th>
                                  </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-300 text-gray-300">
                              {sortedData
                                .filter((result) =>
                                  result.username.toLowerCase().includes(searchTerm.toLowerCase()) || 
                                  result.tipeuser.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                  result.status.toLowerCase().includes(searchTerm.toLowerCase())
                                )
                                .map((result) => (
                                    <tr className={result.status === 'NOT ACTIVE' ? 'bg-[#21217A]' : ''}>
                                      <td className="px-6 py-4 text-sm whitespace-nowrap">
                                        <div className='flex flex-row items-center gap-2'>
                                          <a href={'/user/'+result.userindex} target='_blank' className='underline'>{result.username}</a>
                                          <button onClick={() => Clipboard(result.username)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                          </button>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-sm whitespace-nowrap">
                                        {result.tipeuser}
                                      </td>
                                      <td className="px-6 py-4 text-sm whitespace-nowrap">
                                        {result.email}
                                      </td>
                                      <td className="px-6 py-4 text-sm whitespace-nowrap">
                                        {result.wallet_address !== null ?
                                        <div className='flex flex-row items-center gap-2'>
                                          <span>{result.wallet_address}</span>
                                          <button onClick={() => Clipboard(result.wallet_address)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                          </button>
                                        </div> : '-' }
                                      </td>
                                      <td className="px-6 py-4 text-sm whitespace-nowrap">
                                        {result.status === 'NOT ACTIVE' ? 
                                        <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{result.status}</span>
                                        : '' }
                                        {result.status === 'NEED REVIEW' ? 
                                        <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.status}</span>
                                        : '' }
                                        {result.status === 'REJECTED' ? 
                                        <span class="bg-orange-100 text-orange-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-orange-900 dark:text-orange-300">{result.status}</span>
                                        : '' }
                                        {result.status === 'ACTIVE' ? 
                                        <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.status}</span>
                                        : '' }
                                        {result.status === 'FREEZE' ? 
                                        <span class="bg-cyan-100 text-cyan-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-cyan-900 dark:text-cyan-300">{result.status}</span>
                                        : '' }
                                        {result.status === 'BLOCKED' ? 
                                        <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{result.status}</span>
                                        : '' }
                                        {result.status === 'SUSPENDED' ? 
                                        <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{result.status}</span>
                                        : '' }
                                        {result.status === 'DELETED' ? 
                                        <span class="bg-black text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-black dark:text-white">{result.status}</span>
                                        : '' }
                                      </td>
                                      <td className="px-6 py-4 text-sm whitespace-nowrap">
                                        {result.joindate}
                                      </td>
                                      <td className="px-6 py-4 text-sm whitespace-nowrap">
                                        {result.lastlogin}
                                      </td>
                                      <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                          <a
                                              className="text-gray-300 hover:text-gray-100"
                                              href={`/user/${result.userindex}`}
                                          >
                                            <div className='flex justify-center px-1 py-1 bg-[#0028FC] rounded-md'>
                                                <FiEye size={25} className="" />
                                            </div>
                                          </a>
                                      </td>
                                  </tr>
                                  ))}
                              </tbody>
                          </table>
                      </div>
                      <div className="flex flex-row items-center justify-between w-full">
                          <div className="text-white">
                            Showing <span className="font-bold">{pageNumber} - {endCount}</span> of <span className="font-bold">{totalRow}</span>
                          </div>
                          <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              pageCount={Math.ceil(totalRow / showRecord)}
                              containerClassName={'flex inline-flex bg-white shadow rounded'}
                              previousClassName={'px-3 py-2 rounded-l-md border border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800'}
                              nextClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-r border-gray-200 rounded-r-md'}
                              pageClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                              breakClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                              activeClassName={'bg-[#E1EFFE]'}
                              onPageChange={(e) => paginatehandle(e)}
                          />
                          </div>
                  </div>
              </div>
              <div className="w-[25%] mx-3">
                  <div className="flex flex-col gap-5">
                      <div>
                          <h1 className="text-white text-[15px] font-semibold">Search</h1>
                          <div className="relative">
                              <input type="text" className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D]" placeholder="Search" ref={inputSearch} onKeyDown={searchHandle}/>
                              <div className="absolute top-[50%] right-3 translate-y-[-50%]">
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M2.5 17.5L7.5 12.5M5.83333 8.33333C5.83333 11.555 8.44501 14.1667 11.6667 14.1667C14.8883 14.1667 17.5 11.555 17.5 8.33333C17.5 5.11167 14.8883 2.5 11.6667 2.5C8.44501 2.5 5.83333 5.11167 5.83333 8.33333Z" stroke="#707375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                              </div>
                          </div>
                      </div>

                      <div>
                          <h1 className="text-white text-[15px] font-semibold">Join Date</h1>
                          <div className="customDatePickerWidth">
                            <DatePicker
                                onChange={joinDateHandle}
                                startDate={startDateJoin}
                                endDate={endDateJoin}
                                selectsRange={true}
                                placeholderText="ALL"
                                isClearable={true}
                                dateFormat={'dd/MM/yyyy'}
                                className="bg-[#1E1F23] py-[6px] pl-[16px] outline-none rounded text-[#989B9D] cursor-pointer w-full"
                              />
                          </div>
                      </div>

                      <div>
                          <h1 className="text-white text-[15px] font-semibold">Last Login</h1>
                          <div className="customDatePickerWidth">
                              <DatePicker
                                onChange={lastLoginDateHandle}
                                startDate={startDateLastLogin}
                                endDate={endDateLastLogin}
                                selectsRange={true}
                                placeholderText="ALL"
                                isClearable={true}
                                dateFormat={'dd/MM/yyyy'}
                                className="bg-[#1E1F23] py-[6px] pl-[16px] outline-none rounded text-[#989B9D] cursor-pointer w-full"
                              />
                          </div>
                      </div>

                      <div>
                          <h1 className="text-white text-[15px] font-semibold">Status</h1>
                          <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={statusHandle}>
                              <option value=''>ALL</option>
                              <option value='NOT ACTIVE'>NOT ACTIVE</option>
                              <option value='NEED REVIEW'>NEED REVIEW</option>
                              <option VALUE='REJECTED'>REJECTED</option>
                              <option VALUE='ACTIVE'>ACTIVE</option>
                              <option VALUE='FREEZE'>FREEZE</option>
                              <option VALUE='BLOCKED'>BLOCKED</option>
                              <option VALUE='SUSPENDED'>SUSPENDED</option>
                              <option VALUE='DELETED '>DELETED</option>
                          </select>
                      </div>

                      <div>
                          <h1 className="text-white text-[15px] font-semibold">User Type</h1>
                          <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={typeHandle}>
                              <option value=''>ALL</option>
                              <option value='gamer'>Gamer</option>
                              <option value='developer'>Developer </option>
                          </select>
                      </div>

                      <div>
                          <h1 className="text-white text-[15px] font-semibold">Show Record</h1>
                          <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={showRecordhandle}>
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                          </select>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          {/* filter version desktop */}
          {/* <div className='w-[25%] max-sm:hidden max-sm:w-full h-full border border-spacing-3 p-3 mt-1 ml-3 max-sm:ml-0 rounded-lg bg-[#1E1F23]'>
            <div className=''>
              <div className="px-3 mt-3 text-sm text-white max-w-full">
                <span className="text-base text-white">Filter</span>
                <div className='flex flex-row gap-2 items-center mt-2'>
                  <input type="date" ref={startDate} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500'/>
                  <p>To</p>
                  <input type="date" ref={endDate} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500'/>
                </div>

                <select ref={statusInput} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 mt-3'>
                  <option value="">Choose Status</option>
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="NOT ACTIVE">NOT ACTIVE</option>
                  <option value="NEED REVIEW">NEED REVIEW</option>
                  <option value="REJECT">REJECT</option>
                  <option value="BLOCK">BLOCK</option>
                  <option value="DELETED">DELETED</option>
                  <option value="SUSPENDED">SUSPENDED</option>
                </select>
                <button className='bg-blue-900 hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg w-full' onClick={filterHandle}>Filter</button>
                
              </div>
              
            </div>
          </div> */}
          {/* and filter version desktop */}
        </div>
      </div>
    </div>
    </>
  )
}

export default User