import React, { useCallback, useEffect, useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../../components/Sidebar'
import { FiEye } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import { FiX } from 'react-icons/fi';
import {FiFilter} from "react-icons/fi";

import ReactPaginate from "react-paginate";
import DatePicker from 'react-datepicker';
import Clipboard from '../../components/Clipboard'
import { format } from 'date-fns';
import QueryString from '../../components/QueryString';
import Breadcrumbs from "../../components/Breadcrumbs";

function Transaction() {
    const [geser, setGeser] = useState(false);

    const [totalRow, setTotalRow] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [endCount, setEndCount] = useState(0);    

    const [showRecord, setShowRecord] = useState(10);
    const [statusBlockchain, setStatusBlockchain] = useState('');
    const [type, setType] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const inputSearch = useRef();

    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('');

    const params = QueryString('q');
    const params_filter = QueryString('f');

    const handleToggleSidebar = () => {
        if(localStorage.getItem('gesertoggle') !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    const [isPopUpOpen, setPopUpOpen] = useState(false);
    const handleFilterMobile = () => {
        setPopUpOpen(!isPopUpOpen);
    }

    const handleClose = () => {
        setPopUpOpen(false);
    }

    const navigate = useNavigate();
    const [transactionList, setTransactionList] = useState([]);

    const userHandle = useCallback( async (paginate, startDate, endDate, showrecord, statusblockchain, type, search) => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        // StringSign = id;
        StringSign = '';
        // StringSign = 'WEBSITE';
        // StringSign = 'PASSS';
        // StringSign = userindex;
        // StringSign = usertoken;

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature
        }

        let body = {}

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/transaction?length='+showrecord+'&page='+paginate+'&date_from='+startDate+'&date_to='+endDate+'&blockchain_status='+statusblockchain+'&type='+type+'&search='+search+'&id='+currentTimestamp, {
            'headers' : header
        });

        console.log(response.data)

        if(response.data.status.status === 1){
            setTransactionList(response.data.data.list_data);
            setTotalRow(response.data.data.total_data);
        }
    }, [])

    useEffect(() => {
        if(params !== null){
            if(params_filter !== null){
                inputSearch.current.value = params;
                setType(params_filter);
                userHandle(1, '', '', showRecord, '', params_filter, params);
            }else{
                inputSearch.current.value = params;
                userHandle(1, '', '', showRecord, '', '', params);
            }   
        }else{
            userHandle(1, '', '', showRecord, '', '', '');
        }
    }, [userHandle, params, params_filter])

    const paginatehandle = (e) => {
        let pageSelected = e.selected + 1;
        setPageNumber(pageSelected);
        if(startDate && endDate){
            userHandle(pageSelected, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), showRecord, statusBlockchain, type, inputSearch.current.value);
        }else{
            userHandle(pageSelected, '', '', showRecord, statusBlockchain, type, inputSearch.current.value);   
        }
    }

    useEffect(() => {
        let endCount = ((pageNumber - 1) + parseInt(showRecord));
        setEndCount(endCount);
    }, [pageNumber, showRecord]);

    const statusBlockchainHandle = (e) => {
        setStatusBlockchain(e.target.value);
        if(startDate && endDate){
            userHandle(pageNumber, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), showRecord, e.target.value, type, inputSearch.current.value);
        }else{
            userHandle(pageNumber, '', '', showRecord, e.target.value, type, inputSearch.current.value);
        }
    }

    const showRecordHandle = (e) => {
        setShowRecord(e.target.value);
        if(startDate && endDate){
            userHandle(pageNumber, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), e.target.value, statusBlockchain, type, inputSearch.current.value);
        }else{
            userHandle(pageNumber, '', '', e.target.value, statusBlockchain, type, inputSearch.current.value);
        }
    }

    const typeHandle = (e) => {
        setType(e.target.value);
        
        if(startDate && endDate){
            userHandle(pageNumber, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), showRecord, statusBlockchain, e.target.value, inputSearch.current.value);
        }else{
            userHandle(pageNumber, '', '', showRecord, statusBlockchain, e.target.value, inputSearch.current.value);
        }
    }

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        const myArray = dates;
        const allNullValues = myArray.every(value => value === null);

        if(allNullValues !== true){
            userHandle(pageNumber, format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd'), showRecord, statusBlockchain, type, inputSearch.current.value);
        }else{
            userHandle(pageNumber, '', '', showRecord, statusBlockchain, type, inputSearch.current.value);
        }
    }

    const searchHandle = (e) => {
        if(e.key === 'Enter'){
            if(startDate && endDate){
                userHandle(pageNumber, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), showRecord, statusBlockchain, e.target.value, inputSearch.current.value);
            }else{
                userHandle(pageNumber, '', '', showRecord, statusBlockchain, type, inputSearch.current.value);
            }
        }
    }

    const handleSort = (key) => {
        if (sortBy === key) {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
          setSortBy(key);
          setSortOrder('asc');
        }
    };   

    const sortedData = [... transactionList].sort((a, b) => {
        const order = sortOrder === 'asc' ? 1 : -1;
        return a[sortBy] > b[sortBy] ? order : -order;
    });

    const OptionType = ['Allocation', 'Disbursement', 'Refund'];

    return (
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[330px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-5 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                        <div className="flex flex-row m-3 ml-0">
                            {/* <FiArrowLeft size={25} className="mt-1 mr-1" /> */}
                            <div className="flex  flex-col">
                                <h1 className='text-2xl'>Transaction</h1>
                                <h2 className="text-sm">
                                    <Breadcrumbs paths={window.location.pathname.split('/')}/>
                                </h2>
                            </div>
                        </div>
                        <div className='hidden max-sm:flex flex-row m-3 mt-5 px-3 justify-center rounded-lg bg-gray-600' onClick={handleFilterMobile}>
                            <div className='mt-3'>
                                <FiFilter size={18} />
                            </div>
                            <div className='ml-2 mt-2'>
                                <span>Filter</span>
                            </div>
                        </div>
                        {/* filter version for mobile */}
                        {isPopUpOpen && (
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                                <div className="max-w-full max-h-full sm:max-w-md sm:max-h-md bg-white rounded-lg p-3">
                                    <div className="flex justify-end">
                                        <div onClick={handleClose}>
                                        <FiX size={18} />
                                        </div>
                                    </div>
                                    <div className='max-sm:w-full h-full border border-spacing-3 p-3 mt-1 ml-3 max-sm:ml-0 rounded-lg bg-[#1E1F23]'>
                                        <div className=''>
                                            <div className="px-3 mt-3 text-sm text-white max-w-full">
                                                <span className="text-base text-white">Search</span>
                                                <div className="relative">
                                                    <label htmlFor="hs-table-search" className="sr-only">
                                                        Search
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="hs-table-search"
                                                        id="hs-table-search"
                                                        className="block p-2 pl-10 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Search..."
                                                    />
                                                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                                                        <svg
                                                            className="h-3.5 w-3.5 text-gray-400"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-3 mt-3 text-sm text-white">
                                                <h1>Filter By User Type</h1>
                                                <div className="flex flex-col">
                                                    <div className="bg-blue-900 hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg">
                                                        <a className="text-white">Developer</a>
                                                    </div>
                                                    <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg">
                                                        <a className="text-white">Gamers</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-3 mt-3 text-sm text-white">
                                                <h1>Filter By Status User</h1>
                                                <div className="flex flex-col">
                                                    <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg">
                                                        <a className="text-white">Active</a>
                                                    </div>
                                                    <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg">
                                                        <a className="text-white">Need Review</a>
                                                    </div>
                                                    <div className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg">
                                                        <a className="text-white">BLock</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* and filter version mobile */}
                    </div>
                    <div className="flex flex-row">
                        <div className="w-[75%]">
                            <div className="flex flex-col">
                                <div className="overflow-auto">
                                    <div className="p-1.5 w-full inline-block align-middle">
                                        <div className="overflow-auto max-sm:overflow-auto rounded-xl">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Transaction Date</span>
                                                                <button onClick={() => handleSort('tabletransactiontimestamp')}>
                                                                    {sortBy === 'tabletransactiontimestamp' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Transaction Code</span>
                                                                <button onClick={() => handleSort('tabletransactionbctransaction')}>
                                                                    {sortBy === 'tabletransactionbctransaction' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Thread ID</span>
                                                                <button onClick={() => handleSort('threadindex')}>
                                                                    {sortBy === 'threadindex' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Transaction Type</span>
                                                                <button onClick={() => handleSort('tabletransactionpurpose')}>
                                                                    {sortBy === 'tabletransactionpurpose' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Review ID</span>
                                                                <button onClick={() => handleSort('tabletransactionpurposeindex')}>
                                                                    {sortBy === 'tabletransactionpurposeindex' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Developer Username</span>
                                                                <button onClick={() => handleSort('tableusername')}>
                                                                    {sortBy === 'tableusername' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Amount</span>
                                                                <button onClick={() => handleSort('tabletransactiontotalamount')}>
                                                                    {sortBy === 'tabletransactiontotalamount' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Blockchain Status</span>
                                                                <button onClick={() => handleSort('tabletransactionstatus')}>
                                                                    {sortBy === 'tabletransactionstatus' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 text-gray-300">
                                                    {sortedData.map((result, index) => (
                                                        <tr key={index}>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tabletransactiontimestamp}
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                <div className='flex flex-row items-center gap-2'>
                                                                    <a href={'https://testnet.explorer.dwa.network/transaction-detail/'+result.tabletransactionindex} target='_blank' className='underline'>{result.tabletransactionindex}</a>
                                                                    <button onClick={() => Clipboard(result.tabletransactionindex)}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                <div className='flex flex-row items-center gap-2'>
                                                                    {/* {result.tabletransactionpurposeindex.substring(0,4) === 'TRED' ? <a href={`/threads/`+result.tabletransactionpurposeindex} className='underline' >{result.tabletransactionpurposeindex}</a> : ''} */}
                                                                    {/* {result.tabletransactionpurposeindex.substring(0,4) === 'RVEW' ? <a href={`/listreview/`+result.tabletransactionpurposeindex} className='underline' >{result.tabletransactionpurposeindex}</a> : ''} */}
                                                                    {result.threadindex !== null ?
                                                                    <a href={'/threads/'+result.threadindex} target='_blank' className='underline'>{result.threadindex}</a>
                                                                    : '-' }
                                                                    {result.threadindex !== null ?
                                                                    <button onClick={() => Clipboard(result.threadindex)}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </button> : '' }
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tabletransactionpurpose}
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.reviewindex !== null ?
                                                                <div className='flex flex-row items-center gap-2'>
                                                                    <a href={'/listreview/'+result.reviewindex} target='_blank' className='underline'>{result.reviewindex}</a>
                                                                    <button onClick={() => Clipboard(result.reviewindex)}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                : '-' }
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.developerusername !== null ?
                                                                <div className='flex flex-row items-center gap-2'>
                                                                    <a href={'/user/'+result.developeruserindex} target='_blank' className='underline'>{result.developerusername}</a>
                                                                    <button onClick={() => Clipboard(result.developerusername)}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                : '-' }
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tabletransactionamount} ANOA
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tabletransactionstatus === 'CONFIRMED' ?
                                                                <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.tabletransactionstatus}</span>
                                                                : '' }
                                                                {result.tabletransactionstatus === 'COMPLETED' ?
                                                                <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.tabletransactionstatus}</span>
                                                                : '' }
                                                            </td>
                                                            <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                                <a
                                                                    className=" hover:text-gray-900"
                                                                    href={`/transaction/${result.tabletransactionindex}`}
                                                                >
                                                                    <div className='flex justify-center px-1 py-1 bg-[#0028FC] rounded-md'>
                                                                        <FiEye size={25} className="" />
                                                                    </div>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex flex-row items-center justify-between w-full">
                                            <div className="text-white">
                                                Showing <span className="font-bold">{pageNumber} - {endCount}</span> of <span className="font-bold">{totalRow}</span>
                                            </div>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                pageCount={Math.ceil(totalRow / showRecord)}
                                                containerClassName={'flex inline-flex bg-white shadow rounded'}
                                                previousClassName={'px-3 py-2 rounded-l-md border border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800'}
                                                nextClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-r border-gray-200 rounded-r-md'}
                                                pageClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                                                breakClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                                                activeClassName={'bg-[#E1EFFE]'}
                                                onPageChange={paginatehandle}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='max-sm:hidden w-[30%] h-full border border-spacing-3 p-3 mt-1 ml-3 rounded-lg bg-[#1E1F23]'>
                            <div className=''>
                                <div className="px-3 mt-3 text-sm text-white max-w-full">
                                    <span className="text-base text-white">Filter</span>
                                    <div className='flex flex-row gap-2 items-center mt-2'>
                                        <input type="date" ref={startDate} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500'/>
                                        <p>To</p>
                                        <input type="date" ref={endDate} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500'/>
                                    </div>
                                    <select ref={statusInput} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 mt-3'>
                                        <option value="">Choose Status</option>
                                        <option value="Allocation">Allocation</option>
                                        <option value="Refund">Refund</option>
                                        <option value="Distribution">Distribution</option>
                                    </select>
                                    <button className='bg-blue-900 hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg w-full' onClick={filterHandle}>Filter</button>
                                </div>
                            </div>
                        </div> */}

                        <div className="w-[25%] mx-3">
                            <div className="flex flex-col gap-5">
                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Search</h1>
                                    <div className="relative">
                                        <input type="text" className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D]" placeholder="Search" ref={inputSearch} onKeyDown={searchHandle}/>
                                        <div className="absolute top-[50%] right-3 translate-y-[-50%]">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.5 17.5L7.5 12.5M5.83333 8.33333C5.83333 11.555 8.44501 14.1667 11.6667 14.1667C14.8883 14.1667 17.5 11.555 17.5 8.33333C17.5 5.11167 14.8883 2.5 11.6667 2.5C8.44501 2.5 5.83333 5.11167 5.83333 8.33333Z" stroke="#707375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Transaction Date</h1>
                                    <div className="customDatePickerWidth">
                                        <DatePicker
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange={true}
                                            placeholderText="ALL"
                                            isClearable={true}
                                            dateFormat={'dd/MM/yyyy'}
                                            className="bg-[#1E1F23] py-[6px] pl-[16px] outline-none rounded text-[#989B9D] cursor-pointer w-full"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Transaction Type</h1>
                                    <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={typeHandle}>
                                        <option value=''>ALL</option>
                                        {OptionType.map((result, key) => {
                                            let checked = result === type;

                                            var selected = '';
                                            if(checked){
                                                selected = true;
                                            }else{
                                                selected = false;
                                            }
                                            return(
                                                <option value={result} key={key} selected={selected}>{result}</option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Blockchain Status</h1>
                                    <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={statusBlockchainHandle}>
                                        <option value=''>ALL</option>
                                        <option value='CONFIRMED'>CONFIRMED</option>
                                        <option value='COMPLETED'>COMPLETED</option>
                                    </select>
                                </div>

                                <div>
                                    <h1 className="text-white text-[15px] font-semibold">Show Record</h1>
                                    <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={showRecordHandle}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Transaction