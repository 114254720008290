import { useCallback, useEffect, useRef, useState } from "react";
import CryptoJS from 'crypto-js';

import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";

import ReactPaginate from "react-paginate";
import Clipboard from "../../components/Clipboard";
import { FiEye } from "react-icons/fi";

import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import QueryString from "../../components/QueryString";

const Threads = () => {
    const [geser, setGeser] = useState(false);
    const [threads, setThreads] = useState([]);

    const [totalRow, setTotalRow] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [endCount, setEndCount] = useState(0);

    const [showRecord, setShowRecord] = useState(10);
    const [status, setStatus] = useState('');
    
    const inputSearch = useRef();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('');

    const params = QueryString('q');

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    const loadThreads = useCallback( async (paginate, startDate, endDate, status, search, showrecord) => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature
        }

        let body = {};

        const currentTimestamp = new Date().getTime();

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/thread/v01/admin/listthread?length='+showrecord+'&page='+paginate+'&date_from='+startDate+'&date_to='+endDate+'&status='+status+'&search='+search+'&id='+currentTimestamp, body, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            setThreads(response.data.data.listThread);
            setTotalRow(response.data.data.totalThread);
        }

        console.log(response.data.data);
    }, []);

    useEffect(() => {
        if(params !== null){
            inputSearch.current.value = params;
            loadThreads('1', '', '', '', params, showRecord);
        }else{
            loadThreads('1', '', '', '', '', showRecord);
        }
    }, [loadThreads, params]);

    // const cancelHandle = async (id) => {
    //     let uuid =  localStorage.getItem("uuid");
    //     let parseuuid = JSON.parse(uuid);

    //     let userindex = parseuuid.userindex;
    //     let usertoken = parseuuid.usertokenlogin;

    //     let StringSign;
    //     StringSign = '';

    //     let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

    //     let header = {
    //         'platform' : 'WEBSITE',
    //         'gtoken' : 'PASSS',
    //         'userindex' : userindex,
    //         'tokenlogin' : usertoken,
    //         'signature' : signature
    //     }

    //     let body = {
    //         'threadindex' : id
    //     };

    //     let response = await axios.post('https://back.thespace.world/api/thread/v01/admin/cancel', body, {
    //         'headers' : header
    //     });

    //     if(response.data.status.status === 1){
    //         loadThreads();
    //     }
    // }

    const paginatehandle = (e) => {
        let pageSelected = e.selected + 1;
        setPageNumber(pageSelected);
        if(startDate && endDate){
            loadThreads(pageSelected, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), status, inputSearch.current.value, showRecord);
        }else{
            loadThreads(pageSelected, '', '', status, inputSearch.current.value, showRecord);
        }
    }

    useEffect(() => {
        let endCount = ((pageNumber - 1) + parseInt(showRecord));
        setEndCount(endCount);
    }, [pageNumber, showRecord]);

    const statusHandle = (e) => {
        setStatus(e.target.value);

        if(startDate && endDate){
            loadThreads(pageNumber, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), e.target.value, inputSearch.current.value, showRecord);
        }else{
            loadThreads(pageNumber, '', '', e.target.value, inputSearch.current.value, showRecord);
        }
    }

    const searchHandle = (e) => {
        if(e.key === 'Enter'){
            inputSearch.current.value = e.target.value;
            if(startDate && endDate){
                loadThreads(pageNumber, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), status, e.target.value, showRecord);
            }else{
                loadThreads(pageNumber, '', '', status, e.target.value, showRecord);
            }
        }
    }

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        const myArray = dates;
        const allNullValues = myArray.every(value => value === null);

        if(allNullValues !== true){
            loadThreads(pageNumber, format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd'), status, inputSearch.current.value, showRecord);   
        }else{
            loadThreads(pageNumber, '', '', status, inputSearch.current.value, showRecord);   
        }
    }

    const handleSort = (key) => {
        if (sortBy === key) {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
          setSortBy(key);
          setSortOrder('asc');
        }
      };
    
    const sortedData = [...threads].sort((a, b) => {
        const order = sortOrder === 'asc' ? 1 : -1;
        return a[sortBy] > b[sortBy] ? order : -order;
    });

    const showRecordHandle = (e) => {
        setShowRecord(e.target.value);
        if(startDate && endDate){
            loadThreads(pageNumber, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), status, inputSearch.current.value, e.target.value);
        }else{
            loadThreads(pageNumber, '', '', status, inputSearch.current.value, e.target.value);
        }
    }

    return(
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                        <div className="flex flex-row m-3 ml-0">
                            <div className="flex flex-col">
                                <h1 className='text-2xl max-sm:text-xl'>Threads</h1>
                                <h2 className="text-sm max-sm:text-xs">
                                    <Breadcrumbs paths={window.location.pathname.split('/')}/>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-full">
                            <div className="flex flex-row">
                                <div className="overflow-x-auto">
                                    <div className="p-1.5 w-full inline-block align-middle">
                                        <div className="overflow-auto max-sm:overflow-auto rounded-xl">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-300">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Created Date</span>
                                                                <button onClick={() => handleSort('created_date')}>
                                                                    {sortBy === 'created_date' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Threads ID</span>
                                                                <button onClick={() => handleSort('tablethreadindex')}>
                                                                    {sortBy === 'tablethreadindex' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Threads Title</span>
                                                                <button onClick={() => handleSort('tablethreadtitle')}>
                                                                    {sortBy === 'tablethreadtitle' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Developer Username</span>
                                                                <button onClick={() => handleSort('developerusername')}>
                                                                    {sortBy === 'developerusername' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Start Date</span>
                                                                <button onClick={() => handleSort('tablethreaddatestart')}>
                                                                    {sortBy === 'tablethreaddatestart' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>End Date</span>
                                                                <button onClick={() => handleSort('tablethreaddateend')}>
                                                                    {sortBy === 'tablethreaddateend' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Allocation Balance</span>
                                                                <button onClick={() => handleSort('tablethreadcoinallocation')}>
                                                                    {sortBy === 'tablethreadcoinallocation' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Approved Review</span>
                                                                <button onClick={() => handleSort('approved_review')}>
                                                                    {sortBy === 'approved_review' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Status</span>
                                                                <button onClick={() => handleSort('tablethreadstatus')}>
                                                                    {sortBy === 'tablethreadstatus' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            <div className="flex flex-row items-center gap-2">
                                                                <span>Blockchain Status</span>
                                                                <button onClick={() => handleSort('tablethreadstatusblockchain')}>
                                                                    {sortBy === 'tablethreadstatusblockchain' && sortOrder === 'asc' ? 
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.5 3.33301H13.3333M2.5 6.66634H10M2.5 9.99967H7.5M10.8333 9.99967L14.1667 6.66634M14.1667 6.66634L17.5 9.99967M14.1667 6.66634V16.6663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    : 
                                                                        
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="#00000" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 3.33301H13.8333M3 6.66634H10.5M3 9.99967H10.5M14.6667 6.66634V16.6663M14.6667 16.6663L11.3333 13.333M14.6667 16.6663L18 13.333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>  
                                                                    }
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-xs font-bold text-left text-gray-600 uppercase whitespace-nowrap"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-300 text-gray-300">
                                                    {sortedData.map((result, key) => (
                                                        <tr key={key}>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.created_date}
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                <div className="flex flex-row items-center gap-2">
                                                                    <a href={`/threads/`+result.tablethreadindex} target="_blank" className="underline">{result.tablethreadindex}</a>
                                                                    <button onClick={() => Clipboard(result.tablethreadindex)}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tablethreadtitle}
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                <div className="flex flex-row items-start gap-2">
                                                                    <a href={`/user/`+result.developeruserindex} target="_blank" className="underline">{result.developerusername}</a>
                                                                    <button onClick={() => Clipboard(result.developerusername)}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.66668 13.334H5.00001C4.07954 13.334 3.33334 12.5878 3.33334 11.6673V5.00065C3.33334 4.08018 4.07954 3.33398 5.00001 3.33398H11.6667C12.5872 3.33398 13.3333 4.08018 13.3333 5.00065V6.66732M8.33334 16.6673H15C15.9205 16.6673 16.6667 15.9211 16.6667 15.0007V8.33398C16.6667 7.41351 15.9205 6.66732 15 6.66732H8.33334C7.41287 6.66732 6.66668 7.41351 6.66668 8.33398V15.0007C6.66668 15.9211 7.41287 16.6673 8.33334 16.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tablethreaddatestart}
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tablethreaddatestart}
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tablethreadcoinallocation} ANOA
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.approved_review}
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tablethreadstatus === 'ACTIVE' ? 
                                                                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.tablethreadstatus}</span>
                                                                : ''}
                                                                {result.tablethreadstatus === 'ENDED' ? 
                                                                    <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{result.tablethreadstatus}</span>
                                                                : ''}
                                                                {result.tablethreadstatus === 'CANCELED' ? 
                                                                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{result.tablethreadstatus}</span>
                                                                : ''}
                                                                {result.tablethreadstatus === 'PENDING' ? 
                                                                    <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.tablethreadstatus}</span>
                                                                : ''}
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                {result.tablethreadstatusblockchain === 'CONFIRMED' ?
                                                                <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{result.tablethreadstatusblockchain}</span>
                                                                : '' }
                                                                {result.tablethreadstatusblockchain === 'COMPLETED' ?
                                                                <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{result.tablethreadstatusblockchain}</span>
                                                                : '' }
                                                            </td>
                                                            <td className="px-6 py-4 text-sm whitespace-nowrap">
                                                                <a
                                                                    className=" hover:text-gray-900"
                                                                    href={`/threads/`+result.tablethreadindex}
                                                                >
                                                                    <div className='flex justify-center px-1 py-1 bg-[#0028FC] rounded-md'>
                                                                        <FiEye size={25} className="" />
                                                                    </div>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex flex-row items-center justify-between w-full">
                                            <div className="text-white">
                                                Showing <span className="font-bold">{pageNumber} - {endCount}</span> of <span className="font-bold">{totalRow}</span>
                                            </div>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                pageCount={Math.ceil(totalRow / showRecord)}
                                                containerClassName={'flex inline-flex bg-white shadow rounded'}
                                                previousClassName={'px-3 py-2 rounded-l-md border border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800'}
                                                nextClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-r border-gray-200 rounded-r-md'}
                                                pageClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                                                breakClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                                                activeClassName={'bg-[#E1EFFE]'}
                                                onPageChange={(e) => paginatehandle(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* filter version desktop */}
                                {/* <div className='w-[25%] max-sm:hidden max-sm:w-full h-full border border-spacing-3 p-3 mt-1 ml-3 max-sm:ml-0 rounded-lg bg-[#1E1F23]'>
                                    <div className=''>
                                        <div className="px-3 mt-3 text-sm text-white max-w-full">
                                            <span className="text-base text-white">Filter</span>
                                            <div className='flex flex-row gap-2 items-center mt-2'>
                                                <input type="date" ref={startDate} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500'/>
                                                <p>To</p>
                                                <input type="date" ref={endDate} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500'/>
                                            </div>
                                            <select ref={statusInput} className='block p-2 pl-2 w-full text-sm text-gray-600 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 mt-3'>
                                                <option value="">Choose Status</option>
                                                <option value="ACTIVE">ACTIVE</option>
                                                <option value="PENDING">PENDING</option>
                                                <option value="CANCELED">CANCELED</option>
                                                <option value="ENDED">ENDED</option>
                                            </select>
                                            <button className='bg-blue-900 hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg w-full' onClick={filterHandle}>Filter</button>
                                        </div>
                                    </div>
                                </div> */}
                                {/* and filter version desktop */}
                                <div className="w-[25%] mx-3">
                                    <div className="flex flex-col gap-5">
                                        <div>
                                            <h1 className="text-white text-[15px] font-semibold">Search</h1>
                                            <div className="relative">
                                                <input type="text" className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D]" ref={inputSearch} placeholder="Search" onKeyDown={searchHandle}/>
                                                <div className="absolute top-[50%] right-3 translate-y-[-50%]">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.5 17.5L7.5 12.5M5.83333 8.33333C5.83333 11.555 8.44501 14.1667 11.6667 14.1667C14.8883 14.1667 17.5 11.555 17.5 8.33333C17.5 5.11167 14.8883 2.5 11.6667 2.5C8.44501 2.5 5.83333 5.11167 5.83333 8.33333Z" stroke="#707375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h1 className="text-white text-[15px] font-semibold">Status</h1>
                                            <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onClick={statusHandle}>
                                                <option value=''>ALL</option>
                                                <option value='ACTIVE'>ACTIVE</option>
                                                <option value='PENDING'>PENDING</option>
                                                <option value='ENDED'>ENDED</option>
                                                <option value='CANCELED'>CANCELED</option>
                                            </select>
                                        </div>

                                        <div>
                                            <h1 className="text-white text-[15px] font-semibold">Created Date</h1>
                                            <div className="customDatePickerWidth text-white">
                                                <DatePicker
                                                    onChange={handleDateChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange={true}
                                                    placeholderText="ALL"
                                                    isClearable={true}
                                                    dateFormat={'dd/MM/yyyy'}
                                                    className="bg-[#1E1F23] py-[6px] pl-[16px] outline-none rounded text-[#989B9D] cursor-pointer w-full"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <h1 className="text-white text-[15px] font-semibold">Show Record</h1>
                                            <select className="w-full bg-[#1E1F23] py-[6px] pl-[16px] pr-[40px] outline-none rounded text-[#989B9D] cursor-pointer" onChange={showRecordHandle}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Threads;