import React from 'react';

const Breadcrumbs = ({ paths }) => {
  return (
    <div>
      {paths.map((path, index) => (
        <span key={index}>
          {path}
          {index !== paths.length - 1 && <span> / </span>}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumbs;
