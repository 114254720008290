import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('uuid');
        navigate('/');
    }, []);
    return(
        <></>
    );
}

export default Logout;