import React, { useState } from 'react'
import Sidebar from '../../components/Sidebar'
import { FiArrowLeft } from "react-icons/fi";
import Icon_file from "../../assets/language/Icon_file.png";

function Language() {

    const [geser, setGeser] = useState(false);

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");


  return (
    <>
        <div className="bg-black min-h-full">
            <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
            </div>
            <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                    <div className="flex flex-row m-3 ml-0">
                        <FiArrowLeft size={25} className="mt-1 mr-1" />
                        <div className="flex  flex-col">
                            <h1 className='text-2xl max-sm:text-xl'>Language</h1>
                            <h2 className="text-sm max-sm:text-xs">../Language/</h2>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-3 max-sm:mt-0 w-full p-1 text-white text-sm">
                    <div className='flex flex-row w-full my-1'>
                        <div className='flex flex-row bg-[#1E1F23] p-1 w-full mr-3 rounded-lg'>
                            <img className='w-[20px]' src={Icon_file}/>
                            <span className='ml-2'>Banner 3</span>
                        </div>
                        <button className='w-[25%] bg-[#4C4DDC] text-sm text-white text-center rounded-lg py-1 mr-3'>
                            <a className='w-full' href='/language/edit_language'>Edit</a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Language