import React, { useCallback, useEffect, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate, useParams } from 'react-router-dom';

import Sidebar from '../../components/Sidebar';

import Swal from 'sweetalert2'

import { FiArrowLeft } from "react-icons/fi";
import Breadcrumbs from '../../components/Breadcrumbs';
import Clipboard from '../../components/Clipboard';
// import Icon_preview from "../../../src/assets/user/Icon_preview.png";
// import Icon_upload from "../../../src/assets/user/Icon_upload.png";
// import Icon_delete from "../../../src/assets/user/Icon_delete.png";

function User() {
  const { id } = useParams();
  const [userDetail, setUserDetail] = useState({});
  const [geser, setGeser] = useState(false);

  const navigate = useNavigate();

  const [toggleHistoryKyc, setToggleHistoryKyc] = useState(false);
  const [listLogKyc, setListLogKyc] = useState([]);
  const [activeLogKyc, setActiveLogKyc] = useState({});
  const [dataKyc, setDataKyc] = useState([]);
  const [kyc, setKyc] = useState({});

  const handleToggleSidebar = () => {
    if(localStorage.getItem('gesertoggle') !== null){
      localStorage.removeItem("gesertoggle");
      setGeser(true);
    }else{
      if(geser === false){
        setGeser(true);
        localStorage.setItem("gesertoggle", "true");
      }else{
        setGeser(false);
        localStorage.removeItem("gesertoggle");
      }
    }
  }

  const gesertoggle = localStorage.getItem("gesertoggle");
  
  const userHandle = useCallback( async () => {
    let uuid =  localStorage.getItem("uuid");
      let parseuuid = JSON.parse(uuid);

      let userindex = parseuuid.userindex;
      let usertoken = parseuuid.usertokenlogin;

      let StringSign;
      StringSign = id;
      // StringSign = 'PASSS';
      // StringSign = userindex;
      // StringSign = usertoken;

      let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

      let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature

      }

      let body = {
        'detailuserindex' : id
      }

      const currentTimestamp = new Date().getTime();

      let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/detailuser?id='+currentTimestamp, body, {
          'headers' : header
      });

      console.log(response.data);

      if(response.data.status.status === 1){
        setUserDetail(response.data.data[0])
      }
  }, [id])

  const handleBlockUser = useCallback(async () => {
    Swal.fire({
      title: 'Enter your reason?',
      input: 'textarea',
      inputAttributes: {
          autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Kirim',
      showLoaderOnConfirm: true,
      preConfirm: async (params) => {
        let uuid = localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';

        let signature =  CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature
        }

        let body = {
          'detailuserindex' : id,
          'blockreason' : params
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/blockuser', body, {
          'headers' : header
        });

        if(response.data.status.status === 1){
          Swal.fire(
            'Success!',
            'User blokir successfuly',
            'success'
          );

          userHandle();
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if(result.isConfirmed){
            Swal.fire(
                'Canceled!',
                'Your threads successfuly cancel',
                'success'
            )
        }
    })
  }, [id])

  const handleUnBlockUser = useCallback(async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to take this action?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Unblock user!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        let uuid = localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';

        let signature =  CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature
        }

        let body = {
          'detailuserindex' : id,
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/unblockuser', body, {
          'headers' : header
        });

        if(response.data.status.status === 1){
          Swal.fire(
            'Success!',
            'User unblokir successfuly',
            'success'
          );

          userHandle();
        }
      }
    })
  }, [id]);

  const handleCloseAccount = async () => {
    Swal.fire({
      title: 'Enter your reason?',
      input: 'textarea',
      inputAttributes: {
          autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Kirim',
      showLoaderOnConfirm: true,
      preConfirm: async (params) => {
        let uuid = localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';
        StringSign += id;

        let body = {
          'detailuserindex' : id,
          'closeaccountreason' : params
        }

        let signature = CryptoJS.HmacSHA256(StringSign, 'breakltespedriguc').toString();

        let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/closeaccountuser', body, {
          'headers' : header
        });

        if(response.data.status.status === 1){
          Swal.fire(
            'Success!',
            'Close Account successfuly',
            'success'
          );
          userHandle();
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if(result.isConfirmed){
            Swal.fire(
                'Canceled!',
                'Your threads successfuly cancel',
                'success'
            )
        }
    })
  }

  const handleApproveKyc = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to take this action?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve KYC!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        let uuid = localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign ='';
        StringSign += id;

        let body = {
          'detailuserindex' : id,
        }

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/approvekyc', body, {
          'headers' : header
        });

        if(response.data.status.status === 1){
          Swal.fire(
            'Success!',
            'Approve KYC successfuly',
            'success'
          );
          userHandle();
        }else{
          Swal.fire(
            'Error!',
            response.data.status.message[0].errormessage,
            'error'
          );
        }
      }
    })
  }

  const handleRejectKyc = async () => {
    Swal.fire({
      title: 'Enter your reason?',
      input: 'textarea',
      inputAttributes: {
          autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Kirim',
      showLoaderOnConfirm: true,
      preConfirm: async (params) => {
        let uuid = localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign ='';
        StringSign += id;

        let body = {
          'detailuserindex' : id,
          'kycrevision': params
        }

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/rejectkyc', body, {
          'headers' : header
        });

        if(response.data.status.status === 1){
          Swal.fire(
            'Success!',
            'Reject KYC successfuly',
            'success'
          );
          userHandle();
        }else{
          Swal.fire(
            'Error!',
            response.data.status.message[0].errormessage,
            'error'
          );
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if(result.isConfirmed){
            Swal.fire(
              'Success!',
              'Reject KYC successfuly',
              'success'
            )
        }
    })
  }

  useEffect(() => {
    userHandle();
  }, [userHandle])

  const [additional, setAdditional] = useState(true);

  const handleSuspend = async () => {
    Swal.fire({
      title: 'Enter your reason?',
      input: 'textarea',
      inputAttributes: {
          autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Kirim',
      showLoaderOnConfirm: true,
      preConfirm: async (params) => {
        let uuid = localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign ='';
        StringSign += id;

        let body = {
          'detailuserindex' : id,
          'suspendreason': params
        }

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/suspenduser', body, {
          'headers' : header
        });

        if(response.data.status.status === 1){
          Swal.fire(
            'Success!',
            'Suspend successfuly',
            'success'
          );
          userHandle();
        }else{
          Swal.fire(
            'Error!',
            response.data.status.message[0].errormessage,
            'error'
          );
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if(result.isConfirmed){
            Swal.fire(
                'Canceled!',
                'Your threads successfuly cancel',
                'success'
            )
        }
    })
  }

  const handleUnSuspend = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to take this action?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Unsuspend Account'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let uuid = localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign ='';
        StringSign += id;

        let body = {
          'detailuserindex' : id,
        }

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
          'platform' : 'WEBSITE',
          'gtoken' : 'PASSS',
          'userindex' : userindex,
          'tokenlogin' : usertoken,
          'signature' : signature
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/unsuspenduser', body, {
          'headers' : header
        });

        if(response.data.status.status === 1){
          Swal.fire(
            'Success!',
            'Unsuspend successfuly',
            'success'
          );
          userHandle();
        }else{
          Swal.fire(
            'Error!',
            response.data.status.message[0].errormessage,
            'error'
          );
        }
      }
    })
  }

  const LogKyc = useCallback(async () => {
    let uuid =  localStorage.getItem("uuid");
    let parseuuid = JSON.parse(uuid);

    let userindex = parseuuid.userindex;
    let usertoken = parseuuid.usertokenlogin;

    let StringSign;
    StringSign = '';
    StringSign += id;

    let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

    let header = {
        'platform' : 'WEBSITE',
        'gtoken' : 'PASSS',
        'userindex' : userindex,
        'tokenlogin' : usertoken,
        'signature' : signature
    }

    const currentTimestamp = new Date().getTime();

    let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/kyclog?userindexkyc='+id+'&id='+currentTimestamp, {
        'headers' : header
    });

    if(response.data.status.status === 1){
      if(response.data.data.length > 0){
        const FilterLog = response.data.data.filter((_, index) => index !== 0);
        const ActiveFilterLog = response.data.data.filter((_, index) => index === 0)[0];
      
        setDataKyc(response.data.data);
        setActiveLogKyc(ActiveFilterLog);
        setKyc(ActiveFilterLog);
        setListLogKyc(FilterLog);
      }
    }
  }, []);

  useEffect(() => {
    LogKyc();
  }, [LogKyc]);

  const changeFileKyc = (id) => {
    const ActiveFilterLog = dataKyc.filter(item => item.tableuserkyclogindex === id)[0];
    setActiveLogKyc(ActiveFilterLog);
  }

  const changeFileKycActive = () => {
    const ActiveFilterLog = dataKyc.filter((_, index) => index === 0)[0];
    setActiveLogKyc(ActiveFilterLog);
  }

  return (
    <>
      <div className="bg-black min-h-full">
        <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
            <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
        </div>
        <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[10px] max-sm:pr-[10px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
          <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10 items-center">
              <div className="flex flex-row m-3 ml-0">
                  <a href='/user'>
                    <FiArrowLeft size={25} className="mt-1 mr-1 hover:cursor-pointer" />
                  </a>
                  <div className="flex  flex-col">
                      <h1 className='text-2xl max-sm:text-xl'>Detail User</h1>
                      <h2 className="text-sm max-sm:text-xs">
                        <Breadcrumbs paths={window.location.pathname.split('/')}/>
                      </h2>
                  </div>
              </div>
              <div className='bg-white'>
                <div className='flex flex-row gap-2'>
                  <div className='px-4 text-white block py-[6px] bg-[#4C4DDC] cursor-pointer' onClick={() => navigate('/user/'+id)}>
                    User Detail
                  </div>
                  <div className='px-4 block text-black py-[6px] cursor-pointer' onClick={() => navigate('/user/log/'+id)}>
                    Activity Log
                  </div>
                </div>
              </div>
          </div>

          {/* Start Content */}
          {/* <div className="flex flex-row max-sm:flex-col">
              <div className="flex flex-col w-full h-full bg-black border rounded-lg p-3 mt-1">
                
                <div className='w-full'>
                  <h1 className="text-white">Data User</h1>
                  <div className="flex flex-col h-full w-full py-5">
                    <div className="flex flex-row pb-3 w-full">
                      <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                        <span className="text-white text-sm max-sm:text-xs">Full Name</span>
                      </div>
                      <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                        <input className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.fullname}></input>
                      </div>
                    </div>
                    <div className="flex flex-row pb-3 w-full">
                      <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                        <span className="text-white text-sm max-sm:text-xs">Email</span>
                      </div>
                      <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2" >
                        <input className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.email}></input>
                      </div>
                    </div>
                    <div className="flex flex-row pb-3 w-full">
                      <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                        <span className="text-white text-sm max-sm:text-xs">Phone Number</span>
                      </div>
                      <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                        <input className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.phonenumber}></input>
                      </div>
                    </div>
                    <div className="flex flex-row pb-3 w-full">
                      <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                        <span className="text-white text-sm max-sm:text-xs">User Type</span>
                      </div>
                      <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                        <input className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.tipeuser}></input>
                      </div>
                    </div>
                    <div className="flex flex-row pb-3 w-full">
                      <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                        <span className="text-white text-sm max-sm:text-xs">Status</span>
                      </div>
                      <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                        <input className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.status}></input>
                      </div>
                    </div>
                    <div className="flex flex-row pb-3 w-full">
                      <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                        <span className="text-white text-sm max-sm:text-xs">Join Date</span>
                      </div>
                      <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                        <input className="text-sm max-sm:text-xs  w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.joindate}></input>
                      </div>
                    </div>
                    <div className="flex flex-row pb-3 w-full">
                      <div className="flex content-start w-[25%] max-sm:w-[40%] bg-[#1E1F23] rounded-md p-2 max-sm:px-1 mr-3 max-sm:mr-2">
                        <span className="text-white text-sm max-sm:text-xs">Last Login</span>
                      </div>
                      <div className="flex content-start w-full text-white bg-[#1E1F23] rounded-md p-2">
                        <input className="text-sm max-sm:text-xs w-full rounded-md bg-[#1E1F23]" defaultValue={userDetail.lastlogin}></input>
                      </div>
                    </div>
                  </div>
                </div>
                
                
                <div className={`${additional && userDetail.tipeuser === 'developer' ? '' : 'gamer'}`}>
                  {console.log(userDetail)}
                  <h1 className="text-white">Additional User Data</h1>
                  <div className="flex flex-col">
                    <div className="bg-[#1E1F23] pb-5 p-5 flex flex-col justify-center">
                      <div>
                        <span className="text-white text-lg max-sm:text-sm">KTP</span>
                      </div>
                      <div className="bg-gray-300 mx-20 max-sm:mx-1 my-5 max-sm:h-[160px]">
                        {userDetail.idcardpict !== '' ?
                        <img src={'https://back.thespace.world/public/'+userDetail.idcardpict} className="w-full"/>
                        : <span>No Document</span> }
                      </div>
                      
                    </div>
                    
                    {additional && userDetail.tipeuser === 'gamer' ? 
                    <div className="bg-[#1E1F23] pb-5 p-5 flex flex-col justify-center">
                      <div>
                        <span className="text-white text-lg max-sm:text-sm">KTP + SELFIE</span>
                      </div>
                      <div className="bg-gray-300 mx-20 max-sm:mx-1 my-5 max-sm:h-[160px]">
                        {userDetail.idcardpict !== '' ?
                        <img src={'https://back.thespace.world/public/'+userDetail.selfiepict} className="w-full"/>
                        : <span>No Document</span> }
                      </div>
                      
                    </div> : '' }
                    {additional && userDetail.tipeuser === 'developer' ? 
                    <>
                      <div className="bg-[#1E1F23] pb-5 p-5 mt-3 flex flex-col justify-center">
                        <div>
                          <span className="text-white text-lg max-sm:text-sm">NPWP</span>
                        </div>
                        <div className="bg-gray-300 mx-20 max-sm:mx-1 my-5 max-sm:h-[160px]">
                          {userDetail.npwppict !== '' ?
                          <img src={'https://back.thespace.world/public/'+userDetail.npwppict} className="w-full"/>
                          : <span>No Document</span> }
                        </div>
                        
                      </div>
                      <div className="bg-[#1E1F23] pb-5 p-5 mt-3 flex flex-col justify-center">
                        <div>
                          <span className="text-white text-lg max-sm:text-sm">AKTA PP</span>
                        </div>
                        <div className="bg-gray-300 mx-20 max-sm:mx-1 my-5 max-sm:h-[160px]">
                          {userDetail.companydocumentpict !== '' ?
                          <img src={'https://back.thespace.world/public/'+userDetail.companydocumentpict} className="w-full"/>
                          : <span>No Document</span> }
                        </div>
                        
                      </div>
                    </>
                    : '' }
                  </div>
                </div>
              </div>

              <div className="w-[35%] max-sm:w-full h-full border border-spacing-3 p-3 mt-1 max-sm:mt-3 mx-3 max-sm:mx-0  rounded-lg bg-[#1E1F23]">
                <div>
                  <div className="px-3 mt-3 text-sm text-white">
                    <h1>Action</h1>
                    <div className="flex flex-col">
                      <button className="bg-blue-900 hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg" onClick={handleBlockUser}>Block User</button>
                      <button className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg" onClick={handleCloseAccount}>Close Account</button>
                      <button className="bg-blue-900 hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg" onClick={handleApproveKyc}>Approve KYC</button>
                      <button className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg" onClick={handleRejectKyc}>Reject KYC</button>
                      {userDetail.status !== 'SUSPENDED' ?
                      <button className="bg-blue-900  hover:bg-blue-500 cursor-pointer text-center p-1 mt-2 rounded-lg" onClick={handleSuspend}>Suspend</button>
                      : 
                      <button className="bg-green-900  hover:bg-green-500 cursor-pointer text-center p-1 mt-2 rounded-lg" onClick={handleUnSuspend}>Unsuspend</button>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
          </div> */}
          {/* End Content */}

          <div className="w-full h-full mt-5">
            <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">User Data</h2>
            <div className="grid grid-cols-3 gap-3 mb-[32px]">
              <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                  <p className="text-[#E4E8EC] pb-[10px]">Wallet Balance</p>
                  <h1 className="text-[#4391E7] text-[36px] font-semibold">{userDetail.wallet_detail ? userDetail.wallet_detail.quantityQNT : ''} ANOA</h1>
                  <p className='text-[#E4E8EC]'>Wallet Address</p>
                  <div className='flex flex-row items-center gap-2'>  
                    <span className='text-[#4391E7]'>{userDetail.wallet_detail ? userDetail.wallet_detail.wallet_address !== '' ? userDetail.wallet_detail.wallet_address : '-' : ''}</span>
                    {userDetail.wallet_detail ? userDetail.wallet_detail.wallet_address !== '' ?  
                    <button onClick={() => Clipboard(userDetail.wallet_detail.wallet_address)} className='cursor-pointer'>
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.83329 10.666H4.49996C3.76358 10.666 3.16663 10.0691 3.16663 9.33268V3.99935C3.16663 3.26297 3.76358 2.66602 4.49996 2.66602H9.83329C10.5697 2.66602 11.1666 3.26297 11.1666 3.99935V5.33268M7.16663 13.3327H12.5C13.2363 13.3327 13.8333 12.7357 13.8333 11.9993V6.66602C13.8333 5.92964 13.2363 5.33268 12.5 5.33268H7.16663C6.43025 5.33268 5.83329 5.92964 5.83329 6.66602V11.9993C5.83329 12.7357 6.43025 13.3327 7.16663 13.3327Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                    : '-' : ''}
                  </div>                  
              </div>

              {userDetail.tipeuser === 'gamer' ? 
              <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                <p className="text-[#E4E8EC] pb-[10px]">Total Review</p>
                <h1 className="text-[#4391E7] text-[36px] font-semibold">{userDetail.total_review ? userDetail.total_review.total : 0}</h1>
                <a href={'/listreview?q='+userDetail.username} target='_blank' className="bg-[#2970FF] text-white font-semibold py-[5px] w-full block text-center rounded-lg mt-[10px]">Show Data</a>
              </div> : '' }

              {userDetail.tipeuser === 'developer' ? 
              <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                <p className="text-[#E4E8EC] pb-[10px]">Total Threads</p>
                <h1 className="text-[#4391E7] text-[36px] font-semibold">{userDetail.total_threads ? userDetail.total_threads : 0}</h1>
                <a href={'/threads?q='+userDetail.username} target='_blank' className="bg-[#2970FF] text-white font-semibold py-[5px] w-full block text-center rounded-lg mt-[10px]">Show Data</a>
              </div> : '' }

              {userDetail.tipeuser === 'gamer' ? 
              <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                <p className="text-[#E4E8EC] pb-[10px]">Total Earnings</p>
                <h1 className="text-[#4391E7] text-[36px] font-semibold">{userDetail.total_earning.amount ? userDetail.total_earning.amount : 0} ANOA</h1>
                <a href={'/transaction?q='+userDetail.username+'&f=Disbursement'} target='_blank' className="bg-[#2970FF] text-white font-semibold py-[5px] w-full block text-center rounded-lg mt-[10px]">Show Data</a>
              </div> : '' }

              {userDetail.tipeuser === 'developer' ? 
              <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                <p className="text-[#E4E8EC] pb-[10px]">Total Reviews</p>
                <h1 className="text-[#4391E7] text-[36px] font-semibold">{userDetail.total_reviews ? userDetail.total_reviews : 0}</h1>
                <a href={'/listreview?q='+userDetail.username} target='_blank' className="bg-[#2970FF] text-white font-semibold py-[5px] w-full block text-center rounded-lg mt-[10px]">Show Data</a>
              </div> : '' }
            </div>

            {console.log(userDetail)}

            <div className="w-full h-full mb-[32px]">
              <div className="flex flex-row gap-5">
                <div className="w-[130%]">
                  <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Data User</h2>
                  <div className='bg-[#1E1F23] w-full p-[12px] mb-3'>
                    <div className='flex flex-row gap-5 w-full'>
                      {userDetail.profilepicturefullpath !== null ?
                      <img src={userDetail.profilepicturefullpath} alt="default" className='w-[120px] rounded-lg'/>
                      :
                      <img src={process.env.PUBLIC_URL+'/asset/default.png'} alt="default" className='w-[120px] rounded-lg'/>
                      }
                      <div className='w-full flex flex-col justify-between'>
                        <p className='text-[#BABEC1]'>Username</p>
                        <div className='flex flex-row items-center gap-2'>
                          <span className='text-white'>{userDetail.username}</span>
                          <button onClick={() => Clipboard(userDetail.username)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.33329 10.666H3.99996C3.26358 10.666 2.66663 10.0691 2.66663 9.33268V3.99935C2.66663 3.26297 3.26358 2.66602 3.99996 2.66602H9.33329C10.0697 2.66602 10.6666 3.26297 10.6666 3.99935V5.33268M6.66663 13.3327H12C12.7363 13.3327 13.3333 12.7357 13.3333 11.9993V6.66602C13.3333 5.92964 12.7363 5.33268 12 5.33268H6.66663C5.93025 5.33268 5.33329 5.92964 5.33329 6.66602V11.9993C5.33329 12.7357 5.93025 13.3327 6.66663 13.3327Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                        </div>

                        <div className='flex flex-row items-center justify-between'>
                          <p className='text-[#BABEC1]'>User Type</p>
                            {userDetail.status === 'NOT ACTIVE' ?
                            <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">{userDetail.status}</span>
                            : ''}

                            {userDetail.status === 'ACTIVE' ?
                            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">{userDetail.status}</span>
                            : ''}

                            {userDetail.status === 'NEED REVIEW' ?
                            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">{userDetail.status}</span>
                            : ''}

                            {userDetail.status === 'REJECTED' ?
                            <span class="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-pink-400 border border-pink-400">{userDetail.status}</span>
                            : ''}  

                            {userDetail.status === 'FREEZE' ?
                            <span class="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">{userDetail.status}</span>
                            : ''}  

                            {userDetail.status === 'BLOCKED' ?
                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">{userDetail.status}</span>
                            : ''} 

                            {userDetail.status === 'SUSPENDED' ?
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{userDetail.status}</span>
                            : ''}    

                            {userDetail.status === 'DELETED' ?
                            <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">{userDetail.status}</span>
                            : ''}                           
                        </div>
                        <span className='text-white'>{userDetail.tipeuser}</span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-3 mb-[32px]">
                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4">Full Name</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <p className="text-white px-4">{userDetail.fullname}</p>
                        </div>
                    </div>

                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4">Email</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <p className="text-white px-4">{userDetail.email}</p>
                        </div>
                    </div>

                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4">Phone Number</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <p className="text-white px-4">{userDetail.phonenumber}</p>
                        </div>
                    </div>
                    
                    {userDetail.address !== null ?
                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4">Address</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <p className="text-white px-4">{userDetail.address}</p>
                        </div>
                    </div> : '' }

                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4 whitespace-nowrap">Reason for Suspension</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <p className="text-white px-4">{userDetail.tableusersuspendreason !== null ? userDetail.tableusersuspendreason : '-'}</p>
                        </div>
                    </div>

                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4 whitespace-nowrap">Reason for Blocking</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <p className="text-white px-4">{userDetail.tableuserblockreason !== null ? userDetail.tableuserblockreason : '-'}</p>
                        </div>
                    </div>

                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4 whitespace-nowrap">Reason for Deletion</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <p className="text-white px-4">{userDetail.tableuserdeletereason !== null ? userDetail.tableuserdeletereason : '-'}</p>
                        </div>
                    </div>

                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4">Join Date</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <div className="flex flex-row gap-1 h-full items-center px-4">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99163 1.66602C5.39163 1.66602 1.66663 5.39935 1.66663 9.99935C1.66663 14.5993 5.39163 18.3327 9.99163 18.3327C14.6 18.3327 18.3333 14.5993 18.3333 9.99935C18.3333 5.39935 14.6 1.66602 9.99163 1.66602ZM9.99996 16.666C6.31663 16.666 3.33329 13.6827 3.33329 9.99935C3.33329 6.31602 6.31663 3.33268 9.99996 3.33268C13.6833 3.33268 16.6666 6.31602 16.6666 9.99935C16.6666 13.6827 13.6833 16.666 9.99996 16.666ZM9.81663 5.83268H9.76663C9.43329 5.83268 9.16663 6.09935 9.16663 6.43268V10.366C9.16663 10.6577 9.31663 10.9327 9.57496 11.0827L13.0333 13.1577C13.3166 13.3243 13.6833 13.241 13.85 12.9577C13.8918 12.8902 13.9196 12.815 13.9318 12.7365C13.944 12.6581 13.9402 12.578 13.9208 12.501C13.9014 12.424 13.8666 12.3517 13.8187 12.2885C13.7707 12.2252 13.7105 12.1722 13.6416 12.1327L10.4166 10.216V6.43268C10.4166 6.09935 10.15 5.83268 9.81663 5.83268Z" fill="white"/>
                                </svg>
                                <span className="text-white">{userDetail.joindate}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row gap-3">
                        <div className="bg-[#1E1F23] py-[12px] w-[20%]">
                            <p className="text-[#BABEC1] px-4">Last Login</p>
                        </div>
                        <div className="bg-[#1E1F23] py-[12px] w-full">
                            <div className="flex flex-row gap-1 h-full items-center px-4">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99163 1.66602C5.39163 1.66602 1.66663 5.39935 1.66663 9.99935C1.66663 14.5993 5.39163 18.3327 9.99163 18.3327C14.6 18.3327 18.3333 14.5993 18.3333 9.99935C18.3333 5.39935 14.6 1.66602 9.99163 1.66602ZM9.99996 16.666C6.31663 16.666 3.33329 13.6827 3.33329 9.99935C3.33329 6.31602 6.31663 3.33268 9.99996 3.33268C13.6833 3.33268 16.6666 6.31602 16.6666 9.99935C16.6666 13.6827 13.6833 16.666 9.99996 16.666ZM9.81663 5.83268H9.76663C9.43329 5.83268 9.16663 6.09935 9.16663 6.43268V10.366C9.16663 10.6577 9.31663 10.9327 9.57496 11.0827L13.0333 13.1577C13.3166 13.3243 13.6833 13.241 13.85 12.9577C13.8918 12.8902 13.9196 12.815 13.9318 12.7365C13.944 12.6581 13.9402 12.578 13.9208 12.501C13.9014 12.424 13.8666 12.3517 13.8187 12.2885C13.7707 12.2252 13.7105 12.1722 13.6416 12.1327L10.4166 10.216V6.43268C10.4166 6.09935 10.15 5.83268 9.81663 5.83268Z" fill="white"/>
                                </svg>
                                <span className="text-white">{userDetail.lastlogin}</span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="w-[35%]">
                  <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Action</h2>
                  <div className='flex flex-col gap-2'>
                    {userDetail.status === 'BLOCKED' || userDetail.status === 'DELETED' ?
                    <button className="bg-[#3498db] text-white font-semibold py-[5px] w-full rounded-lg cursor-default">
                        Suspend User
                    </button>
                    :userDetail.status === 'SUSPENDED' ?
                    <button className="bg-[#2970FF] text-white font-semibold py-[5px] w-full rounded-lg" onClick={handleUnSuspend}>
                        Unsuspend User
                    </button>
                    :
                    <button className="bg-[#2970FF] text-white font-semibold py-[5px] w-full rounded-lg" onClick={handleSuspend}>
                        Suspend User
                    </button>
                    }

                    {userDetail.status === 'SUSPENDED' || userDetail.status === 'DELETED' ?
                    <button className="bg-[#3498db] text-white font-semibold py-[5px] w-full rounded-lg cursor-default">
                        Block User
                    </button>
                    :userDetail.status === 'BLOCKED' ?
                    <button className="bg-[#2970FF] text-white font-semibold py-[5px] w-full rounded-lg" onClick={handleUnBlockUser}>
                        Unblock User
                    </button>
                    :
                    <button className="bg-[#2970FF] text-white font-semibold py-[5px] w-full rounded-lg" onClick={handleBlockUser}>
                        Block User
                    </button>
                    }

                    {userDetail.status === 'DELETED' ?
                    <button className="bg-[#fab1a0] text-white font-semibold py-[5px] w-full rounded-lg cursor-default">
                        Delete User
                    </button>
                    :
                    <button className="bg-[#CA4E4E] text-white font-semibold py-[5px] w-full rounded-lg" onClick={handleCloseAccount}>
                        Delete User
                    </button>
                    }
                  </div>
                </div>
              </div>
            </div>

            {dataKyc.length > 0 ?
            <div className="w-full h-full mb-[32px]">
              <div className="flex flex-row gap-5">
                <div className="w-[65%]">
                  <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">KYC Submission Log</h2>
                  <div className='bg-[#1E1F23] p-[12px] rounded-2xl border-2 border-[#5D5F61] cursor-pointer mb-3'>
                    <div className='flex flex-col gap-3'>
                      <div className='flex flex-row items-center justify-between' onClick={() => setToggleHistoryKyc(!toggleHistoryKyc)}>
                        <div className='flex flex-row items-center justify-between w-full' onClick={changeFileKycActive}>
                          <p className='text-white'>Created on, {kyc.tableuserkyclogtimestamp}</p>
                          <div className='flex flex-row items-center gap-3'>
                            {kyc.tableuserkyclogstatus === 'NOT ACTIVE' ?
                            <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">{kyc.tableuserkyclogstatus}</span>
                            : ''}

                            {kyc.tableuserkyclogstatus === 'ACTIVE' ?
                            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">{kyc.tableuserkyclogstatus}</span>
                            : ''}

                            {kyc.tableuserkyclogstatus === 'NEED REVIEW' ?
                            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">{kyc.tableuserkyclogstatus}</span>
                            : ''}

                            {kyc.tableuserkyclogstatus === 'REJECTED' ?
                            <span class="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-pink-400 border border-pink-400">{kyc.tableuserkyclogstatus}</span>
                            : ''}  

                            {kyc.tableuserkyclogstatus === 'FREEZE' ?
                            <span class="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">{kyc.tableuserkyclogstatus}</span>
                            : ''}  

                            {kyc.tableuserkyclogstatus === 'BLOCKED' ?
                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">{kyc.tableuserkyclogstatus}</span>
                            : ''} 

                            {kyc.tableuserkyclogstatus === 'SUSPENDED' ?
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{kyc.tableuserkyclogstatus}</span>
                            : ''}    

                            {kyc.tableuserkyclogstatus === 'DELETED' ?
                            <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">{kyc.tableuserkyclogstatus}</span>
                            : ''}
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M19 9L12 16L5 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </div>
                        </div>
                      </div>

                      {toggleHistoryKyc ? 
                      <>
                      {listLogKyc.map((result,key) => (
                        <div className='flex flex-row items-center justify-between' key={key} onClick={() => changeFileKyc(result.tableuserkyclogindex)}>
                          <p className='text-white'>Created on, {result.tableuserkyclogtimestamp}</p>
                          <div className='flex flex-row items-center gap-3'>
                            {result.tableuserkyclogstatus === 'NOT ACTIVE' ?
                            <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">{result.tableuserkyclogstatus}</span>
                            : ''}

                            {result.tableuserkyclogstatus === 'ACTIVE' ?
                            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">{result.tableuserkyclogstatus}</span>
                            : ''}

                            {result.tableuserkyclogstatus === 'NEED REVIEW' ?
                            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">{result.tableuserkyclogstatus}</span>
                            : ''}

                            {result.tableuserkyclogstatus === 'REJECTED' ?
                            <span class="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-pink-400 border border-pink-400">{result.tableuserkyclogstatus}</span>
                            : ''}  

                            {result.tableuserkyclogstatus === 'FREEZE' ?
                            <span class="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">{result.tableuserkyclogstatus}</span>
                            : ''}  

                            {result.tableuserkyclogstatus === 'BLOCKED' ?
                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">{result.tableuserkyclogstatus}</span>
                            : ''} 

                            {result.tableuserkyclogstatus === 'SUSPENDED' ?
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{result.tableuserkyclogstatus}</span>
                            : ''}    

                            {result.tableuserkyclogstatus === 'DELETED' ?
                            <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">{result.tableuserkyclogstatus}</span>
                            : ''}
                          </div>
                        </div>
                      ))}
                      
                      </>
                      : '' }
                    </div>
                  </div>
                  
                  {userDetail.tableuserkycrevision !== null ?
                  <div className='bg-[#1E1F23] p-[12px] rounded-2xl border-2 border-[#5D5F61] cursor-pointer mb-3'>
                    <p className='text-white'>Reason for Rejection</p>
                    <p className='text-white pt-3'>"{userDetail.tableuserkycrevision}"</p>
                  </div> : '' }
                    {console.log(activeLogKyc)}
                  <div className='bg-[#1E1F23] w-full p-[12px] mb-3'>
                    <p className='text-[#BABEC1]'>KTP</p>
                    <div className='flex flex-col w-full items-center my-4'>
                      <img src={activeLogKyc.tableuserkyclogidcardpictfullpath} alt='kyc' className='w-[425px] h-[248px]'/>
                      {/* <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_5795_16816)">
                          <rect x="8" y="7" width="40" height="40" rx="7" fill="white" shape-rendering="crispEdges"/>
                          <rect x="8.75" y="7.75" width="38.5" height="38.5" rx="6.25" stroke="#D7DCDF" stroke-width="1.5" shape-rendering="crispEdges"/>
                          <path d="M27.4088 25.9461C29.1312 27.6685 29.3114 30.3491 27.9494 32.272L27.808 32.4617L32.0254 36.68L32.1002 36.7667C32.3248 37.0695 32.2999 37.499 32.0254 37.7735C31.7509 38.048 31.3213 38.073 31.0186 37.8484L30.9319 37.7735L26.6754 33.5171C24.7599 34.8086 22.1375 34.607 20.4427 32.9122C18.5191 30.9886 18.5191 27.8698 20.4427 25.9461C22.3664 24.0225 25.4852 24.0225 27.4088 25.9461ZM29.1289 17C29.7254 17 30.2976 17.2369 30.7195 17.6586L33.5314 20.469L36.339 23.2809C36.7602 23.7027 36.9968 24.2745 36.9968 24.8707V34.75C36.9968 35.9926 35.9895 37 34.7468 37L33.2375 37.0006C33.2001 36.7076 33.0892 36.4214 32.9033 36.1709L32.7823 36.0266L32.2556 35.499L34.7468 35.5C35.161 35.5 35.4968 35.1642 35.4968 34.75L35.496 25.003L31.25 25.0039C30.0591 25.0039 29.0844 24.0787 29.0052 22.9079L29 22.7539V18.5H23.25C22.8358 18.5 22.5 18.8358 22.5 19.25L22.4997 23.6712C21.9808 23.7943 21.4753 23.9882 20.9986 24.2529L21 19.25C21 18.0074 22.0074 17 23.25 17H29.1289ZM21.5362 27.0396C20.2165 28.3593 20.2165 30.499 21.5362 31.8187C22.8559 33.1384 24.9956 33.1384 26.3153 31.8187C27.635 30.499 27.635 28.3593 26.3153 27.0396C24.9956 25.7199 22.8559 25.7199 21.5362 27.0396ZM30.5 19.56V22.7539C30.5 23.1336 30.7822 23.4474 31.1482 23.497L31.25 23.5039L34.441 23.503L30.5 19.56Z" fill="#343A40"/>
                        </g>
                        <defs>
                          <filter id="filter0_d_5795_16816" x="0" y="0" width="62" height="62" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="5.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.552941 0 0 0 0 0.552941 0 0 0 0 0.552941 0 0 0 0.13 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5795_16816"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5795_16816" result="shape"/>
                          </filter>
                        </defs>
                      </svg> */}
                    </div>
                  </div>
                  
                  {activeLogKyc.tableuserkyclognpwppictfullpath !== null ?
                  <div className='bg-[#1E1F23] w-full p-[12px] mb-3'>
                    <p className='text-[#BABEC1]'>NPWP</p>
                    <div className='flex flex-col w-full items-center my-4'>
                      <img src={activeLogKyc.tableuserkyclognpwppictfullpath} alt='kyc' className='w-[425px] h-[248px]'/>
                      {/* <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_5795_16816)">
                          <rect x="8" y="7" width="40" height="40" rx="7" fill="white" shape-rendering="crispEdges"/>
                          <rect x="8.75" y="7.75" width="38.5" height="38.5" rx="6.25" stroke="#D7DCDF" stroke-width="1.5" shape-rendering="crispEdges"/>
                          <path d="M27.4088 25.9461C29.1312 27.6685 29.3114 30.3491 27.9494 32.272L27.808 32.4617L32.0254 36.68L32.1002 36.7667C32.3248 37.0695 32.2999 37.499 32.0254 37.7735C31.7509 38.048 31.3213 38.073 31.0186 37.8484L30.9319 37.7735L26.6754 33.5171C24.7599 34.8086 22.1375 34.607 20.4427 32.9122C18.5191 30.9886 18.5191 27.8698 20.4427 25.9461C22.3664 24.0225 25.4852 24.0225 27.4088 25.9461ZM29.1289 17C29.7254 17 30.2976 17.2369 30.7195 17.6586L33.5314 20.469L36.339 23.2809C36.7602 23.7027 36.9968 24.2745 36.9968 24.8707V34.75C36.9968 35.9926 35.9895 37 34.7468 37L33.2375 37.0006C33.2001 36.7076 33.0892 36.4214 32.9033 36.1709L32.7823 36.0266L32.2556 35.499L34.7468 35.5C35.161 35.5 35.4968 35.1642 35.4968 34.75L35.496 25.003L31.25 25.0039C30.0591 25.0039 29.0844 24.0787 29.0052 22.9079L29 22.7539V18.5H23.25C22.8358 18.5 22.5 18.8358 22.5 19.25L22.4997 23.6712C21.9808 23.7943 21.4753 23.9882 20.9986 24.2529L21 19.25C21 18.0074 22.0074 17 23.25 17H29.1289ZM21.5362 27.0396C20.2165 28.3593 20.2165 30.499 21.5362 31.8187C22.8559 33.1384 24.9956 33.1384 26.3153 31.8187C27.635 30.499 27.635 28.3593 26.3153 27.0396C24.9956 25.7199 22.8559 25.7199 21.5362 27.0396ZM30.5 19.56V22.7539C30.5 23.1336 30.7822 23.4474 31.1482 23.497L31.25 23.5039L34.441 23.503L30.5 19.56Z" fill="#343A40"/>
                        </g>
                        <defs>
                          <filter id="filter0_d_5795_16816" x="0" y="0" width="62" height="62" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="5.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.552941 0 0 0 0 0.552941 0 0 0 0 0.552941 0 0 0 0.13 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5795_16816"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5795_16816" result="shape"/>
                          </filter>
                        </defs>
                      </svg> */}
                    </div>
                  </div>: '' }
                  
                  {userDetail.tipeuser === 'developer' ? 
                  <div className='bg-[#1E1F23] w-full p-[12px] mb-3'>
                    <p className='text-[#BABEC1]'>Document</p>
                    <div className='flex flex-col w-full items-center my-4'>
                      <img src={activeLogKyc.tableuserkyclogcompanydocumentpictfullpath} alt='kyc' className='w-[425px] h-[248px]'/>
                      {/* <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_5795_16816)">
                          <rect x="8" y="7" width="40" height="40" rx="7" fill="white" shape-rendering="crispEdges"/>
                          <rect x="8.75" y="7.75" width="38.5" height="38.5" rx="6.25" stroke="#D7DCDF" stroke-width="1.5" shape-rendering="crispEdges"/>
                          <path d="M27.4088 25.9461C29.1312 27.6685 29.3114 30.3491 27.9494 32.272L27.808 32.4617L32.0254 36.68L32.1002 36.7667C32.3248 37.0695 32.2999 37.499 32.0254 37.7735C31.7509 38.048 31.3213 38.073 31.0186 37.8484L30.9319 37.7735L26.6754 33.5171C24.7599 34.8086 22.1375 34.607 20.4427 32.9122C18.5191 30.9886 18.5191 27.8698 20.4427 25.9461C22.3664 24.0225 25.4852 24.0225 27.4088 25.9461ZM29.1289 17C29.7254 17 30.2976 17.2369 30.7195 17.6586L33.5314 20.469L36.339 23.2809C36.7602 23.7027 36.9968 24.2745 36.9968 24.8707V34.75C36.9968 35.9926 35.9895 37 34.7468 37L33.2375 37.0006C33.2001 36.7076 33.0892 36.4214 32.9033 36.1709L32.7823 36.0266L32.2556 35.499L34.7468 35.5C35.161 35.5 35.4968 35.1642 35.4968 34.75L35.496 25.003L31.25 25.0039C30.0591 25.0039 29.0844 24.0787 29.0052 22.9079L29 22.7539V18.5H23.25C22.8358 18.5 22.5 18.8358 22.5 19.25L22.4997 23.6712C21.9808 23.7943 21.4753 23.9882 20.9986 24.2529L21 19.25C21 18.0074 22.0074 17 23.25 17H29.1289ZM21.5362 27.0396C20.2165 28.3593 20.2165 30.499 21.5362 31.8187C22.8559 33.1384 24.9956 33.1384 26.3153 31.8187C27.635 30.499 27.635 28.3593 26.3153 27.0396C24.9956 25.7199 22.8559 25.7199 21.5362 27.0396ZM30.5 19.56V22.7539C30.5 23.1336 30.7822 23.4474 31.1482 23.497L31.25 23.5039L34.441 23.503L30.5 19.56Z" fill="#343A40"/>
                        </g>
                        <defs>
                          <filter id="filter0_d_5795_16816" x="0" y="0" width="62" height="62" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="5.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.552941 0 0 0 0 0.552941 0 0 0 0 0.552941 0 0 0 0.13 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5795_16816"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5795_16816" result="shape"/>
                          </filter>
                        </defs>
                      </svg> */}
                    </div>
                  </div> : '' }

                  {userDetail.tipeuser === 'gamer' ? 
                  <div className='bg-[#1E1F23] w-full p-[12px] mb-3'>
                    <p className='text-[#BABEC1]'>Photo Selfie + KTP</p>
                    <div className='flex flex-col w-full items-center my-4'>
                      <img src={activeLogKyc.tableuserkyclogselfiepictpictfullpath} alt='kyc' className='w-[425px] h-[248px]'/>
                      {/* <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_5795_16816)">
                          <rect x="8" y="7" width="40" height="40" rx="7" fill="white" shape-rendering="crispEdges"/>
                          <rect x="8.75" y="7.75" width="38.5" height="38.5" rx="6.25" stroke="#D7DCDF" stroke-width="1.5" shape-rendering="crispEdges"/>
                          <path d="M27.4088 25.9461C29.1312 27.6685 29.3114 30.3491 27.9494 32.272L27.808 32.4617L32.0254 36.68L32.1002 36.7667C32.3248 37.0695 32.2999 37.499 32.0254 37.7735C31.7509 38.048 31.3213 38.073 31.0186 37.8484L30.9319 37.7735L26.6754 33.5171C24.7599 34.8086 22.1375 34.607 20.4427 32.9122C18.5191 30.9886 18.5191 27.8698 20.4427 25.9461C22.3664 24.0225 25.4852 24.0225 27.4088 25.9461ZM29.1289 17C29.7254 17 30.2976 17.2369 30.7195 17.6586L33.5314 20.469L36.339 23.2809C36.7602 23.7027 36.9968 24.2745 36.9968 24.8707V34.75C36.9968 35.9926 35.9895 37 34.7468 37L33.2375 37.0006C33.2001 36.7076 33.0892 36.4214 32.9033 36.1709L32.7823 36.0266L32.2556 35.499L34.7468 35.5C35.161 35.5 35.4968 35.1642 35.4968 34.75L35.496 25.003L31.25 25.0039C30.0591 25.0039 29.0844 24.0787 29.0052 22.9079L29 22.7539V18.5H23.25C22.8358 18.5 22.5 18.8358 22.5 19.25L22.4997 23.6712C21.9808 23.7943 21.4753 23.9882 20.9986 24.2529L21 19.25C21 18.0074 22.0074 17 23.25 17H29.1289ZM21.5362 27.0396C20.2165 28.3593 20.2165 30.499 21.5362 31.8187C22.8559 33.1384 24.9956 33.1384 26.3153 31.8187C27.635 30.499 27.635 28.3593 26.3153 27.0396C24.9956 25.7199 22.8559 25.7199 21.5362 27.0396ZM30.5 19.56V22.7539C30.5 23.1336 30.7822 23.4474 31.1482 23.497L31.25 23.5039L34.441 23.503L30.5 19.56Z" fill="#343A40"/>
                        </g>
                        <defs>
                          <filter id="filter0_d_5795_16816" x="0" y="0" width="62" height="62" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="3" dy="4"/>
                            <feGaussianBlur stdDeviation="5.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.552941 0 0 0 0 0.552941 0 0 0 0 0.552941 0 0 0 0.13 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5795_16816"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5795_16816" result="shape"/>
                          </filter>
                        </defs>
                      </svg> */}
                    </div>
                  </div> : '' }
                </div>
                <div className="w-[35%]">
                  <h2 className="text-[#FFF] text-[20px] font-semibold mb-3">Action</h2>
                  <div className='flex flex-col gap-2'>
                    {userDetail.status === 'NOT ACTIVE' || userDetail.status === 'REJECTED' || userDetail.status === 'DELETED' || userDetail.status === 'SUSPENDED' || userDetail.status === 'BLOCKED' ?
                    <button className="bg-[#74b9ff] text-white font-semibold py-[5px] w-full rounded-lg cursor-default">
                        Approve KYC
                    </button>
                    :
                    userDetail.status === 'ACTIVE' ?
                    <button className="bg-[#74b9ff] text-white font-semibold py-[5px] w-full rounded-lg">
                        Approve KYC
                    </button>
                    :
                    <button className="bg-[#2970FF] text-white font-semibold py-[5px] w-full rounded-lg" onClick={handleApproveKyc}>
                        Approve KYC
                    </button>
                    }


                    {userDetail.status === 'NOT ACTIVE' || userDetail.status === 'REJECTED' || userDetail.status === 'DELETED' || userDetail.status === 'SUSPENDED' || userDetail.status === 'BLOCKED' ?
                    <button className="bg-[#fab1a0] text-white font-semibold py-[5px] w-full rounded-lg cursor-default">
                        Reject KYC
                    </button>
                    :
                    userDetail.status === 'REJECTED' || userDetail.status === 'ACTIVE' ?
                    <button className="bg-[#fab1a0] text-white font-semibold py-[5px] w-full rounded-lg">
                        Reject KYC
                    </button>
                    :
                    <button className="bg-[#CA4E4E] text-white font-semibold py-[5px] w-full rounded-lg" onClick={handleRejectKyc}>
                        Reject KYC
                    </button>
                    }
                  </div>
                </div>
              </div>
            </div>
            : '' }
          </div>

        </div>
      </div>
    </>
  );
}

export default User;
