import React, { Component } from 'react'



class Test_api extends Component{
    constructor(props){
        super(props)
        this.state = {
            items : [],
            isLoading: true
        }
    }

    componentDidMount(){
        fetch("https://jsonplaceholder.typicode.com/users")
            .then(response => response.json() )
            .then(data => this.setState({ items:  data, isLoading:  false }))
    }

    render() {
        const { items, isLoading } = this.state

        if(isLoading) {
            return <p>Loading . . . . . .</p>
        }

        return (
            <div className='flex flex-row'>
                <div className='mr-10'>
                    <ul>
                        { items.map((item, index) =>
                            <li key={index}> {item.name} </li> 
                        ) }
                    </ul>
                </div>
                <div>
                    <ul>
                        { items.map((item, index) =>
                            <li key={index}> {item.username} </li>
                        )}
                    </ul>
                </div>
                
            </div>
        );
    }

} 


export default Test_api