// --- import image
import Icon_logo from "../../src/assets/Logo2.png";
import Icon_logo_1 from "../../src/assets/Logo21.png";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiChevronsUp } from "react-icons/fi";
import {FiAlignJustify} from "react-icons/fi";
import Icon_dashboard from "../../src/assets/Icon_dashboard.png";
import Icon_user from "../../src/assets/Icon_user.png";
import Icon_transaction from "../../src/assets/Icon_transaction.png";
import Icon_review from "../../src/assets/Icon_review.png";
import Icon_log from "../../src/assets/Icon_log.png";
// import Icon_admin from "../../src/assets/Icon_admin.png";
// import Icon_media from "../../src/assets/Icon_media.png";
// import Icon_language from "../../src/assets/Icon_language.png";
import Icon_logout from "../../src/assets/Icon_logout.png";

import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import CryptoJS from 'crypto-js';
import axios from 'axios';
import io from 'socket.io-client';

export default function Sidebar(props) {

    const location = useLocation();
    let convertStringToArray = location.pathname.split('/');
    const [notifUser, setNotifUser] = useState(0);
    const [notifReview, setNotifReview] = useState(0);
    const [notif, setNotif] = useState(false);
    const [jumlahNotif, setJumlahNotif] = useState(0);
    const [notifikasiList, setNotifikasiList] = useState([]);
    const navigate = useNavigate();
    const [configSocket, setConfigSocket] = useState(null);

    const Menus = [
        { title: "Dashboard", src: Icon_dashboard, urlRoute: '/dashboard'},
        { title: "Threads", src: Icon_log, urlRoute: '/threads'},
        { title: "User", src: Icon_user, urlRoute: '/user'},
        { title: "Transaction", src: Icon_transaction, urlRoute: '/transaction'},
        { title: "Review", src: Icon_review, urlRoute: '/listreview'},
        { title: "Log", src: Icon_log, urlRoute: '/log'},
        // { title: "Admin", src: Icon_admin, urlRoute: '/admin'},
        // { title: "Media", src: Icon_media, urlRoute: '/media'},
        // { title: "Language", src: Icon_language, urlRoute: '/language'},
        { title: "Logout", src: Icon_logout, gap: true, urlRoute: '/logout'},
    ];

    const [showSidebar, setShowSidebar] = useState(true);

    const newNotif = useCallback(async () => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;
        let payloadSignature = [];

        let signature = CryptoJS.HmacSHA256(payloadSignature, 'brekltespedriguc').toString();

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: userindex,
            tokenlogin: usertoken,
            signature: signature,
        };

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(
            'https://back.thespace.world' + "/api/user/v01/newnotif?id="+currentTimestamp,
            {
                headers: dataHeaders,
            }
        );

        if(response.data.status.status === 1){
            setJumlahNotif(response.data.data.total_notif);
        }
    }, []);

    const loadNotification = useCallback(async () => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let payloadSignature = [];

        let signature = CryptoJS.HmacSHA256(payloadSignature, 'brekltespedriguc').toString();

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: userindex,
            tokenlogin: usertoken,
            signature: signature,
        };

        const currentTimestamp = new Date().getTime();

        let response = await axios.get(
            'https://back.thespace.world' + "/api/user/v01/notification?id="+currentTimestamp,
            {
                headers: dataHeaders,
            }
        );

        if(response.data.status.status === 1){
            setNotifikasiList(response.data.data);
        }
    }, []);       

    useEffect(() => {
        const socketio = io('https://back.thespace.world');
        setConfigSocket(socketio);
    }, []);

    useEffect(() => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;

        if(configSocket !== null){
            configSocket.on(userindex, (data) => {
                newNotif();
            })
        }
    }, [configSocket, newNotif, loadNotification]);

    const readNotifHandle = async (result) => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let payloadSignature = [];
        let signature = CryptoJS.HmacSHA256(payloadSignature, 'brekltespedriguc').toString();

        let dataHeaders = {
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: userindex,
            tokenlogin: usertoken,
            signature: signature,
        };

        let body = {
            notificationindex : result.tablenotificationindex
        }

        const currentTimestamp = new Date().getTime();

        let response = await axios.post('https://back.thespace.world' + "/api/user/v01/readnotif?id="+currentTimestamp,body,{
            headers: dataHeaders,
        });

        if(response.data.status.status === 1){
            loadNotification();
            setNotif(false);
            navigate('/'+result.tablenotificationlink);
        }
    }

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const loadNotifBar = useCallback( async () => {
        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature
        }

        const currentTimestamp = new Date().getTime();
  
        let response = await axios.get('https://back.thespace.world/api/user/v01/admin/notification?id='+currentTimestamp, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            setNotifUser(response.data.data.notifUser);
            setNotifReview(response.data.data.notifReview);
        }
    }); 

    useEffect(() => {
        newNotif();
    }, [newNotif]);

    const clickNotif = () => {
        setNotif(true);
        loadNotification();
        setTimeout(() => {
            newNotif();
        }, 2000);
    }

    return (
        <>
            <div className={`duration-700 transform ${showSidebar ? 'translate-y-0' : 'translate-y-full'}`}>
                {showSidebar ? null : (
                    <div className="w-full -mt-6">
                        <FiAlignJustify
                            size={30}
                            className={`text-gray-400 cursor-pointer`}
                            onClick={toggleSidebar}
                        />
                    </div>
                )}
                {showSidebar && (
                    <div className={`${localStorage.getItem('gesertoggle') !== null ? 'w-20' : 'w-72' }
                        h-[95vh] duration-300 p-5 pt-5 bg-[#1E1F23] relative rounded-2xl shadow-md shadow-slate-300`}>
                        <div className="flex gap-x-1 items-center justify-between">
                            <div className="flex flex-row items-center">
                                <img src={Icon_logo} 
                                    className={`w-[50px] cursor-pointer duration-500 
                                    ${ localStorage.getItem('gesertoggle') && "rotate-[360deg]"}`}
                                />
                                <img src={Icon_logo_1}
                                    className={`w-[142px] origin-left duration-300
                                    ${localStorage.getItem('gesertoggle') && "scale-0"}`}
                                />
                            </div>
                            <div className="relative cursor-pointer" onClick={clickNotif}>
                                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17H4L5.40493 15.5951C5.78595 15.2141 6 14.6973 6 14.1584V11C6 8.38757 7.66962 6.16509 10 5.34142V5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5V5.34142C16.3304 6.16509 18 8.38757 18 11V14.1584C18 14.6973 18.2141 15.2141 18.5951 15.5951L20 17H15M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17M9 17H15" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                {jumlahNotif > 0 ?
                                <span class="bg-red-100 text-red-800 text-sm font-medium rounded dark:bg-red-900 dark:text-red-300 absolute top-0 right-0 z-10 p-[3px] px-[5px]">{jumlahNotif}</span>  : '' }
                            </div>
                        </div>
                        <FiArrowLeftCircle 
                            size={30} 
                            className={`absolute cursor-pointer rounded-full -right-3 top-20 bg-white
                            ${localStorage.getItem('gesertoggle') && "rotate-180"}`}
                            onClick={props.togglesidebar}
                        />
                        <ul className={`pt-12`}>
                            {Menus.map((menu, index) => (
                                <li key={index} className="">
                                    <Link to={{ pathname: menu.urlRoute }} className={`w-full text-gray-400 text-sm group flex items-center 
                                                gap-x-4 cursor-pointer p-2 hover:bg-blue-600 rounded-md
                                                ${menu.gap ? "mt-10" : "mt-3" }
                                                ${'/'+convertStringToArray[1] === menu.urlRoute && 'bg-blue-600'}`}>
                                        <img src={`${menu.src}`}
                                        />
                                        <div class="flex flex-row justify-between w-full">
                                            <span className={`${localStorage.getItem('gesertoggle') !== null ? 'hidden' : 'block'}
                                                origin-left duration-200`}>
                                                {menu.title}
                                            </span>
                                            {menu.title === 'Review' ? notifReview > 0 ?
                                            <span className="bg-[#4C4DDC] rounded-[99px] text-center p-1 text-white font-bold">
                                                {menu.title === 'Review' ? notifReview : ''}
                                            </span> : '' : '' }

                                            {menu.title === 'User' ? notifUser > 0 ?
                                            <span className="bg-[#4C4DDC] rounded-[99px] text-center p-1 text-white font-bold">
                                                {menu.title === 'User' ? notifUser : ''}
                                            </span> : '' : ''}
                                        </div>
                                        <span className={`${localStorage.getItem('gesertoggle') == null ? 'hidden' : 'block'}
                                            absolute left-40 bg-white whitespace-pre text-gray-600 font-semibold rounded-md drop-shadow-lg 
                                            px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-24 group-hover:duration-500 group-hover:w-fit`}>
                                            {menu.title}
                                        </span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <div className="flex gap-x-1 items-center my-5 ml-1">
                            <FiChevronsUp
                                size={20}
                                className={`text-gray-400 cursor-pointer hidden max-sm:block`}
                                onClick={toggleSidebar}
                            />
                        </div>
                    </div>
                )}
            </div>
            {notif ? <>
                <div className="bg-bg1 backdrop-blur-[3px] fixed inset-0 z-30"></div>
                <div className="fixed inset-0 z-30">
                    <div className="relative w-full h-full">
                        <div className="absolute translate-y-[-30%] top-[30%] w-full ">
                            <div className="max-w-[725px] m-auto">
                                <div className="bg-gray-700 rounded px-[48px] py-[48px]">
                                    <div className="flex flex-row justify-between items-center">
                                        <label className="font-bold text-white">NOTIFICATION</label>
                                        <div className="cursor-pointer" onClick={() => setNotif(false)}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 18L18 6M6 6L18 18" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="mt-5 max-h-[400px] overflow-auto">
                                        {notifikasiList.map((result, key) => (
                                            <div className="border-b py-5 cursor-pointer mb-1" onClick={() => readNotifHandle(result)} key={key}>
                                                <div className="flex flex-row items-center justify-between">
                                                    <div className="flex flex-row items-center gap-4">
                                                        {result.tablenotificationpict !== null ? result.tablenotificationpict !== '' ? 
                                                        <img src={`https://back.thespace.world/public/${result.tablenotificationpict}`} alt="picnotif" className="w-[64px] h-[64px] rounded-full"/> : '' : '' }
                                                        <div>
                                                            <h1 className="font-bold text-white">{result.tablenotificationtitle}</h1>
                                                            <p className="text-white">{result.tablenotificationmessage}</p>
                                                            <p className="text-blue-500">
                                                                {result.tablenotificationtimestamp}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {result.tablenotificationisread !== 1 ?
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="6" cy="6" r="6" fill="#6895FD"/>
                                                        </svg> : '' }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : '' }
        </>
    );
}