import React, { useEffect, useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';


import Sidebar from "../../components/Sidebar";
import { FiArrowLeft } from "react-icons/fi";
import { BiPencil } from "react-icons/bi";

function Admin() {

    const navigate = useNavigate();
    const [adminList, setAdminList] = useState([]);

    const [geser, setGeser] = useState(false);

    const handleToggleSidebar = () => {
        if(localStorage.getItem("gesertoggle") !== null){
            localStorage.removeItem("gesertoggle");
            setGeser(true);
        }else{
            if(geser === false){
                setGeser(true);
                localStorage.setItem("gesertoggle", "true");
            }else{
                setGeser(false);
                localStorage.removeItem("gesertoggle");
            }
        }
    }

    const gesertoggle = localStorage.getItem("gesertoggle");

    const userHandle = async () => {

        let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = '';
        // StringSign = 'PASSS';
        // StringSign = userindex;
        // StringSign = usertoken;

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature

        }

        let body = {
        }

        let response = await axios.post('https://back.thespace.world/api/user/v01/admin/listadmin', body, {
            'headers' : header
        });

        if(response.data.status.status === 1){
            console.log(response.data.data);
            setAdminList(response.data.data);
        }

        console.log(response.data);
    }

    useEffect(() => {
        userHandle();
    }, [])



    return (
        <>
            <div className="bg-black min-h-full">
                <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
                    <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
                </div>
                <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[15px] max-sm:pr-[15px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
                    <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
                        <div className="flex flex-row m-3 ml-0">
                            <FiArrowLeft size={25} className="mt-1 mr-1" />
                            <div className="flex  flex-col">
                                <h1 className='text-2xl max-sm:text-xl'>Admin</h1>
                                <h2 className="text-sm max-sm:text-xs">../Admin/</h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col m-3 max-sm:m-0">
                        <div className="">
                            <div className="w-full">
                                <div className="flex flex-col">
                                    <div className="overflow-x-auto">
                                    <div className="p-1.5 max-sm:px-0 w-full inline-block align-middle">
                                        <div className="overflow-hidden rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-[#1E1F23] rounded-lg">
                                            <tr>
                                                <th
                                                scope="col"
                                                className="px-6 py-3 text-xs font-bold text-left text-[#989B9D] uppercase "
                                                >
                                                Nama
                                                </th>
                                                <th
                                                scope="col"
                                                className="px-6 py-3 text-xs font-bold text-left text-[#989B9D] uppercase "
                                                >
                                                Role
                                                </th>
                                                <th
                                                scope="col"
                                                className="px-6 py-3 text-xs font-bold text-left text-[#989B9D] uppercase "
                                                >
                                                Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 text-gray-300">
                                            {adminList.map((result) => (
                                            <tr>
                                                <td className="px-6 py-4 text-sm  whitespace-nowrap">
                                                    {result.username}
                                                </td>
                                                <td className="px-6 py-4 text-sm  whitespace-nowrap">
                                                    {result.tipeuser}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                <a
                                                    className=" hover:text-gray-100"
                                                    href={`admin/editadmin/${result.userindex}`}
                                                >
                                                    <BiPencil size={25} className="" />
                                                </a>
                                                </td>
                                            </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admin
