
import Dashboard from "./pages/Dashboard";
import User from "./pages/user/User";
import Userdetail from "./pages/user/Userdetail";
import Wallet from "./pages/user/Wallet";
import Admin from "./pages/admin/admin";
import Editadmin from "./pages/admin/edit_admin";
import Listreview from "./pages/review/list_review";
import Detailreview from "./pages/review/detail_review";
import Transaction from "./pages/transaction/transaction";
import Detailtransaction from "./pages/transaction/detailtransaction";
import Log from "./pages/log/log";
import Media from "./pages/media/media";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Language from "./pages/language/language";
import Edit_language from "./pages/language/edit_language";
import Test_api from "./pages/Test_api";
import Authentication from "./hoc/Authentication";
import NonAuthentication from "./hoc/NonAuthentication";


import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NewRegister from "./pages/NewRegister";
import Logout from "./pages/Logout";
import Threads from "./pages/threads/Threads";
import ThreadsDetail from "./pages/threads/ThreadsDetail";
import UserLog from "./pages/user/UserLog";


function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<NonAuthentication><Login /></NonAuthentication>} />
          <Route path="/dashboard" element={<Authentication><Dashboard /></Authentication>} />
          <Route path="/user" element={<Authentication><User /></Authentication>} />
          <Route path="/user/:id" element={<Authentication><Userdetail /></Authentication>} />
          <Route path="/login" element={<NonAuthentication><Login /></NonAuthentication>} />
          <Route path="/wallet" element={<Authentication><Wallet/></Authentication>} />
          <Route path="/admin" element={<Authentication><Admin/></Authentication>}/>
          <Route path="/admin/editadmin/:id" element={<Authentication><Editadmin/></Authentication>}/>
          <Route path="/listreview" element={<Authentication><Listreview/></Authentication>}/>
          <Route path="/listreview/:id" element={<Authentication><Detailreview/></Authentication>}/>
          <Route path="/transaction" element={<Authentication><Transaction/></Authentication>}/>
          <Route path="/transaction/:id" element={<Authentication><Detailtransaction/></Authentication>}/>
          <Route path="/media" element={<Authentication><Media/></Authentication>}/>
          <Route path="/language" element={<Authentication><Language/></Authentication>}/>
          <Route path="language/edit_language" element={<Authentication><Edit_language/></Authentication>}/>
          <Route path="/log" element={<Authentication><Log/></Authentication>}/>
          <Route path="/register" element={<Register />} />
          <Route path="/new-register" element={<NewRegister />} />
          <Route path="/Test_api" element={<Test_api />} />
          <Route path="/user/log/:id" element={<UserLog />} />

          <Route path="/threads" element={<Authentication><Threads/></Authentication>}/>
          <Route path="/threads/:id" element={<Authentication><ThreadsDetail/></Authentication>}/>
          <Route path="/logout" element={<Authentication><Logout/></Authentication>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
