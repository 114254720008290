import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Breadcrumbslatest from '../components/Breadcrumbslatest';
import { Link, resolvePath } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';
import Chart from "react-apexcharts";
import CryptoJS from 'crypto-js';
import axios from 'axios';
import {sub, format, startOfMonth, endOfDay, eachDayOfInterval } from 'date-fns';

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

function Dashboard() {
  const [geser, setGeser] = useState(false);

  const [optionsUsersDeveloper, setOptionsUsersDeveloper] = useState({
    chart: {
      id: "line-chart"
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: '#ffffff', 
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff', 
        }
      }
    },
    markers: {
      size: [4, 7]
    }
  });
  const [seriesUsersDevelopers, setSeriesUsersDevelopers] = useState([]);

  const [optionsUsersGamers, setOptionsUsersDGamers] = useState({
    chart: {
      id: "line-chart"
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: '#ffffff', 
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff', 
        }
      }
    },
    markers: {
      size: [4, 7]
    }
  });
  const [seriesUsersGamers, setSeriesGamers] = useState([]);

  const [optionsStatistic, setOptionStatistic] = useState({
    chart: {
      id: "line-chart"
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: '#ffffff', 
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff',
        }
      }
    },
    stroke: {
      curve: 'smooth',
    }
  });
  const [seriesStatistic, setSeriesStatistic] = useState([]);

  const [optionsRatio, setOptionsRation] = useState({
    labels: [],
    legend: {
      position: 'right',
      labels: {
        colors: '#ffffff',
      } 
    },
    dataLabels: {
      enabled: false
    },
  });
  const [seriesRatio, setSeriesRatio] = useState([]);

  const [needAction, setNeedAction] = useState({});
  const [users, setUsers] = useState({});
  const [statistic, setStatistic] = useState({});

  const [usersStartDate, setUsersStartDate] = useState('2023-12-05');
  const [usersEndDate, setUsersEndDate] = useState('2023-12-30');

  const [statisticStartDate, setStatisticStartDate] = useState('2023-12-05');
  const [statisticEndDate, setStatisticEndDate] = useState('2023-12-30');

  const [switchStatistic, setSwichStatistic] = useState(0);
  const [switchRatio, setSwitchRatio] = useState(0);

  const [filterDate, setFilterDate] = useState({
    output : 'Pilih Rentang Waktu',
    start_date : '',
    end_date : '',
    start_be_date : '',
    end_be_date : ''
  });

  const [dateRangePicker, setDateRangePicker] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const [toggleSelectDate, setToggleSelectDate] = useState(false);
  const [toggleDropdownDate, setToggleDropdownDate] = useState(false);

  const [outputDate, setOutputDate] = useState({
    'today' : '',
    'yesterday' : '',
    'last30days' : '',
    'thismonth' : ''
  });

  useEffect(() => {
    let dateToday = new Date();
    let today = format(dateToday, 'dd MMM yyyy');

    const dateYesterday = sub(new Date(), { days: 1 });
    const yesterday = format(dateYesterday, 'dd MMM yyyy');

    const dateLast30Days = sub(new Date(), { days: 30 });
    const last30days = format(dateLast30Days, 'dd MMM yyyy');

    const dateThisMonth = new Date();
    const startOfMonthDate = startOfMonth(dateThisMonth);
    const endOfTodayDate = endOfDay(dateThisMonth);

    // Membuat rentang tanggal dari awal bulan hingga tanggal hari ini
    const dateRange = eachDayOfInterval({ start: startOfMonthDate, end: endOfTodayDate });

    // Format rentang tanggal
    const formattedStartDate = format(dateRange[0], 'dd MMM yyyy');
    const formattedEndDate = format(dateRange[dateRange.length - 1], 'dd MMM yyyy');

    setOutputDate({
      ...outputDate,
      today : today,
      yesterday: yesterday,
      last30days: last30days+' - '+today,
      thismonth : formattedStartDate+' - '+formattedEndDate
    });

    const sevenLastdays = new Date();
    const sevenDaysAgo = sub(sevenLastdays, { days: 6 });

    // Membuat rentang tanggal dari 7 hari terakhir
    const sevenDateRange = eachDayOfInterval({ start: sevenDaysAgo, end: sevenLastdays });

    // Format rentang tanggal
    const sevenFormattedStartDate = format(sevenDateRange[0], 'dd MMM yyyy');
    const sevenFormattedEndDate = format(sevenDateRange[sevenDateRange.length - 1], 'dd MMM yyyy');

    const start_be_date = format(sevenDateRange[0], 'yyyy-MM-dd');
    const end_be_date = format(sevenDateRange[sevenDateRange.length - 1], 'yyyy-MM-dd');

    setFilterDate({
      ...filterDate,
      output: '7 Hari Terakhir',
      start_date : sevenFormattedStartDate,
      end_date : sevenFormattedEndDate,
      start_be_date : start_be_date,
      end_be_date : end_be_date
    })
  }, []);

  const handleToggleSidebar = () => {
    if(localStorage.getItem('gesertoggle') !== null){
      localStorage.removeItem("gesertoggle");
      setGeser(true);
    }else{
      if(geser === false){
        setGeser(true);
        localStorage.setItem("gesertoggle", "true");
      }else{
        setGeser(false);
        localStorage.removeItem("gesertoggle");
      }
    }
  }

  const gesertoggle = localStorage.getItem("gesertoggle");

  const loadNeedAction = useCallback( async () => {
    let uuid =  localStorage.getItem("uuid");
    let parseuuid = JSON.parse(uuid);

    let userindex = parseuuid.userindex;
    let usertoken = parseuuid.usertokenlogin;

    let StringSign;
    StringSign = '';

    let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

    let header = {
      'platform' : 'WEBSITE',
      'gtoken' : 'PASSS',
      'userindex' : userindex,
      'tokenlogin' : usertoken,
      'signature' : signature
    }

    const currentTimestamp = new Date().getTime();

    let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/dashboard/needaction?id='+currentTimestamp, {
      'headers' : header
    });

    console.log(response.data);

    if(response.data.status.status === 1){
      setNeedAction(response.data.data);
    }
  }, []);

  const loadUsers = useCallback(async () => {
    let uuid =  localStorage.getItem("uuid");
    let parseuuid = JSON.parse(uuid);

    let userindex = parseuuid.userindex;
    let usertoken = parseuuid.usertokenlogin;

    let StringSign;
    StringSign = '';

    let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

    let header = {
      'platform' : 'WEBSITE',
      'gtoken' : 'PASSS',
      'userindex' : userindex,
      'tokenlogin' : usertoken,
      'signature' : signature
    }

    const currentTimestamp = new Date().getTime();

    let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/dashboard/users?date_from='+filterDate.start_be_date+'&date_to='+filterDate.end_be_date+'&id='+currentTimestamp, {
      'headers' : header
    });

    if(response.data.status.status === 1){
      setUsers(response.data.data);

      const formattedDataDevelopers = response.data.data.developers.map(item => (item.name));
      const formattedDataDevelopersValue = response.data.data.developers.map(item => (item.developer));

      setOptionsUsersDeveloper((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: formattedDataDevelopers,
        },
      }));

      setSeriesUsersDevelopers([
        {
          name : 'static-developers',
          data : formattedDataDevelopersValue
        }
      ]);

      const formattedDataGamers = response.data.data.gamers.map(item => (item.name));
      const formattedDataGamersValue = response.data.data.gamers.map(item => (item.gamer));

      setOptionsUsersDGamers((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: formattedDataGamers,
        },
      }));

      setSeriesGamers([
        {
          name : 'static-gamers',
          data : formattedDataGamersValue
        }
      ]);
    }  
  }, [filterDate]);

  const loadStatistic = useCallback(async (params) => {
    let uuid =  localStorage.getItem("uuid");
    let parseuuid = JSON.parse(uuid);

    let userindex = parseuuid.userindex;
    let usertoken = parseuuid.usertokenlogin;

    let StringSign;
    StringSign = '';

    let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

    let header = {
      'platform' : 'WEBSITE',
      'gtoken' : 'PASSS',
      'userindex' : userindex,
      'tokenlogin' : usertoken,
      'signature' : signature
    }

    const currentTimestamp = new Date().getTime();

    let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/dashboard/statistic?date_from='+filterDate.start_be_date+'&date_to='+filterDate.end_be_date+'&id='+currentTimestamp, {
      'headers' : header
    });

    if(response.data.status.status === 1){
      setStatistic(response.data.data);

      if(params === 0){
        const formattedDataStatistic = response.data.data.threads.map(item => (item.name));
        const formattedDataStatisticValue = response.data.data.threads.map(item => (item.thread));

        setOptionStatistic((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: formattedDataStatistic,
          },
        }));
  
        setSeriesStatistic([
          {
            name : 'statistic',
            data : formattedDataStatisticValue,
          }
        ]);
      }
      if(params === 1){
        const formattedDataStatistic = response.data.data.allocations.map(item => (item.name));
        const formattedDataStatisticValue = response.data.data.allocations.map(item => (item.anoa));

        setOptionStatistic((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: formattedDataStatistic,
          },
        }));
  
        setSeriesStatistic([
          {
            name : 'statistic',
            data : formattedDataStatisticValue
          }
        ]);
      } 
      if(params === 2){
        const formattedDataStatistic = response.data.data.reviews.map(item => (item.name));
        const formattedDataStatisticValue = response.data.data.reviews.map(item => (item.review));

        setOptionStatistic((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: formattedDataStatistic,
          },
        }));
  
        setSeriesStatistic([
          {
            name : 'statistic',
            data : formattedDataStatisticValue
          }
        ]);
      }    
      if(params === 3){
        const formattedDataStatistic = response.data.data.disbursements.map(item => (item.name));
        const formattedDataStatisticValue = response.data.data.disbursements.map(item => (item.anoa));

        setOptionStatistic((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: formattedDataStatistic,
          },
        }));
  
        setSeriesStatistic([
          {
            name : 'statistic',
            data : formattedDataStatisticValue
          }
        ]);
      }    
    }
  }, [filterDate]);

  useEffect(() => {
    loadNeedAction();
    loadUsers();
    loadStatistic(0);
  }, [loadNeedAction, loadUsers, loadStatistic]);

  const formatDate = (inputDate) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(inputDate).toLocaleDateString(undefined, options);
    return formattedDate;
  };

  const isObjectEmptyNeedAction = Object.keys(needAction).length !== 0;

  const switchHandle = (params) => {
    setSwichStatistic(params);
    loadStatistic(params);
  }

  const handleSelect = (ranges) => {
    setDateRangePicker({
      ...dateRangePicker,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    });

    let output = 'Pilih Tanggal';
    let startDate = format(new Date(ranges.selection.startDate), 'dd MMM yyyy');
    let endDate = format(new Date(ranges.selection.endDate), 'dd MMM yyyy');

    let start_be_date = format(new Date(ranges.selection.startDate), 'yyyy-MM-dd');
    let end_be_date = format(new Date(ranges.selection.endDate), 'yyyy-MM-dd');

    setFilterDate({
      ...filterDate,
      output : output,
      start_date: startDate,
      end_date : endDate,
      start_be_date : start_be_date,
      end_be_date : end_be_date
    });
  }

  const resetFilterDate = () => {
    const sevenLastdays = new Date();
    const sevenDaysAgo = sub(sevenLastdays, { days: 6 });

    // Membuat rentang tanggal dari 7 hari terakhir
    const sevenDateRange = eachDayOfInterval({ start: sevenDaysAgo, end: sevenLastdays });

    // Format rentang tanggal
    const sevenFormattedStartDate = format(sevenDateRange[0], 'dd MMM yyyy');
    const sevenFormattedEndDate = format(sevenDateRange[sevenDateRange.length - 1], 'dd MMM yyyy');

    const start_be_date = format(sevenDateRange[0], 'yyyy-MM-dd');
    const end_be_date = format(sevenDateRange[sevenDateRange.length - 1], 'yyyy-MM-dd');

    setFilterDate({
      ...filterDate,
      output: '7 Hari Terakhir',
      start_date : sevenFormattedStartDate,
      end_date : sevenFormattedEndDate,
      start_be_date : start_be_date,
      end_be_date : end_be_date
    })

    setToggleSelectDate(false);

    setDateRangePicker({
      ...dateRangePicker,
      startDate: new Date(),
      endDate: new Date()
    });
  }

  const handleSelectionToggleDate = () => {
    setToggleSelectDate(true);

    setFilterDate({
      ...filterDate,
      output : 'Pilih Tanggal',
    })
  }

  const todayHandle = () => {
    let dateToday = new Date();

    let output = 'Hari Ini';
    let startDate = format(new Date(dateToday), 'dd MMM yyyy');
    let endDate = format(new Date(dateToday), 'dd MMM yyyy');

    let start_be_date = format(new Date(dateToday), 'yyyy-MM-dd');
    let end_be_date = format(new Date(dateToday), 'yyyy-MM-dd');

    setFilterDate({
      ...filterDate,
      output : output,
      start_date: startDate,
      end_date : endDate,
      start_be_date : start_be_date,
      end_be_date : end_be_date
    });
  }

  const yesterdaysHandle = () => {
    const dateYesterday = sub(new Date(), { days: 1 });

    let output = 'Kemarin';
    let startDate = format(new Date(dateYesterday), 'dd MMM yyyy');
    let endDate = format(new Date(dateYesterday), 'dd MMM yyyy');

    let start_be_date = format(new Date(dateYesterday), 'yyyy-MM-dd');
    let end_be_date = format(new Date(dateYesterday), 'yyyy-MM-dd');

    setFilterDate({
      ...filterDate,
      output : output,
      start_date: startDate,
      end_date : endDate,
      start_be_date : start_be_date,
      end_be_date : end_be_date
    });
  }

  const last30daysHandle = () => {
    let dateToday = new Date();
    let dateLast30Days = sub(new Date(), { days: 30 });

    let output = '30 Hari Terakhir';
    let startDate = format(new Date(dateLast30Days), 'dd MMM yyyy');
    let endDate = format(new Date(dateToday), 'dd MMM yyyy');

    let start_be_date = format(new Date(dateLast30Days), 'yyyy-MM-dd');
    let end_be_date = format(new Date(dateToday), 'yyyy-MM-dd');

    setFilterDate({
      ...filterDate,
      output : output,
      start_date: startDate,
      end_date : endDate,
      start_be_date : start_be_date,
      end_be_date : end_be_date
    });
  }

  const thisMonthHandle = () => {
    let dateThisMonth = new Date();
    let startOfMonthDate = startOfMonth(dateThisMonth);
    let endOfTodayDate = endOfDay(dateThisMonth);

    let dateRange = eachDayOfInterval({ start: startOfMonthDate, end: endOfTodayDate });

    let output = 'Bulan Ini';
    let startDate = format(dateRange[0], 'dd MMM yyyy');
    let endDate = format(dateRange[dateRange.length - 1], 'dd MMM yyyy');

    let start_be_date = format(dateRange[0], 'yyyy-MM-dd');
    let end_be_date = format(dateRange[dateRange.length - 1], 'yyyy-MM-dd');

    setFilterDate({
      ...filterDate,
      output : output,
      start_date: startDate,
      end_date : endDate,
      start_be_date : start_be_date,
      end_be_date : end_be_date
    });
  }

  const loadRatio = useCallback(async (params) => {
    let uuid =  localStorage.getItem("uuid");
    let parseuuid = JSON.parse(uuid);

    let userindex = parseuuid.userindex;
    let usertoken = parseuuid.usertokenlogin;

    let StringSign;
    StringSign = '';

    let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

    let header = {
      'platform' : 'WEBSITE',
      'gtoken' : 'PASSS',
      'userindex' : userindex,
      'tokenlogin' : usertoken,
      'signature' : signature
    }

    const currentTimestamp = new Date().getTime();
    let response = await axios.get(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/dashboard/ratio?date_from='+filterDate.start_be_date+'&date_to='+filterDate.end_be_date+'&id='+currentTimestamp, {
      'headers' : header
    });

    if(response.data.status.status === 1){
      if(params === 0) {
        const ratioNameThread = response.data.data.ratiothreads.map(item => (item.tablegenreheadername));
        const ratioThreadValue = response.data.data.ratiothreads.map(item => (item.threads));

        setOptionsRation((prevOptions) => ({
          ...prevOptions,
          labels: ratioNameThread,
        }));
  
        setSeriesRatio(ratioThreadValue);
      }
      if(params === 1) {
        const ratioNameThread = response.data.data.ratioallocation.map(item => (item.tablegenreheadername));
        const ratioThreadValue = response.data.data.ratioallocation.map(item => (item.allocation));

        setOptionsRation((prevOptions) => ({
          ...prevOptions,
          labels: ratioNameThread,
        }));

        let numberArray = [];
        for (let i = 0; i < ratioThreadValue.length; i++){
            numberArray.push(parseInt(ratioThreadValue[i]));
        }
  
        setSeriesRatio(numberArray);
      }
      if(params === 2) {
        const ratioNameThread = response.data.data.ratioreviews.map(item => (item.tablegenreheadername));
        const ratioThreadValue = response.data.data.ratioreviews.map(item => (item.reviews));

        setOptionsRation((prevOptions) => ({
          ...prevOptions,
          labels: ratioNameThread,
        }));
  
        setSeriesRatio(ratioThreadValue);
      }
      if(params === 3) {
        const ratioNameThread = response.data.data.ratiodisbursment.map(item => (item.tablegenreheadername));
        const ratioThreadValue = response.data.data.ratiodisbursment.map(item => (item.disbursement));

        setOptionsRation((prevOptions) => ({
          ...prevOptions,
          labels: ratioNameThread,
        }));

        let numberArray = [];
        for (let i = 0; i < ratioThreadValue.length; i++){
            numberArray.push(parseInt(ratioThreadValue[i]));
        }
  
        setSeriesRatio(numberArray);
      }
    }

  }, [filterDate]);

  useEffect(() => {
    loadRatio(0);
  }, [loadRatio]);

  const switchHandleRatio = (params) => {
    setSwitchRatio(params);
    loadRatio(params);
  }

  console.log(seriesRatio)

  return (
    <>
      <div className="bg-black min-h-full">
        <div className='fixed top-5 left-5 max-sm:top-[10px] max-sm:left-[10px] max-sm:absolute'>
            <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
        </div>
        <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[330px]"} ${gesertoggle} max-sm:pl-10 duration-300 w-full h-full pt-5 pr-10 pb-5`}>
          <div className='mb-[32px]'>
            <h1 className='text-white text-[30px] font-normal'>Dashboard</h1>
            <Breadcrumbslatest/>
          </div>
          <div className="border-2 border-[#5D5F61] w-full rounded-2xl p-[24px] mb-[32px]">
            <h2 className='text-white text-[16px] font-semibold pb-[16px]'>Need Action</h2>
            <div className='flex flex-row gap-3 mb-[32px]'>
              <div className='flex flex-col justify-between w-full'>
                <div className="bg-[#1E1F23] rounded-lg p-[12px]">
                  <div className='flex flex-row items-center justify-between'>
                    <p className='text-[14px] text-[#E4E8EC]'>Pending KYC</p>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_5563_8373)">
                        <path d="M14.1734 18.8007C14.681 17.6852 15.4034 16.6806 16.2993 15.8444C17.1953 15.0081 18.2472 14.3566 19.395 13.9269C20.5427 13.4973 21.7639 13.298 22.9887 13.3404C24.2136 13.3827 25.418 13.666 26.5334 14.174C27.6489 14.6816 28.6534 15.404 29.4897 16.3C30.3259 17.1959 30.9775 18.2478 31.4071 19.3956C31.8367 20.5433 32.0361 21.7645 31.9937 22.9893C31.9513 24.2142 31.668 25.4187 31.16 26.534C30.4208 28.1661 29.2261 29.5501 27.7195 30.5198C26.2129 31.4895 24.4584 32.0037 22.6667 32.0007C19.0667 32.0007 15.7734 29.9207 14.2267 26.6673H1.33337V24.0007C1.41337 22.4807 2.45337 21.2407 4.45337 20.2407C6.45337 19.2407 8.96004 18.7207 12 18.6673C12.76 18.6673 13.48 18.734 14.1734 18.8007ZM12 5.33398C13.4934 5.37398 14.7467 5.89398 15.7467 6.89398C16.7467 7.89398 17.24 9.14732 17.24 10.6673C17.24 12.1873 16.7467 13.4407 15.7467 14.4407C14.7467 15.4407 13.4934 15.934 12 15.934C10.5067 15.934 9.25337 15.4407 8.25337 14.4407C7.25337 13.4407 6.76004 12.1873 6.76004 10.6673C6.76004 9.14732 7.25337 7.89398 8.25337 6.89398C9.25337 5.89398 10.5067 5.37398 12 5.33398ZM22.6667 29.334C24.4348 29.334 26.1305 28.6316 27.3808 27.3814C28.631 26.1311 29.3334 24.4354 29.3334 22.6673C29.3334 20.8992 28.631 19.2035 27.3808 17.9533C26.1305 16.703 24.4348 16.0007 22.6667 16.0007C20.8986 16.0007 19.2029 16.703 17.9527 17.9533C16.7024 19.2035 16 20.8992 16 22.6673C16 24.4354 16.7024 26.1311 17.9527 27.3814C19.2029 28.6316 20.8986 29.334 22.6667 29.334ZM21.3334 18.6673H23.3334V22.4273L26.5867 24.3073L25.5867 26.0407L21.3334 23.5873V18.6673Z" fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_5563_8373">
                          <rect width="32" height="32" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <h1 className='text-white text-[36px] font-semibold'>{needAction.PendingKYC}</h1>
                </div>
              </div>

              <div className='flex flex-col justify-between w-full'>
                <div className="bg-[#1E1F23] rounded-lg p-[12px]">
                  <div className='flex flex-row items-center justify-between'>
                    <p className='text-[14px] text-[#E4E8EC]'>Pending Review</p>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_5563_8373)">
                        <path d="M14.1734 18.8007C14.681 17.6852 15.4034 16.6806 16.2993 15.8444C17.1953 15.0081 18.2472 14.3566 19.395 13.9269C20.5427 13.4973 21.7639 13.298 22.9887 13.3404C24.2136 13.3827 25.418 13.666 26.5334 14.174C27.6489 14.6816 28.6534 15.404 29.4897 16.3C30.3259 17.1959 30.9775 18.2478 31.4071 19.3956C31.8367 20.5433 32.0361 21.7645 31.9937 22.9893C31.9513 24.2142 31.668 25.4187 31.16 26.534C30.4208 28.1661 29.2261 29.5501 27.7195 30.5198C26.2129 31.4895 24.4584 32.0037 22.6667 32.0007C19.0667 32.0007 15.7734 29.9207 14.2267 26.6673H1.33337V24.0007C1.41337 22.4807 2.45337 21.2407 4.45337 20.2407C6.45337 19.2407 8.96004 18.7207 12 18.6673C12.76 18.6673 13.48 18.734 14.1734 18.8007ZM12 5.33398C13.4934 5.37398 14.7467 5.89398 15.7467 6.89398C16.7467 7.89398 17.24 9.14732 17.24 10.6673C17.24 12.1873 16.7467 13.4407 15.7467 14.4407C14.7467 15.4407 13.4934 15.934 12 15.934C10.5067 15.934 9.25337 15.4407 8.25337 14.4407C7.25337 13.4407 6.76004 12.1873 6.76004 10.6673C6.76004 9.14732 7.25337 7.89398 8.25337 6.89398C9.25337 5.89398 10.5067 5.37398 12 5.33398ZM22.6667 29.334C24.4348 29.334 26.1305 28.6316 27.3808 27.3814C28.631 26.1311 29.3334 24.4354 29.3334 22.6673C29.3334 20.8992 28.631 19.2035 27.3808 17.9533C26.1305 16.703 24.4348 16.0007 22.6667 16.0007C20.8986 16.0007 19.2029 16.703 17.9527 17.9533C16.7024 19.2035 16 20.8992 16 22.6673C16 24.4354 16.7024 26.1311 17.9527 27.3814C19.2029 28.6316 20.8986 29.334 22.6667 29.334ZM21.3334 18.6673H23.3334V22.4273L26.5867 24.3073L25.5867 26.0407L21.3334 23.5873V18.6673Z" fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_5563_8373">
                          <rect width="32" height="32" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <h1 className='text-white text-[36px] font-semibold'>{needAction.PendingReview}</h1>
                </div>
              </div>
            </div>
            
            <div className='mb-[16px] flex flex-row items-center justify-between'>
              <h2 className='text-white text-[16px] font-semibold'>Latest KYC Submission</h2>
              <Link to="/user" className='text-white text-[16px] font-semibold underline'>See All</Link>
            </div>

            <div className="rounded-xl mb-[32px]">
              <table className="divide-y divide-gray-200 w-full">
                <thead className="bg-[#1E1F23] rounded-lg text-gray-300">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Created Date</th>
                    <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">User ID</th>
                    <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">User Role</th>
                    <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Join Date</th>
                    <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Status</th>
                    <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 text-gray-300">
                  {isObjectEmptyNeedAction ?
                  needAction.LatestKYCSubmission.map((result, key) => (
                    <tr key={key}>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">{result.kycdate}</td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">{result.userindex}</td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">{result.tipeuser}</td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">{result.joindate}</td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">
                        <span className='bg-[#FFFB9A] rounded-lg py-[8px] px-[8px] text-[#0A0A0A] text-[12px] block w-[100px] text-center'>{result.status}</span>
                      </td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">
                        <div className='px-3 py-1 bg-[#0028FC] w-[50px] rounded-md'>
                            <a href={'/user/'+result.userindex}>
                            <FiEye size={25}/>
                            </a>
                        </div>
                      </td>
                    </tr>
                  )) : ''}
                </tbody>
              </table>
            </div>

            <div className='mb-[16px] flex flex-row items-center justify-between'>
              <h2 className='text-white text-[16px] font-semibold'>Latest Review Submission</h2>
              <Link to="/listreview" className='text-white text-[16px] font-semibold underline'>See All</Link>
            </div>

            <div className="overflow-auto max-sm:overflow-auto rounded-xl">
              <table className="divide-y divide-gray-200 w-full">
                <thead className="bg-[#1E1F23] rounded-lg text-gray-300">
                  <tr>
                      <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Created Date</th>
                      <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Review ID</th>
                      <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Gamers ID</th>
                      <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Thread ID</th>
                      <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Status</th>
                      <th scope="col" className="px-6 py-3 text-xs font-bold text-left uppercase">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 text-gray-300">
                  {isObjectEmptyNeedAction ? 
                  needAction.LatestReviewSubmission.map((result, key) => (
                    <tr key={key}>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">{result.tablereviewtimestamp}</td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">
                        <a href={'/listreview/'+result.tablereviewindex} className='underline' target='_blank'>{result.tablereviewindex}</a>
                      </td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">
                        <a href={'/user/'+result.tablereviewgameruserindex} className='underline' target='_blank'>{result.tableusername}</a>
                      </td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">
                        <a href={'/threads/'+result.tablereviewthreadindex} className='underline' target='_blank'>{result.tablereviewthreadindex}</a>
                      </td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">
                        <span className='bg-[#FFFB9A] rounded-lg py-[8px] px-[8px] text-[#0A0A0A] text-[12px] block w-[100px] text-center'>{result.tablereviewstatus}</span>
                      </td>
                      <td className="px-6 py-3 text-sm max-sm:text-xs whitespace-nowrap align-top">
                        <Link to={'/listreview/'+result.tablereviewindex}>
                          <div className='px-3 py-1 bg-[#0028FC] w-[50px] rounded-md'>
                              <FiEye size={25}/>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  )) : ''}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="border-2 border-[#5D5F61] w-full rounded-2xl p-[24px] mb-[32px]">
            <div className='flex flex-row justify-between items-center mb-3'>
              <h2 className='text-white text-[16px] font-semibold pb-[16px]'>Users</h2>

              <div className='relative w-[430px]'>
                <div className='bg-[#181826] border-2 border-[#292840] shadow rounded-md p-[12px] flex flex-row items-center cursor-pointer' onClick={() => setToggleDropdownDate(!toggleDropdownDate)}>
                  <div className='flex flex-row items-center gap-2 w-full'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#EBF0F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span className='text-white font-semibold'>{filterDate.output}</span>
                    <span className='text-white'>({filterDate.start_date} - {filterDate.end_date})</span>
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 9L12 16L5 9" stroke="#EBF0F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                
                {toggleDropdownDate ?
                <div className='absolute bg-[#181826] border-2 border-[#292840] top-16 w-full rounded-[16px] p-[16px] z-20'>
                  <div className='relative'>
                    <p className='text-white font-semibold text-[16px]'>Rentang waktu</p>

                    <div className='h-full'>
                      <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={todayHandle}>
                        <p className='text-[14px]' style={{ color : filterDate.output === 'Hari Ini' ? '#4B55C3' : ''}}>Hari ini</p>
                        <p className='text-white text-[14px] font-semibold'>{outputDate.today} (00:00 - 23:59)</p>
                      </div>

                      <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={yesterdaysHandle}>
                        <p className='text-[14px]' style={{ color : filterDate.output === 'Kemarin' ? '#4B55C3' : ''}}>Kemarin</p>
                        <p className='text-white text-[14px] font-semibold'>{outputDate.yesterday}</p>
                      </div>
                      
                      <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={last30daysHandle}>
                        <p className='text-[14px]' style={{ color : filterDate.output === '30 Hari Terakhir' ? '#4B55C3' : ''}}>30 Hari Terakhir</p>
                        <p className='text-white text-[14px] font-semibold'>{outputDate.last30days}</p>
                      </div>

                      <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={thisMonthHandle}>
                        <p className='text-[14px]' style={{ color : filterDate.output === 'Bulan Ini' ? '#4B55C3' : ''}}>Bulan Ini</p>
                        <p className='text-white text-[14px] font-semibold'>{outputDate.thismonth}</p>
                      </div>

                      <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700 my-2"/>

                      <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={handleSelectionToggleDate}>
                        <p className='text-[14px]' style={{ color : filterDate.output === 'Pilih Tanggal' ? '#4B55C3' : ''}}>Pilih Tanggal</p>
                      </div>

                      <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={() => resetFilterDate()}>
                        <p className='text-[14px]'>Reset</p>
                      </div>
                    </div>
                    {toggleSelectDate ?
                    <div className='absolute top-0 right-0 min-h-full'>
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={[dateRangePicker]}
                        fixedHeight={false}
                        months={1}                        
                      />
                    </div> : '' }
                  </div>
                </div>
                : '' }
              </div>

            </div>
            <div className='flex flex-row gap-3'>
              <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                <div className='flex flex-row justify-between items-center'>
                  <div>
                    <p className='text-white text-[20px] font-semibold'>Developers</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                  <h1 className='text-[#CB3A31] text-[36px] font-semibold'>{users.total_developers}</h1>
                </div>
                <div>
                  <Chart
                    options={optionsUsersDeveloper}
                    series={seriesUsersDevelopers}
                    type="line"
                    width="100%"
                    height="300"
                  />
                </div>
              </div>

              <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                <div className='flex flex-row justify-between items-center'>
                  <div>
                    <p className='text-white text-[20px] font-semibold'>Gamers</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                  <h1 className='text-[#CB3A31] text-[36px] font-semibold'>{users.total_gamers}</h1>
                </div>
                <div>
                  <Chart
                    options={optionsUsersGamers}
                    series={seriesUsersGamers}
                    type="line"
                    width="100%"
                    height="300"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border-2 border-[#5D5F61] w-full rounded-2xl p-[24px] mb-[32px]">
            <h2 className='text-white text-[16px] font-semibold pb-[16px]'>Statistic</h2>
            <div className='flex flex-row gap-3 mb-3'>
              <div className={switchStatistic === 0 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandle(0)}>
                <div className={switchStatistic === 0 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                  <p className='text-[20px] text-[#E4E8EC]'>Threads</p>
                  <p className='text-[#E0E0F3] text-[28px]'>{statistic.total_threads}</p>
                  <p className='text-[#E4E8EC]'>{formatDate(statisticStartDate)} - {formatDate(statisticEndDate)}</p>
                </div>
              </div>

              <div className={switchStatistic === 1 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandle(1)}>
                <div className={switchStatistic === 1 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                  <p className='text-[20px] text-[#E4E8EC]'>Allocation (ANOA)</p>
                  <p className='text-[#E0E0F3] text-[28px]'>{statistic.total_allocations}</p>
                  <p className='text-[#E4E8EC]'>{formatDate(statisticStartDate)} - {formatDate(statisticEndDate)}</p>
                </div>
              </div>

              <div className={switchStatistic === 2 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandle(2)}>
                <div className={switchStatistic === 2 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                  <p className='text-[20px] text-[#E4E8EC]'>Reviews</p>
                  <p className='text-[#E0E0F3] text-[28px]'>{statistic.total_reviews}</p>
                  <p className='text-[#E4E8EC]'>{formatDate(statisticStartDate)} - {formatDate(statisticEndDate)}</p>
                </div>
              </div>

              <div className={switchStatistic === 3 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandle(3)}>
                <div className={switchStatistic === 3 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}    >
                  <p className='text-[20px] text-[#E4E8EC]'>Disbursments (ANOA)</p>
                  <p className='text-[#E0E0F3] text-[28px]'>{statistic.total_disbursements}</p>
                  <p className='text-[#E4E8EC]'>{formatDate(statisticStartDate)} - {formatDate(statisticEndDate)}</p>
                </div>
              </div>
            </div>

            <div>
              <Chart
                options={optionsStatistic}
                series={seriesStatistic}
                type="line"
                width="100%"
                height="500"
              />
            </div>
          </div>

          <div className="border-2 border-[#5D5F61] w-full rounded-2xl p-[24px] mb-[32px]">
            <h2 className='text-white text-[16px] font-semibold pb-[16px]'>Ratio</h2>
            <div className='flex flex-row gap-3 mb-3'>
              <div className='bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'>
                <div className='bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]'>
                  <p className='text-[20px] text-[#E4E8EC]'>Genre Ratio of Threads</p>
                  <p className='text-[#E4E8EC]'>1 Jan 2023 - 1 Feb 2023</p>
                </div>
              </div>

              <div className='rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'>
                <div className='bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'>
                  <p className='text-[20px] text-[#E4E8EC]'>Genre Ratio of Allocation</p>
                  <p className='text-[#E4E8EC]'>1 Jan 2023 - 1 Feb 2023</p>
                </div>
              </div>

              <div className='rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'>
                <div className='bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'>
                  <p className='text-[20px] text-[#E4E8EC]'>Genre Ratio of Review</p>
                  <p className='text-[#E4E8EC]'>1 Jan 2023 - 1 Feb 2023</p>
                </div>
              </div>

              <div className='rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'>
                <div className='bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'>
                  <p className='text-[20px] text-[#E4E8EC]'>Genre Ratio of Disbursement</p>
                  <p className='text-[#E4E8EC]'>1 Jan 2023 - 1 Feb 2023</p>
                </div>
              </div>
            </div>

            <div className='bg-[#1E1F23] rounded-md p-[24px] text-left'>
              <div className='w-[600px] m-auto'>
                <Chart
                  options={optionsRatio}
                  series={seriesRatio}
                  type="donut"
                  height={500}
                  width='100%'
                />
              </div>
            </div>
          </div> */}

          <div className="border-2 border-[#5D5F61] w-full rounded-2xl p-[24px] mb-[32px]">
            <div className='mb-5'>
              <div className='flex flex-row justify-between items-center mb-3'>
                <h2 className='text-white text-[16px] font-semibold pb-[16px]'>Users</h2>

                <div className='relative w-[430px]'>
                  <div className='bg-[#181826] border-2 border-[#292840] shadow rounded-md p-[12px] flex flex-row items-center cursor-pointer' onClick={() => setToggleDropdownDate(!toggleDropdownDate)}>
                    <div className='flex flex-row items-center gap-2 w-full'>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#EBF0F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span className='text-white font-semibold'>{filterDate.output}</span>
                      <span className='text-white'>({filterDate.start_date} - {filterDate.end_date})</span>
                    </div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 9L12 16L5 9" stroke="#EBF0F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  
                  {toggleDropdownDate ?
                  <div className='absolute bg-[#181826] border-2 border-[#292840] top-16 w-full rounded-[16px] p-[16px] z-20'>
                    <div className='relative'>
                      <p className='text-white font-semibold text-[16px]'>Rentang waktu</p>

                      <div className='h-full'>
                        <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={todayHandle}>
                          <p className='text-[14px]' style={{ color : filterDate.output === 'Hari Ini' ? '#4B55C3' : ''}}>Hari ini</p>
                          <p className='text-white text-[14px] font-semibold'>{outputDate.today} (00:00 - 23:59)</p>
                        </div>

                        <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={yesterdaysHandle}>
                          <p className='text-[14px]' style={{ color : filterDate.output === 'Kemarin' ? '#4B55C3' : ''}}>Kemarin</p>
                          <p className='text-white text-[14px] font-semibold'>{outputDate.yesterday}</p>
                        </div>
                        
                        <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={last30daysHandle}>
                          <p className='text-[14px]' style={{ color : filterDate.output === '30 Hari Terakhir' ? '#4B55C3' : ''}}>30 Hari Terakhir</p>
                          <p className='text-white text-[14px] font-semibold'>{outputDate.last30days}</p>
                        </div>

                        <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={thisMonthHandle}>
                          <p className='text-[14px]' style={{ color : filterDate.output === 'Bulan Ini' ? '#4B55C3' : ''}}>Bulan Ini</p>
                          <p className='text-white text-[14px] font-semibold'>{outputDate.thismonth}</p>
                        </div>

                        <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700 my-2"/>

                        <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={handleSelectionToggleDate}>
                          <p className='text-[14px]' style={{ color : filterDate.output === 'Pilih Tanggal' ? '#4B55C3' : ''}}>Pilih Tanggal</p>
                        </div>

                        <div className='flex flex-row items-center justify-between py-[8px] px-[12px] cursor-pointer text-[#989B9D] hover:bg-[#2970FF] hover:rounded-md hover:text-white' onClick={() => resetFilterDate()}>
                          <p className='text-[14px]'>Reset</p>
                        </div>
                      </div>
                      {toggleSelectDate ?
                      <div className='absolute top-0 right-0 min-h-full'>
                        <DateRange
                          editableDateInputs={true}
                          onChange={handleSelect}
                          moveRangeOnFirstSelection={false}
                          ranges={[dateRangePicker]}
                          fixedHeight={false}
                          months={1}                        
                        />
                      </div> : '' }
                    </div>
                  </div>
                  : '' }
                </div>

              </div>
              <div className='flex flex-row gap-3'>
                <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                  <div className='flex flex-row justify-between items-center'>
                    <div>
                      <p className='text-white text-[20px] font-semibold'>Developers</p>
                      <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                    </div>
                    <h1 className='text-[#CB3A31] text-[36px] font-semibold'>{users.total_developers}</h1>
                  </div>
                  <div>
                    <Chart
                      options={optionsUsersDeveloper}
                      series={seriesUsersDevelopers}
                      type="line"
                      width="100%"
                      height="300"
                    />
                  </div>
                </div>

                <div className="bg-[#1E1F23] rounded-lg p-[12px] w-full">
                  <div className='flex flex-row justify-between items-center'>
                    <div>
                      <p className='text-white text-[20px] font-semibold'>Gamers</p>
                      <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                    </div>
                    <h1 className='text-[#CB3A31] text-[36px] font-semibold'>{users.total_gamers}</h1>
                  </div>
                  <div>
                    <Chart
                      options={optionsUsersGamers}
                      series={seriesUsersGamers}
                      type="line"
                      width="100%"
                      height="300"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-5'>
              <h2 className='text-white text-[16px] font-semibold pb-[16px]'>Statistic</h2>
              <div className='flex flex-row gap-3 mb-3'>
                <div className={switchStatistic === 0 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandle(0)}>
                  <div className={switchStatistic === 0 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                    <p className='text-[20px] text-[#E4E8EC]'>Threads</p>
                    <p className='text-[#E0E0F3] text-[28px]'>{statistic.total_threads}</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                </div>

                <div className={switchStatistic === 1 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandle(1)}>
                  <div className={switchStatistic === 1 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                    <p className='text-[20px] text-[#E4E8EC]'>Allocation (ANOA)</p>
                    <p className='text-[#E0E0F3] text-[28px]'>{statistic.total_allocations}</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                </div>

                <div className={switchStatistic === 2 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandle(2)}>
                  <div className={switchStatistic === 2 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                    <p className='text-[20px] text-[#E4E8EC]'>Reviews</p>
                    <p className='text-[#E0E0F3] text-[28px]'>{statistic.total_reviews}</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                </div>

                <div className={switchStatistic === 3 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandle(3)}>
                  <div className={switchStatistic === 3 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}    >
                    <p className='text-[20px] text-[#E4E8EC]'>Disbursments (ANOA)</p>
                    <p className='text-[#E0E0F3] text-[28px]'>{statistic.total_disbursements}</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                </div>
              </div>

              <div>
                <Chart
                  options={optionsStatistic}
                  series={seriesStatistic}
                  type="line"
                  width="100%"
                  height="500"
                />
              </div>     
            </div>

            <div className='mb-5'>
              <h2 className='text-white text-[16px] font-semibold pb-[16px]'>Ratio</h2>
              <div className='flex flex-row gap-3 mb-3'>
                <div className={switchRatio === 0 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandleRatio(0)}>
                  <div className={switchRatio === 0 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                    <p className='text-[20px] text-[#E4E8EC]'>Genre Ratio of Threads</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                </div>

                <div className={switchRatio === 1 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandleRatio(1)}>
                  <div className={switchRatio === 1 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                    <p className='text-[20px] text-[#E4E8EC]'>Genre Ratio of Allocation</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                </div>

                <div className={switchRatio === 2 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandleRatio(2)}>
                  <div className={switchRatio === 2 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                    <p className='text-[20px] text-[#E4E8EC]'>Genre Ratio of Review</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                </div>

                <div className={switchRatio === 3 ? 'bg-bgimg-1 rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer' : ' rounded-lg p-[1px] flex flex-col justify-between w-full cursor-pointer'} onClick={() => switchHandleRatio(3)}>
                  <div className={switchRatio === 3 ? 'bg-[#081340] flex flex-col justify-between h-full rounded-lg p-[12px]' : 'bg-[#1E1F23] flex flex-col justify-between h-full rounded-lg p-[12px]'}>
                    <p className='text-[20px] text-[#E4E8EC]'>Genre Ratio of Disbursement</p>
                    <span className='text-[#E4E8EC] font-normal'>{formatDate(filterDate.start_be_date)} - {formatDate(filterDate.end_be_date)}</span>
                  </div>
                </div>
              </div>

              <div className='bg-[#1E1F23] rounded-md p-[24px] text-left'>
                {seriesRatio.length > 0 ?
                  <div className='w-[600px] m-auto'>
                    <Chart
                      options={optionsRatio}
                      series={seriesRatio}
                      type="donut"
                      height={500}
                      width='100%'
                    />
                  </div>
                  : 
                  <div>
                    <h1 className='text-white text-center text-[30px] my-[200px]'>There is no ratio data</h1>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard