import React, { useCallback, useEffect, useRef, useState } from 'react'

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate, useParams } from 'react-router-dom';

import Sidebar from "../../components/Sidebar";
import { FiEye } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";

function Wallet() {

    const [geser, setGeser] = useState(false);

    const handleToggleSidebar = () => {
      if(localStorage.getItem("gesertoggle") !== null){
        localStorage.removeItem("gesertoggle");
        setGeser(true);
      }else{
        if(geser === false){
          setGeser(true);
          localStorage.setItem("gesertoggle", "true");
        }else{
          setGeser(false);
          localStorage.removeItem("gesertoggle");
        }
      }
    }

    const gesertoggle = localStorage.getItem("gesertoggle") || "w-72";

    const navigate = useNavigate();
    const { id } = useParams();
    const [userDetail, setUserDetail] = useState({});

    const userHandle = useCallback( async () => {
      let uuid =  localStorage.getItem("uuid");
        let parseuuid = JSON.parse(uuid);

        let userindex = parseuuid.userindex;
        let usertoken = parseuuid.usertokenlogin;

        let StringSign;
        StringSign = id;
        // StringSign = 'PASSS';
        // StringSign = userindex;
        // StringSign = usertoken;

        let signature = CryptoJS.HmacSHA256(StringSign, 'brekltespedriguc').toString();

        let header = {
            'platform' : 'WEBSITE',
            'gtoken' : 'PASSS',
            'userindex' : userindex,
            'tokenlogin' : usertoken,
            'signature' : signature

        }

        let body = {
          'detailuserindex' : id
        }

        let response = await axios.post(process.env.REACT_APP_HOSTNAME+'/api/user/v01/admin/detailuser', body, {
            'headers' : header
        });

        if(response.data.status.status === 1){
          setUserDetail(response.data.data[0])
        }
    }, [id])

    useEffect(() => {
      userHandle();
    }, [userHandle])

  return (
    <>
      <div className="bg-black min-h-full">
        <div className='fixed top-5 left-5 max-sm:left-0 max-sm:pl-[15px] max-sm:absolute'>
            <Sidebar togglesidebar={handleToggleSidebar} gesertoggle={geser}/>
        </div>
        <div className={`${localStorage.getItem('gesertoggle') ? "pl-[140px]" : "pl-[350px]"} ${gesertoggle} max-sm:pl-[10px] max-sm:pr-[10px] duration-300 w-full h-full pt-5 pr-10 pb-5`}>
          <div className="flex flex-row justify-between text-gray-300 max-sm:mt-10">
              <div className="flex flex-row m-3 ml-0">
                  <FiArrowLeft size={25} className="mt-1 mr-1" />
                  <div className="flex  flex-col">
                      <h1 className='text-2xl max-sm:text-xl'>Wallet</h1>
                      <h2 className="text-sm max-sm:text-xs">../User/Wallet/</h2>
                  </div>
              </div>
              <div className="flex text-center text-sm w-[20%] m-3 max-sm:w-[50%] max-sm:h-[50%] max-sm:mx-0 max-sm:mt-[20px]">
                <div className="flex w-full p-2 max-sm:p-0 h-full text-center">
                  <div className=" bg-gray-300 text-gray-600 py-2 max-sm:py-1 w-[50%] rounded-l-lg">
                    <a>Data User</a>
                  </div>
                  <div className="bg-blue-400 text-gray-300 py-2 max-sm:py-1 w-[50%] rounded-r-lg">
                    <a href={`/wallet/`}>Wallet</a>
                  </div>
                </div>
              </div>
          </div>
          {/* Start Content */}
          <div className="flex flex-col">
              <div>
                <h1 className='text-gray-300'>User Balance</h1>
                <div className="flex flex-row w-full mt-3 max-sm:mt-2">
                  <div className="w-[50%] flex flex-col mr-3 max-sm:mr-1 bg-[#1E1F23] py-5 max-sm:py-2 px-2 rounded-lg">
                    <span className="text-[#989B9D] text-base max-sm:text-sm mb-3">
                      Balance
                    </span>
                    <span className="text-white text-sm max-sm:text-xs">300 ANOA</span>
                  </div>
                  <div className="w-[50%] flex flex-col bg-[#1E1F23] max-sm:ml-1 py-5 max-sm:py-2 px-2 rounded-lg">
                    <span className="text-[#989B9D] text-base max-sm:text-sm mb-3">
                      Monthly Transaction
                    </span>
                    <span className="text-white text-sm max-sm:text-xs">+200 ANOA</span>
                  </div>
                </div>
                <div className="w-full flex flex-col bg-[#1E1F23] py-5 max-sm:py-2 px-2 mt-3 max-sm:mt-2 rounded-lg">
                  <span className="text-[#989B9D] text-base max-sm:text-sm mb-3">
                    Address Wallet
                  </span>
                  <span className="text-white text-sm max-sm:text-xs">
                    57689435448835455485454886
                  </span>
                </div>
              </div>
              <div className="mt-5">
                <h1 className='text-gray-100 ml-1'>User transaction</h1>
                <div className="w-full">
                  <div className="flex flex-col">
                    <div className="overflow-x-auto">
                      <div className="py-1.5 w-full inline-block align-middle">
                        <div className="overflow-hidden max-sm:overflow-auto rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-[#1E1F23]">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left text-[#989B9D] uppercase "
                                >
                                  Transaction Hash
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left text-[#989B9D] uppercase "
                                >
                                  Timestamp
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left text-[#989B9D] uppercase "
                                >
                                  Transaction Type
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left text-[#989B9D] uppercase "
                                >
                                  Amount
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-xs font-bold text-left text-[#989B9D] uppercase "
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 text-gray-300">
                              <tr>
                                <td className="px-6 py-4 text-sm max-sm:text-xs  whitespace-nowrap">
                                  78126317812637816381263812
                                </td>
                                <td className="px-6 py-4 text-sm max-sm:text-xs  whitespace-nowrap">
                                  12/12/12 - 14:01
                                </td>
                                <td className="px-6 py-4 text-sm max-sm:text-xs  whitespace-nowrap">
                                  Transfer
                                </td>
                                <td className="px-6 py-4 text-sm max-sm:text-xs  whitespace-nowrap">
                                  12 ANOA
                                </td>
                                <td className="px-6 py-4 text-sm max-sm:text-xs font-medium text-right whitespace-nowrap">
                                  <a
                                    className=" hover:text-gray-100"
                                    href="#"
                                  >
                                    <FiEye size={25} className="" />
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* End Content */}
        </div>
      </div>
    </>
  );
}

export default Wallet;
